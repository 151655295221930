import { schoolUnit } from './unitCode/index';
import { miscTransaction } from './transaction/index';

export const misc = {
  namespaced: true,
  modules: {
    schoolUnit,
    miscTransaction,
  },
};

export default misc;
