export const programToModelMapper = {
  miscWhom: 'miscWhom',
  mtgWhom: 'mtgWhom',
};

export const programToModel = (program) => (programToModelMapper[program]);

export const routerToProgramMapper = {
  '/misc': {
    baseCrudKey: 'miscWhom',
  },
  '/mtg': {
    baseCrudKey: 'mtgWhom',
  },
};

export const routerToProgram = (program) => (routerToProgramMapper[program]);

export const getProgramDetailsByParams = (self) => {
  const program = ((self.$route.matched || [])[0] || {}).path;
  if (program) {
    return routerToProgram(program);
  }
  return {};
};
