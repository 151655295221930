import gql from 'graphql-tag';
import { clients } from '../../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../../util/shared/vue-global';

const { backend } = clients.direct;
const defaultGLFundsFields = {
  _id: '',
  fiscalYear: '',
  fund: '',
  description: '',
  enableReconciliation: false,
};

export const glFund = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async loadGLFunds({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      const updatedCriteria = { ...criteria };
      const gqlfields = fields || defaultGLFundsFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query glFunds($criteria: glFundCriteriaType) {
            glFunds(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria: updatedCriteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        if (baseCrudKey && results.data && results.data.glFunds) {
          const GLFunds = results.data ? results.data.glFunds || [] : [];
          commit('base/crud/setItems', [baseCrudKey, GLFunds], { root: true });
        }
        return results.data.glFunds;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the GL Funds list.', { root: true });
        return [];
      }
    },
    async upsertGlFund({ dispatch, rootGetters, commit }, { item, fields, baseCrudKey }) {
      let results;
      try {
        const gqlfields = fields || defaultGLFundsFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertGlFund ($item: glFundInputType!)  {
                upsertGlFund(upsert: $item) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const GLFundStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newGLFund = results.data.upsertGlFund;
        const newList = mergeByIdNewAtTop(GLFundStateData || [], newGLFund);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the GL Funds.', { root: true });
      }
    },
  },
};

export default glFund;
