export const markNotificationRead = (notificationObj) => {
  const existingmarkReadIDs = JSON.parse(localStorage.getItem('markReadID') || '[]');
  if (!existingmarkReadIDs.includes(notificationObj._id)) {
    existingmarkReadIDs.push(notificationObj._id);
    localStorage.setItem('markReadID', JSON.stringify(existingmarkReadIDs));
  }
};

export const formatNotification = (notificationStr = '') => {
  if (notificationStr.length > 65) {
    return `${notificationStr.substring(0, 62)}...`;
  }
  return notificationStr;
};
