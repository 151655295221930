<template>
  <div>
    <v-snackbar
      class="notification-snackbar"
      v-model="showNotification"
      v-for="(message,index) in snackbarAnnouncements.slice(0,5).reverse()"
      :style="{'margin-bottom':calcMargin(index), 'z-index': zIndexNotiSnackbar}"
      :key="index"
      :vertical="true"
      bottom
      right
      :timeout="-1"
      height="15vh"
      width="35vw">
      {{ formatNotification(message.body) }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="open-notification-dialog-btn"
          v-bind="attrs"
          v-if="(message.body || '').length > 75"
          color="blue"
          text
          @click="openNotificationDialog(message)">
          Read More
        </v-btn>
        <v-btn
          class="mark-notification-read-btn"
          color="blue"
          text @click="markMessageRead(message)">
          Mark Read
        </v-btn>
    </template>
    </v-snackbar>
    <NotificationDialog
    :notification="notification"
    :notificationDialog="openDialog"
    @showNotificationDialog="closeNotificationDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { markNotificationRead, formatNotification } from './notificationUtil';
import { TMC_CONSTANTS } from '../../../util/shared/constants';
import NotificationDialog from './NotificationDialog.vue';

export default {
  name: 'NotificationSnackbar',
  components: {
    NotificationDialog,
  },
  data: () => ({
    timer: undefined,
    openDialog: false,
    notification: {
      _id: ' ',
      title: ' ',
      body: ' ',
    },
    zIndexNotiSnackbar: TMC_CONSTANTS.COMPONENT_Z_INDEX.NOTIFICATION_SNACKBAR,
  }),
  created() {
    this.showAnnouncement();
    this.announcementPolling();
  },
  computed: {
    ...mapState({
      notificationList: (state) => state.notifications.announcements || [],
      snackbarAnnouncements: (state) => state.notifications.snackbarAnnouncements || [],
    }),
    showNotification() {
      return this.snackbarAnnouncements.length > 0;
    },
  },
  methods: {
    ...mapActions('notifications', [
      'loadAnnouncements',
    ]),
    ...mapMutations('notifications', [
      'setSnackbarAnnouncements',
    ]),
    formatNotification,
    calcMargin(i) {
      return `${i * 16}vh`;
    },
    markMessageRead(message) {
      markNotificationRead(message);
      const indexToDelete = this.snackbarAnnouncements.findIndex((obj) => obj._id === message._id);
      this.snackbarAnnouncements.splice(indexToDelete, 1);
    },
    openNotificationDialog(message) {
      this.openDialog = true;
      this.notification = message;
      this.markMessageRead(message);
    },
    closeNotificationDialog(val) {
      this.openDialog = val;
    },
    async showAnnouncement() {
      await this.loadAnnouncements();
      const existingmarkReadIDs = JSON.parse(localStorage.getItem('markReadID') || '[]');
      let newArr = JSON.parse(JSON.stringify(this.notificationList));
      for (let i = 0; i < existingmarkReadIDs.length; i += 1) {
        const readAnnouncmentId = existingmarkReadIDs[i];
        newArr = newArr.filter(
          (item) => item._id !== readAnnouncmentId,
        );
      }
      this.setSnackbarAnnouncements(newArr);
    },
    async announcementPolling() {
      this.timer = setInterval(
        this.showAnnouncement,
        600000,
      );
    },
  },
};
</script>
<style>
.notification-snackbar {
  z-index: v-bind('zIndexNotiSnackbar');
};
</style>
