import gql from 'graphql-tag';
import { clients } from '../../util/clients';

const { backend } = clients.direct;

export const InterestApportionmentActions = {
  async fetchInterestApportionment({ commit, dispatch }, criteria = {}) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });

      results = await backend.query({
        query: gql` query interestapportionment($interestAmount: Float!, $fromDate: String!, $toDate: String!, $departments: [ID], $fiscalYear: Int!) {
          interestapportionment(interestAmount: $interestAmount, fromDate: $fromDate, toDate: $toDate, departments: $departments, fiscalYear: $fiscalYear) {
            fromDate
            toDate
            interestApportionmentDetails
          }
        }`,
        variables: criteria,
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.interestapportionment) {
        commit('setInterestApportionment', results.data.interestapportionment);
      }
    } catch (e) {
      console.warn(e);
      dispatch('flashError', 'There was a problem interest apportionment details.', { root: true });
    }
  },
};

export default InterestApportionmentActions;
