import { SystemConfigActions } from './systemconfig.actions';

const firstElementIndex = 0;

export const SystemConfig = {
  namespaced: true,
  state: () => ({
    globalSettingItem: {},
    bankSettingItem: {},
    allBanksData: [],
    banksList: [],
    odSettingItem: {},
    warSettingItem: {},
    miscSettingItem: {},
    mtgSettingItem: {},
    workStationItem: {},
    devSettingItem: {},
  }),
  mutations: {
    newForm(state) {
      state.bankSettingItem = {};
    },
    setSettingItem(state, { items, scope }) {
      const configData = [];
      const banksList = [];
      for (let i = 0; i < items.length; i += 1) {
        const configItem = { ...items[i].configuration };
        configItem.id = items[i].id;
        configData.push(configItem);
        if (scope === 'bank') {
          banksList.push(
            {
              id: items[i].id,
              bankName: items[i].configuration.bankName,
            },
          );
        }
      }
      switch (scope) {
        case 'global':
          state.globalSettingItem = configData[firstElementIndex] || {};
          break;
        case 'bank':
          state.allBanksData = configData;
          state.bankSettingItem = configData[firstElementIndex];
          state.banksList = banksList;
          break;
        case 'odconfig':
          state.odSettingItem = configData[firstElementIndex] || {};
          break;
        case 'warconfig':
          state.warSettingItem = configData[firstElementIndex] || {};
          break;
        case 'miscConfig':
          state.miscSettingItem = configData[firstElementIndex] || {};
          break;
        case 'mtgConfig':
          state.mtgSettingItem = configData[firstElementIndex] || {};
          break;
        case 'devConfig':
          state.devSettingItem = configData[firstElementIndex] || {};
          break;
        default:
          break;
      }
    },
    setSelectedData(state, recordID) {
      for (let i = 0; i < state.allBanksData.length; i += 1) {
        if (state.allBanksData[i].id === recordID) {
          state.bankSettingItem = state.allBanksData[i];
          break;
        }
      }
    },
    setAfterUpdate(state, { data, scope, newRecordID }) {
      let updatedBanksData = [];
      const updatedRecord = { ...data };
      if (newRecordID !== null) {
        updatedRecord.id = newRecordID;
      }
      switch (scope) {
        case 'global':
          state.globalSettingItem = updatedRecord;
          break;
        case 'bank':
          if (newRecordID !== null) {
            state.bankSettingItem = updatedRecord;
            state.allBanksData = [updatedRecord, ...state.allBanksData];
            const { bankName, id } = updatedRecord;
            const newBankList = {
              bankName,
              id,
            };
            state.banksList = [newBankList, ...state.banksList];
          } else {
            state.bankSettingItem = data;
            updatedBanksData = state.allBanksData.filter((t) => t.id !== data.id);
            const allBanksData = [data, ...updatedBanksData];
            state.banksList = allBanksData.map((obj) => {
              const nObj = {
                id: obj.id,
                bankName: obj.bankName,
              };
              return nObj;
            });
            state.allBanksData = allBanksData;
          }
          break;
        case 'odconfig':
          state.odSettingItem = updatedRecord;
          break;
        case 'warconfig':
          state.warSettingItem = updatedRecord;
          break;
        case 'miscConfig':
          state.miscSettingItem = updatedRecord;
          break;
        case 'mtgConfig':
          state.mtgSettingItem = updatedRecord;
          break;
        case 'devConfig':
          state.devSettingItem = updatedRecord;
          break;
        default:
          break;
      }
    },
    setWorkstationItem(state, item) {
      state.workStationItem = item;
    },
  },
  actions: {
    ...SystemConfigActions,
  },
};

export default SystemConfig;
