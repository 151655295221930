import { ImportActions } from './bulk.actions';
import { ProfileActions } from './profile.actions';
import { DestinationActions } from './destination.actions';

const pipelineData = {
  description: 'Create new vouchers',
  pipeline: {
    type: 'import-od-trans-ascii',
    action: 'upsertODTransactions',
  },
};

export const Bulk = {
  namespaced: true,
  state: () => ({
    listOfProfiles: [],
    dataDestionationsList: [],
    destionationFields: [],
    profileData: {},
    pipelines: [pipelineData],
    processedFileData: {
      matchedData: {
        headers: [],
        data: [],
        totalAmount: 0,
      },
      unmatchedData: {
        headers: [],
        data: [],
        totalAmount: 0,
      },
    },
  }),
  mutations: {
    setPipelineData(state, profiles) {
      const formatedProfiles = profiles.map((x) => {
        let rObj = {};
        rObj = {
          description: x.type,
          pipeline: {
            type: 'csv-parser-pipeline',
            profile: x.type,
            action: x.action,
            noheader: x.noheader,
          },
        };
        return rObj;
      });
      state.pipelines = formatedProfiles.concat(pipelineData);
    },
    setProfiles(state, profiles) {
      state.listOfProfiles = profiles;
    },
    setProfile(state, profile) {
      state.profileData = profile;
    },
    setDataDestionations(state, destionations) {
      state.dataDestionationsList = destionations;
    },
    setDestionationFields(state, destionationFields) {
      state.destionationFields = destionationFields;
    },
    setProcessedDetails(state, processedFileData) {
      state.processedFileData = processedFileData;
    },
  },
  actions: {
    ...ImportActions,
    ...ProfileActions,
    ...DestinationActions,
  },
};

export default Bulk;
