import cbor from 'cbor-js';
import { clients } from '../../util/clients';
import { arrayToBase64Str, base64StrToArray } from '../../util/authorization';

// eslint-disable no-undef
export const checkWebAuthN = () => {
  const naPkc = {
    isUserVerifyingPlatformAuthenticatorAvailable: () => Promise.resolve(false),
  };
  const pkc = window.PublicKeyCredential || naPkc;
  return pkc.isUserVerifyingPlatformAuthenticatorAvailable();
};

export const getChallengeByUsername = async (username) => {
  const resp = await clients.auth.getWebAuthNPubKeyChallenge(username);
  if (!(resp && resp.data && resp.data.challenge)) {
    return {};
  }
  const { challenge } = resp.data;
  const challengeArray = base64StrToArray(challenge);
  return {
    challenge,
    challengeArray,
  };
};

// derive public server-side artifacts
export const derivePubSerSideObjs = async (credential) => {
  // decode the clientDataJSON into a utf-8 string
  const utf8Decoder = new TextDecoder('utf-8');
  const decodedClientData = utf8Decoder.decode(
    credential.response.clientDataJSON,
  );

  // parse the string as an object
  const clientDataObj = JSON.parse(decodedClientData);

  // console.info(clientDataObj.challenge); // ..with this

  const decodedAttestationObj = await cbor.decode(
    credential.response.attestationObject,
  );

  const { authData } = decodedAttestationObj;
  // get the length of the credential ID
  const dataView = new DataView(
    new ArrayBuffer(2),
  );
  const idLenBytes = authData.slice(53, 55);
  idLenBytes.forEach(
    (value, index) => dataView.setUint8(
      index, value,
    ),
  );
  const credentialIdLength = dataView.getUint16();
  const credentialId = authData.slice(
    55, 55 + credentialIdLength,
  );
  const publicKeyBytes = authData.slice(
    55 + credentialIdLength,
  );

  // base64js.fromByteArray(credentialId);
  const credentialIdStr = arrayToBase64Str(credentialId);
  // base64js.fromByteArray(publicKeyBytes);
  const publicKeyByteStr = arrayToBase64Str(publicKeyBytes);
  const challengeStr = clientDataObj.challenge;
  return {
    credentialIdStr,
    publicKeyByteStr,
    challengeStr,
  };
};

export const publicKeyBytesToObj = (publicKeyBytes) => cbor.decode(
  publicKeyBytes.buffer,
);

export default {
  checkWebAuthN,
};
