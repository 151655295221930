<template>
  <v-dialog
      v-model="footerLogsDialog"
      width="80vw"
      max-height="80vh">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          class="font-weight-thin"
        >(recent logs)</v-btn>
      </template>
      <v-card>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                rounded
                @click="copyToClipboard"
              >Copy to clipboard</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                ref="footerLogsTA"
                label="Recent Logs"
                :value="formattedLogs"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-col align="right">
              <v-btn
                icon
                @click="footerLogsDialog = false"
              >
                <v-icon>{{icons.mdiClose}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</template>

<script>
import {
  mapState,
} from 'vuex';
import {
  mdiClose,
} from '@mdi/js';

const initdata = () => ({
  icons: {
    mdiClose,
  },
  footerLogsDialog: false,
});

export default {
  name: 'FooterLogsDialog',
  components: {
  },
  data: () => initdata(),
  computed: {
    ...mapState({
      recentLogs: (state) => state.recentLogs,
    }),
    formattedLogs() {
      const logsStr = this.recentLogs
        .map((l) => `(${l.when}) ${l.type} - ${l.msg}`)
        .join('\n');
      return logsStr === '' ? '(no recent logs found)' : logsStr;
    },
  },
  methods: {
    async copyToClipboard() {
      // unit test compatibility
      if (navigator && navigator.clipboard) {
        await navigator.clipboard.writeText(this.formattedLogs);
      }
    },
  },
};
</script>
