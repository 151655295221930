const logErrorsIfAny = (type, errors, commit) => {
  let errorStr = '';
  if (errors) {
    if (Array.isArray(errors)) {
      errorStr = errors.join('\n');
    } else {
      errorStr = errors.toString();
    }
  }
  if (commit && errorStr !== '') {
    commit('appendRecentLog', [type, errorStr], { root: true });
  }
};

// if errors occur in the client middleware,
// we create a circular dependency (store -> client -> store)
// this approach isn't great, but it lets us put those errors somewhere
// so that we can manage them later from a catch block in our Vuex actions
let cachedErrors = [];

const internalLogCachedErrors = (commit) => {
  if (cachedErrors && cachedErrors.length > 0) {
    logErrorsIfAny('logCachedErrors', cachedErrors, commit);
    cachedErrors = [];
  }
};

export default {
  handleGQLErrors: (results, commit) => {
    if (results && results.errors) {
      logErrorsIfAny('handleGQLErrors', results.errors, commit);
      console.error(results.errors);
    }
    internalLogCachedErrors(commit);
  },
  handleAxiosErrors: (error, commit) => {
    if (error) {
      logErrorsIfAny('handleAxiosErrors', error, commit);
      console.error(error);
      throw error;
    }
    internalLogCachedErrors(commit);
  },
  cacheErrors: (errors) => {
    if (errors) {
      if (Array.isArray(errors)) {
        cachedErrors = [...cachedErrors, ...errors];
      } else {
        cachedErrors = [...cachedErrors, errors.toString()];
      }
    }
  },
  logCachedErrors: (commit) => {
    internalLogCachedErrors(commit);
  },
};
