import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../util/shared/vue-global';

const { backend } = clients.direct;

export const whom = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async loadWhoms({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query warwhoms($criteria: WarWhomCriteriaType) {
            warwhoms(criteria: $criteria) {
                ${fieldsToGqlString(fields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        const whoms = results.data ? results.data.warwhoms || [] : [];
        commit('base/crud/setItems', [baseCrudKey, whoms], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the to-whom list.', { root: true });
      }
    },
    async delWhom({ commit, rootGetters, dispatch }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation deleteWarWhom ($id: ID!)  {
                deleteWarWhom(id: $id) {
                  _id
                }
              }`,
          variables: {
            id: item._id,
          },
        });
        clients.handleGQLErrors(results, commit);
        const delId = results.data.deleteWarWhom._id;
        const whomsStateData = rootGetters['base/crud/items'](baseCrudKey);
        const updatedData = whomsStateData.filter((d) => d._id !== delId);
        commit('base/crud/setItems', [baseCrudKey, updatedData], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem deleting the To Whom-List', { root: true });
      }
    },
    async upsrtWhom({ dispatch, rootGetters, commit }, { item, fields, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsert ($item: WarWhomInputType!)  {
                upsertWarWhom(upsert: $item) {
                  ${fieldsToGqlString(fields)},
                }
              }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const whomsStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newWhom = results.data.upsertWarWhom;
        const newList = mergeByIdNewAtTop(whomsStateData || [], newWhom);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the To Whom-List', { root: true });
      }
    },
  },
};

export default whom;
