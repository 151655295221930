import gql from 'graphql-tag';
import { clients } from '../../util/clients';

const { backend } = clients.direct;

const isConfigured = (config) => {
  if (
    config.apiAccessId
    && config.locationId
  ) {
    return true;
  }
  return false;
};

export const ForteService = {
  namespaced: true,
  state: () => ({
    forteConfig: {},
  }),
  mutations: {
    setConfigData(state, config) {
      state.forteConfig = config;
    },
  },
  actions: {
    async fetchForteConfig({ dispatch, commit }, { scope, forteAmount }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        const amount = (forteAmount || '').toString();
        results = await backend.query({
          query: gql` query fetchForteConfig($scope: String, $amount: String) {
            forteCheckoutData(scope: $scope, amount: $amount) { scope, configData }
          }`,
          variables: { scope, amount },
          fetchPolicy: 'no-cache',
        });
        // console.log(results);
        const response = { ...results.data.forteCheckoutData.configData };
        response.isConfigured = isConfigured(response);
        commit('setConfigData', response);
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem loading Configs.', { root: true });
      }
    },
  },
};

export default ForteService;
