import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../util/shared/vue-global';

const { backend } = clients.direct;

const fields = {
  _id: '',
  districtCode: '',
  districtName: '',
  isJointCounty: '',
  isRemitFund: '',
  isRequiredAdaForCalculation: '',
  taxUnit: {
    _id: '',
    toUnitDescForAppro: '',
    averageDailyAttendence: '',
    appSection: '',
  },
};

export const taxDistrict = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async loadTaxDistrict({ commit, dispatch }, {
      criteria, baseCrudKey, postProcessTaxDistrict,
    }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query taxDistrict($criteria: taxDistrictCriteriaType) {
            taxDistrict(criteria: $criteria) {
                    ${fieldsToGqlString(fields)},
                  }
                }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const taxDistricts = results.data ? results.data.taxDistrict || [] : [];
        if (baseCrudKey) {
          const processedTaxDistricts = postProcessTaxDistrict
            ? postProcessTaxDistrict(taxDistricts) : taxDistricts;
          commit('base/crud/setItems', [baseCrudKey, processedTaxDistricts], { root: true });
        }
        return taxDistricts;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Tax district list.', { root: true });
      }
      return [];
    },
    async upsertTaxDistrict({ dispatch, rootGetters, commit }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertTaxDistrict($item: taxDistrictInputType!)  {
            upsertTaxDistrict(upsert: $item) {
              ${fieldsToGqlString(fields)},
            }
          }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const taxDistrictStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newTaxDistrict = results.data.upsertTaxDistrict;
        const newList = mergeByIdNewAtTop(taxDistrictStateData || [], newTaxDistrict);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Tax District', { root: true });
      }
    },
  },
};

export default taxDistrict;
