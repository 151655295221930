import { fundReconciliation } from './fundReconciliation/index';
import { glFund } from './glFund/index';

export const util = {
  namespaced: true,
  modules: {
    fundReconciliation,
    glFund,
  },
};

export default util;
