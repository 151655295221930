<template>
  <div>
    <v-row>
      <v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs" v-on="on"
              color="secondary"
              class="ma-2 white--text"
              id="signinwebauthn"
              @click="login"
            >
              (BETA) Passwordless Auth
              <v-icon right> {{icons.mdiLogin}} </v-icon>
            </v-btn>
          </template>
          <span>Powered by WebAuthN</span>
        </v-tooltip>
      </v-col>
      <v-col cols="4">
        <v-btn text color="secondary"
              class="ma-2 white--text"
              id="preferpass"
              @click="preferPassword"
        >(or, click here if you prefer the password)</v-btn>
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>

import {
  mdiLogin,
} from '@mdi/js';

export default {
  name: 'LoginWebAuthN',
  data: () => ({
    icons: {
      mdiLogin,
    },
    showPass: false,
  }),
  props: {
    title: {
      type: String,
      default: 'Sign-in',
    },
  },
  methods: {
    preferPassword() {
      this.$emit('preferPassword');
    },
    login() {
      this.$emit('login');
    },
  },
};
</script>
