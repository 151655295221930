import gql from 'graphql-tag';
import { clients } from '../../util/clients';

const { backend } = clients.direct;

export const DestinationActions = {
  async loadDataDestinations({ dispatch, commit }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` query {
          ImportDataDestinations { description, action }
        }`,
        fetchPolicy: 'no-cache',
      });
      const importDataDestinations = results.data.ImportDataDestinations;
      if (importDataDestinations !== undefined) {
        commit('setDataDestionations', importDataDestinations);
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading Configs.', { root: true });
    }
  },
  async fetchDestionationByAction({ dispatch, commit }, action) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` query fetchDestionationByAction($action: String) {
          ImportDataDestination(action: $action) { action, configFields }
        }`,
        variables: { action },
        fetchPolicy: 'no-cache',
      });
      const importDataDestination = results.data.ImportDataDestination;
      if (importDataDestination !== null) {
        const arrConfigFields = Object.keys(importDataDestination.configFields);
        commit('setDestionationFields', arrConfigFields);
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading Configs.', { root: true });
    }
  },
};

export default DestinationActions;
