import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth/index';
import { audit } from './audit/index';
import { base } from './base/index';
import { enums } from './enums/index';
import { files } from './files/index';
import { jobs } from './jobs/index';
import { user } from './user/index';
import { Bulk } from './bulk/index';
import { ForteService } from './ForteService/index';
import { OD } from './OD/index';
import { SystemConfig } from './SystemConfig/index';
import { UserAdministration } from './UserAdministration/index';
import { config } from './config/index';
import { shared } from './shared/index';
import { war } from './war/index';
import { misc } from './misc/index';
import { mtg } from './mtg/index';
import { common } from './common/index';
import { notifications } from './announcements/index';
import { userGuide } from './userGuide/index';
import { glDaily } from './glDaily/index';
import { MigrationManager } from './MigrationManager/index';
import { createNewFiscalYearManager } from './createNewFiscalYear/index';

Vue.use(Vuex);

export const actions = {
  // root-level convenience method
  async reAuth({ dispatch }) {
    return dispatch('auth/token/reAuth');
  },
  flashError({ commit }, msg) {
    commit('setMessage', msg);
    commit('appendRecentLog', ['flashError', msg]);
    commit('setFlashType', 'red');
    commit('setSnackbar', true);
  },
  flashWarn({ commit }, msg) {
    commit('setMessage', msg);
    commit('appendRecentLog', ['flashWarn', msg]);
    commit('setFlashType', 'amber accent-4');
    commit('setSnackbar', true);
  },
  flashInfo({ commit }, msg) {
    commit('setMessage', msg);
    commit('appendRecentLog', ['flashInfo', msg]);
    commit('setFlashType', 'gray');
    commit('setSnackbar', true);
  },
  flashSuccess({ commit }, msg) {
    commit('setMessage', msg);
    commit('appendRecentLog', ['flashSuccess', msg]);
    commit('setFlashType', 'green');
    commit('setSnackbar', true);
  },
  clearSnackbar({ commit }) {
    commit('setMessage', undefined);
    commit('setSnackbar', false);
  },
  logException({ commit }, exc) {
    if (exc) {
      console.error('logException', exc);
      commit('appendRecentLog', ['exception', exc.toString()]);
    }
  },
};

export const mutations = {
  setMessage(state, msg) {
    state.error = msg;
  },
  setSnackbar(state, msg) {
    state.snackbar = msg;
  },
  setFlashType(state, color) {
    state.snackbarColor = color;
  },
  setGridLoading(state, payload) {
    const { loading, loadingText } = payload;
    state.gridLoading = loading;
    state.gridLoadingText = loadingText || '';
  },
  appendRecentLog(state, [type, msgIn]) {
    // console.info(`${type}, ${msgIn}`);
    const msg = (msgIn || '').toString();
    const entry = {
      type,
      msg,
      when: new Date().toLocaleString(),
    };
    const { recentLogs } = state;
    if (recentLogs.length >= 10) {
      state.recentLogs = [
        ...recentLogs.slice(1),
        entry,
      ];
    } else {
      state.recentLogs.push(entry);
    }
  },
  setPrintScreen(state, flag) {
    state.isPrintScreen = flag;
  },
  setWkstDialog(state, flag) {
    state.wkstDialog = flag;
  },
};

export const state = () => ({
  error: undefined, // snackbar msg
  snackbar: false, // show snackbar?
  snackbarColor: 'primary',
  // global use for any 1 grid on a given page at a time
  gridLoading: false,
  gridLoadingText: '',
  recentLogs: [],
  isPrintScreen: false,
  wkstDialog: false,
});

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters: {
    todaysDate() { // as "YYYY-MM-DD"
      return new Date().toJSON().slice(0, 10);
    },
    dateControlClassic() {
      return true; // todo, drive from local storage
    },
  },
  modules: {
    auth,
    audit,
    base,
    enums,
    files,
    jobs,
    user,
    Bulk,
    ForteService,
    OD,
    SystemConfig,
    UserAdministration,
    config,
    shared,
    war,
    misc,
    mtg,
    common,
    notifications,
    userGuide,
    glDaily,
    MigrationManager,
    createNewFiscalYearManager,
  },
});
