<template>
  <div id="divSnackbar">

    <!--
    <v-btn color="red" text
      @click="mockSnackbarError">Mock Snackbar Error</v-btn>
    <v-btn color="green" text
      @click="mockSnackbarSuccess">Mock Snackbar Success</v-btn>
    <v-btn color="primary" text
      @click="mockSnackbarInfo">Mock Snackbar Info</v-btn>
    -->
    <v-snackbar
      v-model="snackbar"
      :timeout="errorTimeout"
      :color="color"
    >
      {{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text v-bind="attrs"
          @click="clearSnackbar"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',

  components: {
  },

  data: () => ({
    errorTimeout: 5200, // ms
  }),
  computed: {
    error: {
      set(error) {
        this.$store.dispatch('flashError', error);
      },
      get() {
        return this.$store.state.error;
      },
    },
    snackbar: {
      set(value) {
        this.$store.commit('setSnackbar', value);
      },
      get() {
        return this.$store.state.snackbar;
      },
    },
    color: {
      get() {
        return this.$store.state.snackbarColor;
      },
    },
  },
  methods: {
    clearSnackbar() {
      this.$store.dispatch('clearSnackbar');
    },
    mockSnackbarError() {
      this.$store.dispatch('flashError', 'this is a test!');
    },
    mockSnackbarSuccess() {
      this.$store.dispatch('flashSuccess', 'this is a test! this is a test! this is a test!');
    },
    mockSnackbarInfo() {
      this.$store.dispatch('flashInfo', 'this is a normal test!');
    },
  },
};
</script>
