import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';

export const MiscRoutes = [
  {
    path: '/misc',
    name: 'MiscellaneousReceipts',
    component: () => import('../views/MiscellaneousReceipt.vue'),
    children: [
      {
        path: 'transactions',
        name: 'Miscellaneous Receipts - Transactions',
        component: () => import('../views/Misc/Transactions.vue'),
      },
      {
        path: 'reports',
        name: 'Miscellaneous Receipts - Reports',
        component: () => import('../views/Misc/Reports.vue'),
      },
      {
        path: 'browse',
        name: 'Miscellaneous Receipts - Browse',
        component: () => import('../views/Misc/browse/browse.vue'),
        children: [
          {
            path: 'unitschoolcode',
            name: 'Miscellaneous Receipts - Unit/School Code',
            component: () => import('../views/Misc/browse/UnitSchoolCode.vue'),
          },
          {
            path: 'receivedof',
            name: 'Miscellaneous Receipts - Received Of',
            component: () => import('../views/common/Whom.vue'),
          },
        ],
      },
      {
        path: 'utils',
        name: 'Miscellaneous Receipts - Utils',
        component: () => import('../views/Misc/utils/MiscUtils.vue'),
        children: [
          {
            path: 'createfiscalyear',
            name: 'Miscellaneous Receipts - Create Fiscal Year',
            component: () => import('../views/Misc/utils/MiscellaneousCreateFiscalYear.vue'),
          },
        ],
      },
    ],
  },
];

MiscRoutes.forEach((element, index) => {
  MiscRoutes[index].beforeEnter = async (to, from, next) => {
    await accessCriteria(authRoles.MiscRead, next);
  };
});

export default MiscRoutes;
