import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../util/shared/vue-global';

const { backend } = clients.direct;

const fields = {
  _id: '',
  taxYear: '',
  mills: '',
  levyPercent: '',
  is4Mill: '',
  taxUnit: {
    _id: '',
    toUnitDescForAppro: '',
  },
  taxFund: {
    _id: '',
    fundNumber: '',
    description: '',
  },
  fund: {
    _id: '',
    fund: '',
  },
  taxDistrict: {
    _id: '',
    districtCode: '',
  },
};

const getFundNumber = (record) => ((record.taxFund && record.taxFund.fundNumber) || '');

export const taxLevy = {
  namespaced: true,
  state: () => ({
    regYears: {},
  }),
  mutations: {
    setTaxRegYears(state, regYears) {
      state.regYears = regYears;
    },
  },
  actions: {
    async loadTaxLevy({ commit, dispatch }, {
      criteria, baseCrudKey,
    }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query taxLevy($criteria: taxLevyCriteriaType) {
            taxLevy(criteria: $criteria) {
                    ${fieldsToGqlString(fields)},
                  }
                }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.taxLevy) {
          const levy = results.data ? results.data.taxLevy || [] : [];
          /* Should be moved to post-process method */
          const sortLevyTaxFund = levy.sort((a, b) => getFundNumber(a)
            .localeCompare(getFundNumber(b), 'en', { numeric: true }));
          commit('base/crud/setItems', [baseCrudKey, sortLevyTaxFund], { root: true });
          return sortLevyTaxFund;
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Tax Levys list.', { root: true });
      }
      return [];
    },
    async upsertTaxLevy({ dispatch, rootGetters, commit }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertTaxLevy($item: taxLevyInputType!)  {
            upsertTaxLevy(upsert: $item) {
              ${fieldsToGqlString(fields)},
            }
          }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const taxFundStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newTaxLevy = results.data.upsertTaxLevy;
        const newList = mergeByIdNewAtTop(taxFundStateData || [], newTaxLevy);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Tax Fund', { root: true });
      }
    },
    async upsertBulkTaxLevy({ dispatch, commit }, { item }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertBulkTaxLevy($item: [taxLevyInputType]!)  {
            upsertBulkTaxLevy(upserts: $item) {
              insertedIds,
            }
          }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Tax Levies!', { root: true });
      }
      return results;
    },
    async loadTaxRegYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            taxLevyStatsTYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setTaxRegYears', results.data.taxLevyStatsTYRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Tax Year ranges.', { root: true });
      }
    },
  },
};

export default taxLevy;
