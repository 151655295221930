import gql from 'graphql-tag';
import { privateVuex } from './private';
import { clients } from '../../util/clients';
import { parseJwt, getRawJwt } from '../../util/jwt';
import { authRoles } from '../../router/roleGroups';

const filterByAuthInRoles = (rolesArray, authorizedRoles) => {
  if (rolesArray.some((item) => authRoles.SuperAdmin.includes(item))) {
    return authRoles.SuperAdmin; // can access all resources
  }
  if (authorizedRoles.includes('CommonUser')) {
    return ['CommonUser']; // common user role, can access all common resources
  }
  return authorizedRoles.filter((value) => rolesArray.includes(value));
};

export const isAuthorised = (authorizedRoles) => {
  let result = [];
  const jwt = getRawJwt();
  if (jwt) {
    const decodedToken = parseJwt(jwt);
    const rolesArray = decodedToken.roles || [];
    result = filterByAuthInRoles(rolesArray, authorizedRoles);
  }
  return result.length > 0;
};

export const token = {
  namespaced: true,
  state: () => ({
    dialog: false,
    types: null,
    resetPasswordDialog: false,
    overlay: false,
    authJwt: undefined,
    authUser: undefined,
    authExp: undefined,
  }),
  mutations: {
    setDialog(state, dialog) {
      state.dialog = dialog;
    },
    setAuthTypes(state, types) {
      state.types = types;
    },
    setResetPasswordDialog(state, dialog) {
      state.resetPasswordDialog = dialog;
    },
    setOverlay(state, overlay) {
      state.overlay = overlay;
    },
    rehydrateLogin(state) {
      // console.log('rehydrating');
      const context = privateVuex.rehydrateAuth();
      if (context.jwt && context.user && context.exp) {
        // console.log('with values');
        state.authJwt = context.jwt;
        state.authUser = context.user;
        state.authExp = context.exp;
      }
    },
    setLoggedIn(state, context) {
      state.authJwt = context.jwt;
      state.authUser = context.username;

      // decode to cache exp value
      const decodedToken = parseJwt(context.jwt);
      state.authExp = decodedToken.exp;

      privateVuex.setLoggedIn(context);
    },
    setLoggedOut(state) {
      state.authJwt = undefined;
      state.authUser = undefined;
      state.authExp = undefined;

      privateVuex.setLoggedOut();
    },
  },
  actions: { // actions has both local state, and rootState
    // re-auth, if needed, or just return the currently valid token
    async reAuth({ commit }) {
      // console.log('reAuth');
      const auth = privateVuex.getAuthIfValid();
      // is re-auth needed?
      if (!auth.jwt) {
        // console.log('reAuth needed');
        // make a new re-auth, or if a re-auth is
        // in progress, just latch onto that one
        if (!privateVuex.getReAuthPromise()) {
          let reauthResolve;
          let reauthReject;
          // otherwise, start a re-auth
          // console.log('reAuth starting');
          const reauth = new Promise((resolve, reject) => {
            reauthResolve = resolve;
            reauthReject = reject;
            // kick off dialog
            commit('setDialog', true);
          });
          privateVuex.setPrivateState({
            reauth,
            reauthResolve,
            reauthReject,
          });
        }
        return privateVuex.getReAuthPromise();
      }
      // token is good to go 👍 just use it
      return Promise.resolve(auth);
    },
    async resolveAuth({ commit }, tokenContext) {
      // console.log(`Login issue, tokenContext: ${tokenContext}`);
      commit('setDialog', false);
      commit('setLoggedIn', tokenContext);
      privateVuex.resolve(tokenContext);
      privateVuex.setPrivateState({}); // clear out for the next re-auth
    },
    // if something goes wrong, use this to notify in the snackbar
    async rejectAuth({ commit, dispatch }, error) {
      const flashErrorMsg = `There was a problem authenticating: ${error}`;
      commit('setDialog', true);
      dispatch('flashError', flashErrorMsg, { root: true });

      privateVuex.reject(error);
      privateVuex.setPrivateState({}); // clear out for the next re-auth
    },
    async loadAuthTypes({ commit, dispatch }) {
      try {
        const types = await clients.auth.getLoginTypes();
        commit('setAuthTypes', types.data);
      } catch (e) {
        clients.logCachedErrors(commit);
        dispatch('logException', e, { root: true });
        // be sort of silent on this one (log only),
        // because the system could continue to work despite it
        dispatch(
          'logException',
          'There was a problem loading authentication types.',
          { root: true },
        );
      }
    },
    async restartUserDeviceOtp({ commit, dispatch }, userDeviceOtp) {
      try {
        if (userDeviceOtp === '') {
          return;
        }
        const resp = await clients.auth.restartUserDeviceOtp(userDeviceOtp);
        console.info(resp);
        if (!(resp && resp.data && resp.data.success)) {
          commit('appendRecentLog', 'Reset / initialization of the user-device OTP failed for some reason.', { root: true });
        }
      } catch (e) {
        clients.logCachedErrors(commit);
        dispatch('logException', e, { root: true });
        // be sort of silent on this one (log only),
        // because the system could continue to work despite it
        dispatch(
          'logException',
          'There was a problem registering an OTP remote auth login.',
          { root: true },
        );
      }
    },
    // here, the server either authorizes us, prompts for a new password, or notifies us of failure
    // need to handle all 3 w/o losing the pending promise
    // (i.e. cannot call rejectAuth yet for failed password or for password change)
    async genToken({ dispatch, commit }, payload) {
      const isBackgroundPoll = payload && payload.type === 'remoteauth';
      try {
        if (!isBackgroundPoll) {
          commit('setOverlay', true);
        }
        const tokenContext = await clients.auth.genToken(payload);
        if (tokenContext && tokenContext.data && tokenContext.data.status === 'authorized'
          && tokenContext.data.passExp === true) {
          // "success", but password is expired, prompt update
          commit('setLoggedIn', tokenContext.data);
          commit('setResetPasswordDialog', true);
        } else if (tokenContext && tokenContext.data && tokenContext.data.status === 'disabled') {
          dispatch('flashError', 'This user has been disabled by your administrator.', { root: true });
          commit('setOverlay', false);
        } else if (tokenContext && tokenContext.data && tokenContext.data.status === 'pending') {
          // try again later
          commit('setOverlay', false);
        } else if (tokenContext && tokenContext.data && tokenContext.data.status === 'invalid') {
          dispatch('flashError', [
            'Your authentication failed for this user. If you were logging in by password, ',
            'you may need to have it reset. If you were logging in by WebAuthN / passwordless, ',
            'you may need to re-register your device.',
          ].join(''), { root: true });
          commit('setOverlay', false);
        } else {
          // all good, turn them loose again
          dispatch('resolveAuth', tokenContext.data);
          commit('setOverlay', false);

          // post-processing - authorize any pending remote auths by user
          dispatch('setPendingRemoteAuthStatus', 'approved');
        }
        return tokenContext;
      } catch (error) {
        clients.logCachedErrors(commit);
        commit('setOverlay', false);
        // remoteauth is a background poll, so do nothing if unauthorized
        if (!isBackgroundPoll) {
          if (error && error.response && error.response.status === 401) {
            // bad password, update the UI accordingly
            dispatch('flashError', 'Bad username or password.', { root: true });
          } else {
            dispatch('rejectAuth', 'Unable to authenticate for an unknown reason. Try again.');
          }
        }
        return {
          status: 'failure',
          message: 'unknown reason',
        };
      }
    },
    async setPendingRemoteAuthStatus({ getters, dispatch }, approvalStatus) {
      try {
        const username = getters.authUser;
        await dispatch('reAuth', null, { root: true });
        await clients.direct.auth.mutate({
          mutation: gql`mutation setRemoteAuthStatus ($username: String, $approvalStatus: String)  {
            setRemoteAuthStatus(username: $username, approvalStatus: $approvalStatus)
          }`,
          variables: {
            username,
            approvalStatus,
          },
        });
      } catch (e) {
        dispatch('logException', e, { root: true });
        // be sort of silent on this one (log only),
        // because the system could continue to work despite it
        dispatch(
          'logException',
          'There was a problem checking for pending remote auths.',
          { root: true },
        );
      }
    },
  },
  getters: { // state, getters
    authUser(state) {
      return state.authUser || 'anonymous';
    },
    authUserRoles(state) {
      if (state.authJwt === undefined || state.authJwt === '') {
        return [];
      }
      const decodedToken = parseJwt(state.authJwt);
      return decodedToken.roles || [];
    },
    isODAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.ODRead);
      return filtered.length > 0;
    },
    isIEBAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.ImportExportAdmins);
      return filtered.length > 0;
    },
    isBankProfileAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.BankProfileAdmins);
      return filtered.length > 0;
    },
    isMtgAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.MtgRead);
      return filtered.length > 0;
    },
    isSysConfigAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.SettingsUsers);
      return filtered.length > 0;
    },
    isUserAdminAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.UserAdmins);
      return filtered.length > 0;
    },
    isAuditUserAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.AuditUsers);
      return filtered.length > 0;
    },
    isWarAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.WarRead);
      return filtered.length > 0;
    },
    isMiscAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.MiscRead);
      return filtered.length > 0;
    },
    isSharedProgAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.shared.SharedProgram);
      return filtered.length > 0;
    },
    isGlAccessible(state, getters) {
      const filtered = filterByAuthInRoles(getters.authUserRoles, authRoles.GlRead);
      return filtered.length > 0;
    },
  },
};

export default token;
