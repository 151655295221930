import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';

export const UserAdministrationRoutes = [
  {
    path: '/user-administration',
    name: 'User Administration',
    component: () => import('../views/common/UserAdministration.vue'),
    beforeEnter: async (to, from, next) => {
      await accessCriteria(authRoles.UserAdmins, next);
    },
  },
];

export default UserAdministrationRoutes;
