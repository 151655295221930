import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../util/shared/vue-global';

const { backend } = clients.direct;

const fields = {
  _id: '',
  fund: '',
  fiscalYear: '',
  description: '',
  bank: {
    _id: '',
    bankName: '',
  },
  type: '',
  lastWarrant: '',
  lastPayment: '',
  totalEncumberedAmount: '',
  crossReferenceFund: '',
  isRequiredUnit: '',
  altKey: '',
  calculateInterest: '',
  isExcludedFromExport: '',
  backPage: '',
  clearingFund: '',
  line: '',
};

export const fund = {
  namespaced: true,
  state: () => ({
    regYears: {},
    selectedItems: [],
  }),
  mutations: {
    setFiscalYears(state, regYears) {
      state.regYears = regYears;
    },
    setSelectedItems(state, items) {
      state.selectedItems = items;
    },
  },
  actions: {
    async loadFunds({ commit, dispatch }, {
      criteria, baseCrudKey, postProccessFunds,
    }) {
      let results;
      try {
        const sortingDir = (criteria.sortBy || [])[0] ? criteria.sortBy[0].direction : 'asc';
        await dispatch('reAuth', null, { root: true });
        const cleanedCriteria = { ...criteria };
        results = await backend.query({
          query: gql` query fund($criteria: FundCriteriaType) {
                  fund(criteria: $criteria) {
                    ${fieldsToGqlString(fields)},
                  }
                }`,
          variables: { criteria: cleanedCriteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.fund) {
          let funds = postProccessFunds
            ? postProccessFunds(results.data.fund) : results.data.fund;
          if (sortingDir === 'desc') {
            funds = funds.sort((a, b) => b.fund - a.fund);
          } else {
            funds = funds.sort((a, b) => a.fund - b.fund);
          }
          if (baseCrudKey) {
            commit('base/crud/setItems', [baseCrudKey, funds], { root: true });
          }
          return funds;
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the funds list.', { root: true });
      }
      return [];
    },
    async upsertFund({ dispatch, rootGetters, commit }, {
      item, baseCrudKey, postProccessFunds,
    }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        const cleanedUpsert = { ...item };
        results = await backend.mutate({
          mutation: gql`mutation upsertFund($upsert: FundInputType!) {
                  upsertFund(upsert: $upsert) {
                    ${fieldsToGqlString(fields)},
                  }
                }`,
          variables: { upsert: cleanedUpsert },
        });
        clients.handleGQLErrors(results, commit);
        const items = rootGetters['base/crud/items'](baseCrudKey);
        const newTran = results.data.upsertFund;
        const newList = mergeByIdNewAtTop(items || [], newTran);
        const funds = postProccessFunds
          ? postProccessFunds(newList) : newList;
        if (baseCrudKey) {
          commit('base/crud/setItems', [baseCrudKey, funds], { root: true });
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the funds list.', { root: true });
      }
      return results;
    },
    async loadFiscalYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            fundStatsFYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setFiscalYears', results.data.fundStatsFYRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
    },
  },
};

export default fund;
