import gql from 'graphql-tag';
import { clients } from '../../util/clients';
import {
  mergeByIdNewAtTop,
  fieldsToGqlString,
} from '../../util/shared/vue-global';

const { backend } = clients.direct;

export const TransformMapperActions = {
  async loadDests({ commit, dispatch }, {
    fields, baseCrudKey,
  }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` {
          transMapperDests {
            ${fieldsToGqlString(fields)},
          }
        }`,
        variables: {},
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.transMapperDests) {
        const transMapperDests = results.data.transMapperDests || [];
        commit('base/crud/setItems', [`${baseCrudKey}-dests`, transMapperDests], { root: true });
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading the transMapperDests list.', { root: true });
    }
  },
  async loadTransformMappers({ commit, dispatch }, {
    criteria, fields, baseCrudKey, postProcess,
  }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      const cleanedCriteria = { ...criteria };
      results = await backend.query({
        query: gql` query TransformMappers($criteria: TransformMapperCriteriaType) {
          TransformMappers(criteria: $criteria) {
            ${fieldsToGqlString(fields)},
          }
        }`,
        variables: { criteria: cleanedCriteria },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.TransformMappers) {
        const TransformMappers = postProcess
          ? postProcess(results.data.TransformMappers) : results.data.TransformMappers;
        const stringifiedMappers = TransformMappers.map((i) => ({
          ...i,
          sourceTemplate: i.sourceTemplate,
          pipeline: JSON.stringify(i.pipeline, null, 2),
          jsonMapper: JSON.stringify(i.jsonMapper, null, 2),
          inputs: JSON.stringify(i.inputs, null, 2),
          csvInputs: JSON.stringify(i.csvInputs, null, 2),
          fixedWidthTemplate: JSON.stringify(i.fixedWidthTemplate, null, 2),
          // customConfig: JSON.stringify(i.customConfig, null, 2) || {},
        }));
        commit('base/crud/setItems', [baseCrudKey, stringifiedMappers], { root: true });
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading the TransformMapper list.', { root: true });
    }
  },
  async upsertTransformMapper({ commit, dispatch, rootGetters }, {
    item, fields, baseCrudKey,
  }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      const parsedItem = {
        ...item,
        sourceTemplate: item.sourceTemplate || ' ',
        pipeline: JSON.parse(item.pipeline || '[]'),
        jsonMapper: JSON.parse(item.jsonMapper || '[]'),
        inputs: JSON.parse(item.inputs || '[]'),
        csvInputs: JSON.parse(item.csvInputs || '[]'),
        fixedWidthTemplate: JSON.parse(item.fixedWidthTemplate || '[]'),
        // customConfig: JSON.parse(item.customConfig || '[]'),
      };
      results = await backend.mutate({
        mutation: gql`mutation upsertTransformMapper ($item: TransformMapperInput )  {
          upsertTransformMapper(upsert: $item ) {
            ${fieldsToGqlString(fields)},
          }
        }`,
        variables: {
          item: parsedItem,
        },
        fetchPolicy: 'no-cache',
      });
      const roundTrippedItem = {
        ...results.data.upsertTransformMapper,
        sourceTemplate: results.data.upsertTransformMapper.sourceTemplate,
        pipeline: JSON.stringify(results.data.upsertTransformMapper.pipeline, null, 2),
        jsonMapper: JSON.stringify(results.data.upsertTransformMapper.jsonMapper, null, 2),
        inputs: JSON.stringify(results.data.upsertTransformMapper.inputs, null, 2),
        csvInputs: JSON.stringify(results.data.upsertTransformMapper.csvInputs, null, 2),
        // customConfig: JSON.stringify(
        //   results.data.upsertTransformMapper.customConfig, null, 2,
        // ) || {},
        fixedWidthTemplate: JSON.stringify(
          results.data.upsertTransformMapper.fixedWidthTemplate,
          null,
          2,
        ),
      };
      clients.handleGQLErrors(results, commit);
      const newList = mergeByIdNewAtTop(
        rootGetters['base/crud/items'](baseCrudKey),
        roundTrippedItem,
      );
      commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem saving the TransformMapper.', { root: true });
    }
  },
  async deleteTransformMapper({ commit, rootGetters, dispatch }, {
    item, baseCrudKey,
  }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation deleteTransformMapper ($id: ID!)  {
          deleteTransformMapper(id: $id) {
            _id
          }
        }`,
        variables: {
          id: item._id,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      const newList = rootGetters['base/crud/items'](baseCrudKey).filter((t) => t._id !== item._id);
      commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem deleting the TransformMapper.', { root: true });
    }
  },
};

export default {
  TransformMapperActions,
};
