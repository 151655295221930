import gql from 'graphql-tag';
import { clients } from '../../util/clients';

const { auth } = clients.direct;
const usersMappingFields = 'id username roles disableUser fullname';

export const UserAdministrationActions = {
  async loadUsers({ dispatch, commit }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await auth.query({
        query: gql`{
          auths {
            ${usersMappingFields}
          }
        }`,
        fetchPolicy: 'no-cache',
      });
      commit('setUsersList', results.data.auths);
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading Users.', { root: true });
    }
  },
  async userEnums({ dispatch, commit }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await auth.query({
        query: gql`{
          UserRoleEnum:__type(name:"UserRoleEnum") {
            enumValues {
              name, description
            }
          },
        }`,
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.UserRoleEnum) {
        commit('setRolesList', results.data.UserRoleEnum.enumValues);
        commit('setRolesConst', results.data.UserRoleEnum.enumValues);
      }
    } catch (e) {
      dispatch('flashError', 'There was a problem getting User Enums.', { root: true });
    }
  },
  async upsertUser({ dispatch, state, commit }, updates) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await auth.mutate({
        mutation: gql`mutation upsertUser ($user: AuthInputType)  {
          upsertAuth(upsert: $user) {
            ${usersMappingFields}
          }
        }`,
        variables: { user: updates },
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.upsertAuth) {
        const replaceId = results.data.upsertAuth.id;
        const newTrans = state.userList.filter((t) => t.id !== replaceId);
        commit('setUsersList', [results.data.upsertAuth, ...newTrans]);
        dispatch('flashSuccess', 'User has successfully saved.', { root: true });
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem to create/update the user (username might be duplicate).', { root: true });
    }
  },
  async deleteUser({ dispatch, state, commit }, user) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await auth.mutate({
        mutation: gql`mutation deleteAuth ($id: ID!)  {
          deleteAuth(id: $id) {
            id
          }
        }`,
        variables: {
          id: user.id,
        },
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.deleteAuth) {
        const deletedID = results.data.deleteAuth.id;
        const newTrans = state.userList.filter((t) => t.id !== deletedID);
        commit('setUsersList', newTrans);
        dispatch('flashSuccess', 'User has successfully deleted.', { root: true });
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem deleting the department.', { root: true });
    }
  },
  async resetUsersPassword({ dispatch, commit }, updates) {
    let results;
    let resultData = {};
    try {
      await dispatch('reAuth', null, { root: true });
      results = await auth.mutate({
        mutation: gql`mutation resetUsersPassword ($changingPassUser: String, $newPassword: String)  {
          resetUsersPassword(changingPassUser: $changingPassUser, newPassword: $newPassword) {
            message status tokenContext { jwt, username, status }
          }
        }`,
        variables: updates,
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.resetUsersPassword) {
        resultData = results.data.resetUsersPassword;
        if (resultData.status) {
          dispatch('flashSuccess', resultData.message, { root: true });
        } else {
          dispatch('flashError', resultData.message, { root: true });
        }
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem resetting password.', { root: true });
    }
    return resultData;
  },
  async uploadMkdir({ dispatch }) {
    try {
      await dispatch('reAuth', null, { root: true });
      await clients.direct.backendRest.get('/user/uploadMkdir');
    } catch (e) {
      dispatch('logException', e, { root: true });
      dispatch('flashError', 'There was a problem setting up a user\'s upload folder.', { root: true });
    }
  },
};

export default UserAdministrationActions;
