import gql from 'graphql-tag';
import { clients } from '../../util/clients';

const { backend } = clients.direct;
const firstIndex = 0;

const ODDepositEnumElements = {
  cashCheckReconciliation: { name: 'CASHCHECK', description: 'Cash and/or Check' },
  ccReconciliation: { name: 'CREDIT', description: 'Credit' },
  trustReconciliation: { name: 'TRUST', description: 'Trust' },
};

const addItemInArray = (array, item) => {
  const arrayObj = [...array];
  const index = arrayObj.findIndex((x) => x.name === item.name);
  if (index === -1) {
    arrayObj.push(item);
  }
  return arrayObj;
};

const removeItemFromArray = (array, item) => {
  const arrayObj = [...array];
  const index = arrayObj.findIndex((x) => x.name === item.name);
  if (index > -1) {
    arrayObj.splice(index, 1);
  }
  return arrayObj;
};

export const enums = {
  namespaced: true,
  state: () => ({
    ODTransType: undefined,
    ODDepositEnum: undefined,
    ODCardItemTypeEnum: undefined,
    ODEFTItemTypeEnum: undefined,
    ODStatus: undefined,
    ODTrustPaymentTypeEnum: undefined,
    WarGovFunctionEnum: undefined,
    WarTransTypeEnum: undefined,
    WarTransStatusEnum: undefined,
    fundReconTypeEnum: undefined,
    cmnSourceTypeEnum: undefined,
    MISCPaymentTypeEnum: undefined,
    MISCEFTTypeEnum: undefined,
    MISCCardTypeEnum: undefined,
    mtgPaymentTypeEnum: undefined,
    mtgEFTTypeEnum: undefined,
    mtgCCardTypeEnum: undefined,
    sharedBackPageEnum: undefined,
    TaxUnitDetailSDRTypeEnum: undefined,
    GlTransDailySectionEnum: undefined,
    GlReportHeadingsTotalsTypeEnum: undefined,
  }),
  mutations: {
    setEnums(state, results) {
      state.ODTransType = results.data.ODTransType.enumValues;
      state.ODDepositEnum = results.data.ODDepositEnum.enumValues;
      state.ODCardItemTypeEnum = results.data.ODCardItemTypeEnum.enumValues;
      state.ODEFTItemTypeEnum = results.data.ODEFTItemTypeEnum.enumValues;
      state.ODStatus = results.data.ODStatus.enumValues;
      state.ODTrustPaymentTypeEnum = results.data.ODTrustPaymentTypeEnum.enumValues;
      state.WarGovFunctionEnum = results.data.WarGovFunctionEnum.enumValues;
      state.WarTransTypeEnum = results.data.WarTransTypeEnum.enumValues;
      state.WarTransStatusEnum = results.data.WarTransStatusEnum.enumValues;
      state.SharedFundTypeEnum = results.data.CmnFundTypeEnum.enumValues;
      state.fundReconTypeEnum = results.data.fundReconTypeEnum.enumValues;
      state.cmnSourceTypeEnum = results.data.cmnSourceTypeEnum.enumValues;
      state.MISCPaymentTypeEnum = results.data.MISCPaymentTypeEnum.enumValues;
      state.MISCEFTTypeEnum = results.data.MISCEFTTypeEnum.enumValues;
      state.MISCCardTypeEnum = results.data.MISCCardTypeEnum.enumValues;
      state.mtgPaymentTypeEnum = results.data.mtgPaymentTypeEnum.enumValues;
      state.mtgEFTTypeEnum = results.data.mtgEFTTypeEnum.enumValues;
      state.mtgCCardTypeEnum = results.data.mtgCCardTypeEnum.enumValues;
      state.sharedBackPageEnum = results.data.sharedBackPageEnum.enumValues;
      state.TaxUnitDetailSDRTypeEnum = results.data.TaxUnitDetailSDRTypeEnum.enumValues;
      state.GlTransDailySectionEnum = results.data.GlTransDailySectionEnum.enumValues;
      state.GlReportHeadingsTotalsTypeEnum = results.data.GlReportHeadingsTotalsTypeEnum.enumValues;
    },
    setODDepositEnum(state, config) {
      let configuration;
      let item;
      let result;
      if (config[firstIndex] !== undefined) {
        configuration = config[firstIndex].configuration;
        Object.keys(ODDepositEnumElements).forEach((element) => {
          item = configuration[`${element}`];
          if (item) {
            result = addItemInArray(state.ODDepositEnum, ODDepositEnumElements[element]);
          } else {
            result = removeItemFromArray(state.ODDepositEnum, ODDepositEnumElements[element]);
          }
          state.ODDepositEnum = result;
        });
      }
    },
  },
  actions: {
    async cacheEnums({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            ODTransType:__type(name:"ODTransType") {
              enumValues {
                name, description
              }
            },
            ODDepositEnum:__type(name:"ODDepositEnum") {
              enumValues {
                name, description
              }
            },
            ODCardItemTypeEnum:__type(name:"ODCardItemTypeEnum") {
              enumValues {
                name, description
              }
            },
            ODEFTItemTypeEnum:__type(name:"ODEFTItemTypeEnum") {
              enumValues {
                name, description
              }
            },
            ODStatus:__type(name:"ODStatus") {
              enumValues {
                name, description
              }
            },
            ODTrustPaymentTypeEnum:__type(name:"ODTrustPaymentTypeEnum") {
              enumValues {
                name, description
              }
            },
            WarGovFunctionEnum:__type(name:"WarGovFunctionEnum") {
              enumValues {
                name, description
              }
            },
            WarTransTypeEnum:__type(name:"WarTransTypeEnum") {
              enumValues {
                name, description
              }
            },
            WarTransStatusEnum:__type(name:"WarTransStatusEnum") {
              enumValues {
                name, description
              }
            },
            CmnFundTypeEnum:__type(name:"CmnFundTypeEnum") {
              enumValues {
                name, description
              }
            },
            fundReconTypeEnum:__type(name:"fundReconTypeEnum") {
              enumValues {
                name, description
              }
            },
            cmnSourceTypeEnum:__type(name:"cmnSourceTypeEnum") {
              enumValues {
                name, description
              }
            },
            MISCPaymentTypeEnum:__type(name:"MISCPaymentTypeEnum") {
              enumValues {
                name, description
              }
            },
            MISCEFTTypeEnum:__type(name:"MISCEFTTypeEnum") {
              enumValues {
                name, description
              }
            },
            MISCCardTypeEnum:__type(name:"MISCCardTypeEnum") {
              enumValues {
                name, description
              }
            },
            mtgPaymentTypeEnum:__type(name:"mtgPaymentTypeEnum") {
              enumValues {
                name, description
              }
            },
            mtgEFTTypeEnum:__type(name:"mtgEFTTypeEnum") {
              enumValues {
                name, description
              }
            },
            mtgCCardTypeEnum:__type(name:"mtgCCardTypeEnum") {
              enumValues {
                name, description
              }
            },
            sharedBackPageEnum:__type(name:"sharedBackPageEnum") {
              enumValues {
                name, description
              }
            },
            TaxUnitDetailSDRTypeEnum:__type(name:"TaxUnitDetailSDRTypeEnum") {
              enumValues {
                name, description
              }
            },
            GlTransDailySectionEnum:__type(name:"GlTransDailySectionEnum") {
              enumValues {
                name, description
              }
            },
            GlReportHeadingsTotalsTypeEnum:__type(name:"GlReportHeadingsTotalsTypeEnum") {
              enumValues {
                name, description
              }
            },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setEnums', results);
        }
      } catch (e) {
        dispatch('flashError', 'There was a problem caching data (enums).', { root: true });
      }
    },
  },
  getters: {
  },
};

export default enums;
