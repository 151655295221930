<template>
  <v-footer padless>
    <v-col
      class="text-center"
      cols="4"
    >
      {{ new Date(todaysDate).getFullYear() }} — <strong>TM Consulting</strong>
    </v-col>
    <v-col
      class="text-center"
      cols="2"
    >
      <v-btn
        class="ma-2"
        outlined
        color="indigo"
        @click="printScreen"
      >
        PRINT SCREEN
      </v-btn>
    </v-col>
    <v-col
      class="text-center"
      cols="3"
    >
    <FooterLogsDialog />
    </v-col>
    <v-col
      class="text-center"
      cols="3"
    >
      <p class="font-weight-thin">(ver: {{ buildVersion }})</p>
    </v-col>
  </v-footer>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import FooterLogsDialog from './FooterLogsDialog.vue';

export default {
  name: 'Footer',
  components: {
    FooterLogsDialog,
  },
  computed: {
    buildVersion() {
      if (process && process.env && process.env.VUE_APP_VERSION) {
        return process.env.VUE_APP_VERSION;
      }
      return 'unset';
    },
    ...mapGetters([
      'todaysDate',
    ]),
  },
  methods: {
    printScreen() {
      this.$emit('printScreen', true);
    },
  },
};
</script>
