<template>
    <v-dialog
      v-model="dialog"
      persistent max-width="50vw"
      :style="{'z-index': zIndexUpdtUserPass}"
    >
      <v-card ref="inputs"
        @keydown.enter="updatePassword"
      >
        <v-card-title>Update Password</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          <v-text-field
            id="passwordUpdate"
            solo
            v-model="password"
            label="Password"
            :prepend-inner-icon="icons.mdiAccountKey"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            :append-icon="showPass ? icons.mdiEye : icons.mdiEyeOff"
            :error-messages="passErrorMsgs"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          >
          </v-text-field>
          <v-text-field
            id="confirmPasswordUpdate"
            solo
            v-model="confirmPassword"
            label="Confirm Password"
            :prepend-inner-icon="icons.mdiAccountKey"
            :type="showConfPass ? 'text' : 'password'"
            @click:append="showConfPass = !showConfPass"
            :append-icon="showConfPass ? icons.mdiEye : icons.mdiEyeOff"
            :error-messages="confPassErrorMsgs"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          >
          </v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            id="updatePasswordButton"
            color="secondary"
            class="ma-2 white--text"
            @click="updatePassword"
          >
            Update
            <v-icon right> {{icons.mdiLogin}} </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

import {
  mdiAccount,
  mdiAccountKey,
  mdiEye,
  mdiEyeOff,
  mdiLogin,
} from '@mdi/js';
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import { TMC_CONSTANTS } from '../../util/shared/constants';

export default {
  mixins: [validationMixin],
  validations: {
    password: { required },
    confirmPassword: { required, sameAsPassword: sameAs('password') },
  },
  computed: {
    passErrorMsgs() {
      const v = this.$v;
      return (!v.password.$dirty) ? [] : [
        ...(v.password.required) ? [] : ['Required'],
      ];
    },
    confPassErrorMsgs() {
      const v = this.$v;
      let errors = [];
      if (v.confirmPassword.$dirty) {
        if (v.confirmPassword.required) {
          if (!v.confirmPassword.sameAsPassword) {
            errors = ['Password mismatched'];
          }
        } else {
          errors = ['Required'];
        }
      }
      return errors;
    },
    dialog() {
      return this.$store.state.auth.token.resetPasswordDialog;
    },
  },

  name: 'LoginUserPass',
  data: () => ({
    icons: {
      mdiAccount,
      mdiAccountKey,
      mdiEye,
      mdiEyeOff,
      mdiLogin,
    },
    showPass: false,
    showConfPass: false,
    password: '',
    confirmPassword: '',
    zIndexUpdtUserPass: TMC_CONSTANTS.COMPONENT_Z_INDEX.UPDATE_USER_PASSWORD_DIALOG,
  }),
  methods: {
    updatePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const updatedData = {
          changingPassUser: localStorage.getItem('auth.user'),
          newPassword: this.password,
        };
        this.$store.dispatch('UserAdministration/resetUsersPassword', updatedData).then((resp) => {
          if (resp.status) {
            this.$store.commit('auth/token/setResetPasswordDialog', false);
            this.$store.dispatch('auth/token/resolveAuth', resp.tokenContext);
          } else {
            this.$store.dispatch('flashError', 'Unable to update the password.');
          }
        }).catch((e) => {
          console.warn(e);
        });
      }
    },
  },
};
</script>
