import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../util/shared/vue-global';

const { backend } = clients.direct;

const fields = {
  _id: '',
  fundNumber: '',
  description: '',
  defaultMills: '',
  taxUnit: {
    _id: '',
    toUnitDescForAppro: '',
    fiscalYear: '',
    appSection: '',
  },
  grossAssessed: '',
};

export const taxFund = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async loadTaxFund({ commit, dispatch }, {
      criteria, baseCrudKey,
    }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query taxFund($criteria: taxFundCriteriaType) {
            taxFund(criteria: $criteria) {
                    ${fieldsToGqlString(fields)},
                  }
                }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.taxFund) {
          const fund = results.data ? results.data.taxFund || [] : [];
          commit('base/crud/setItems', [baseCrudKey, fund], { root: true });
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Tax Funds list.', { root: true });
      }
    },
    async upsertTaxFund({ dispatch, rootGetters, commit }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertTaxFund ($item: taxFundInputType!)  {
            upsertTaxFund(upsert: $item) {
              ${fieldsToGqlString(fields)},
            }
          }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const taxFundStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newTaxFund = results.data.upsertTaxFund;
        const newList = mergeByIdNewAtTop(taxFundStateData || [], newTaxFund);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Tax Fund', { root: true });
      }
    },
  },
};

export default taxFund;
