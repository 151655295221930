import { TransformMapperActions } from './TransformMapper.actions';

export const config = {
  namespaced: true,
  state: () => ({
    sources: [],
    dests: [],
  }),
  mutations: {
  },
  actions: {
    ...TransformMapperActions,
  },
};

export default {
  config,
};
