import {
  checkStringEmptiness,
} from './shared/vue-global';

export const wkstParam = 'workstationID';

export const fetchWkstFromUrl = (window) => {
  const workstationID = new URL(window.location.href).searchParams.get(`${wkstParam}`);
  return workstationID;
};

export const setWorkstationNumber = (window, workstationNumber) => {
  const wkstID = workstationNumber || fetchWkstFromUrl(window);
  if (!checkStringEmptiness(wkstID)) {
    localStorage.setItem(`${wkstParam}`, wkstID);
  }
};

export const getWorkstationNumber = () => localStorage.getItem(`${wkstParam}`);
