import { bank } from './bank/index';
import { fund } from './fund/index';
import { source } from './source/index';
import { taxFund } from './taxFund/index';
import { taxDistrict } from './taxDistrict/index';
import { taxUnit } from './taxUnit/index';
import { taxLevy } from './taxLevy/index';

export const shared = {
  namespaced: true,
  modules: {
    bank,
    fund,
    source,
    taxFund,
    taxDistrict,
    taxUnit,
    taxLevy,
  },
};

export default shared;
