import gql from 'graphql-tag';
import { clients } from '../../util/clients';
import { bulkResult } from '../../util/shared/graphql-global';
import { bankVoucherDataHeaders } from '../../util/shared/tmc-global';
import { amountFormat, amountToNumber, fieldsToGqlString } from '../../util/shared/vue-global';

const { backend } = clients.direct;

export const ImportActions = {
  async upsertODTransactions({ dispatch, commit }, upserts, chunkId) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });

      results = await backend.mutate({
        mutation: gql`mutation upsertODTransactions($upserts: [ODTransactionBulkInput]) {
          upsertODTransactions(upserts:$upserts) {
            insertedCount, upsertedCount, matchedCount, deletedCount,
            result {ok, nInserted, nMatched, nRemoved, nUpserted, nModified }
          }
        }`,
        variables: {
          upserts,
        },
      });
      clients.handleGQLErrors(results, commit);

      return results;
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', `Something went wrong in the import of chunk id ${chunkId}.`, { root: true });
    }
    return {};
  },
  async importBankVoucher({ dispatch, commit }, upserts, chunkId) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });

      results = await backend.mutate({
        mutation: gql`mutation importBankVoucher($upserts: [BankVoucherBulkInput]) {
          importBankVoucher(upserts:$upserts) {
            ${bulkResult}
          }
        }`,
        variables: {
          upserts,
        },
      });
      clients.handleGQLErrors(results, commit);

      return results;
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', `Something went wrong in the import of chunk id ${chunkId}.`, { root: true });
    }
    return {};
  },
  async cleanupBankVoucher({ dispatch, commit }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });

      results = await backend.mutate({
        mutation: gql`mutation cleanupBankVoucher {
          cleanupBankVoucher {
            ${bulkResult}
          }
        }`,
      });
      return results;
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'Something went wrong.', { root: true });
    }
    return {};
  },
  async loadBankVoucherData({ dispatch, commit }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` query {
          bankVoucherTransactions {
            date voucher amount
            transactionDetails { id register registration fiscalYear
              departmentDetail { dept description }
            }
          }
        }`,
        fetchPolicy: 'no-cache',
      });
      const { bankVoucherTransactions } = results.data;
      const reducedHeader = [...bankVoucherDataHeaders];
      const processedFileData = {
        matchedData: {
          headers: [...bankVoucherDataHeaders],
          data: [],
          totalAmount: 0,
        },
        unmatchedData: {
          headers: reducedHeader.splice(3, 3),
          data: [],
          totalAmount: 0,
        },
      };

      if (bankVoucherTransactions.length > 0) {
        for (let i = 0; i < bankVoucherTransactions.length; i += 1) {
          const trans = bankVoucherTransactions[i];
          if (trans.transactionDetails !== null) {
            processedFileData.matchedData.data.push(
              {
                voucher: trans.voucher,
                amount: amountFormat(trans.amount),
                date: trans.date,
                transactionId: trans.transactionDetails.id,
                register: trans.transactionDetails.register,
                registration: trans.transactionDetails.registration,
                fiscalYear: trans.transactionDetails.fiscalYear,
                deptName: trans.transactionDetails.departmentDetail.description,
                deptNo: trans.transactionDetails.departmentDetail.dept,
              },
            );
            processedFileData.matchedData.totalAmount += amountToNumber(trans.amount);
          } else {
            processedFileData.unmatchedData.data.push(
              {
                voucher: trans.voucher,
                amount: amountFormat(trans.amount),
                date: trans.date,
              },
            );
            processedFileData.unmatchedData.totalAmount += amountToNumber(trans.amount);
          }
        }
        commit('setProcessedDetails', processedFileData);
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading Configs.', { root: true });
    }
  },
  async loadImportedPaidWarrants({ dispatch, commit }, {
    gqlfields, postProccess,
  }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` query {
          paidWarrantsTransactions {
            ${fieldsToGqlString(gqlfields)},
          }
        }`,
        fetchPolicy: 'no-cache',
      });
      const { paidWarrantsTransactions } = results.data;
      const processedFileData = postProccess({
        transData: (paidWarrantsTransactions || []),
      });
      commit('setProcessedDetails', processedFileData);
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading imported paid warrants.', { root: true });
    }
  },
};

export default ImportActions;
