export const isTulsa = (countyName) => (countyName || '').match(new RegExp('tulsa', 'gi'));

export const isCanadian = (countyName) => (countyName || '').match(new RegExp('canadian', 'gi'));

export const isCandianPayrollProfile = (name) => (name || '').match(new RegExp('Payroll', 'gi'));

export const isCountyMatchedInArr = (countyArr, countyName) => countyArr.find((c) => (c || '').match(new RegExp(countyName, 'gi')));

export const filterForCountySpecificProfiles = (profile, countyName) => {
  const { countySpecific } = profile.value || {};
  // if profile is not county specific then 'countySpecific' is empty array/undefined
  if (Array.isArray(countySpecific) && countySpecific.length > 0) {
    if (!isCountyMatchedInArr(countySpecific, countyName)) {
      return false;
    }
  }
  return true;
};
