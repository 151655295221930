<template>
  <div>
    <v-dialog
      :style="{'z-index':zIndexNotiDialog}"
      class="notification-dialogBox"
      v-model="showNotificationDialog"
      scrollable
      width="50vw"
      height="145vh">
      <v-card>
        <v-card-title>
          <span class="headline">{{ notification.title }}</span>
        </v-card-title>
        <v-card-text>
          <div class="notification-body"
            :style="{ color: 'black' }"
            v-for="(text, index) in notification.body.split('\n')"
            :key="index">
            {{ text }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :class="`notification-dialogBox-close-btn`"
            text color="blue darken-1"
            @click="showNotificationDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { TMC_CONSTANTS } from '../../../util/shared/constants';

export default {
  name: 'NotificationDialog',
  props: {
    notification: {
      type: Object,
      default: () => ({}),
    },
    notificationDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    zIndexNotiDialog: TMC_CONSTANTS.COMPONENT_Z_INDEX.NOTIFICATION_DIALOG,
  }),
  computed: {
    showNotificationDialog: {
      get() {
        return this.notificationDialog;
      },
      set(value) {
        this.$emit('showNotificationDialog', value);
      },
    },
  },
};
</script>

<style>
/*TODO Use this style instead of inline style for all the zindex also */
.notification-dialogBox {
  z-index: v-bind('zIndexNotiDialog');
};
.notification-body {
  color:black;
};
</style>
