<template>
  <v-app>
    <v-app-bar
      id="tmc-app-bar"
      app
      dark
      color="primary"
      shrink-on-scroll
      prominent
      src="@/assets/OklahomaLogo.jpg"
      fade-img-on-scroll
      v-if="!reportMode && !isPrintScreen"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(25,118,210,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <span id="spanRouteName" class="mr-2">{{this.$route.name}}</span>
      </div>
      <div class="workstation-number">Wkst-{{wkst}}</div>
      <NotificationBadge/>

    </v-app-bar>
    <NotificationSnackbar/>
    <ReAuth />
    <UpdateUserPassword />
    <InputDialogForWorkstationId />
    <v-main>
      <router-view />
    </v-main>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      bottom
      temporary
    >
      <ProgramList />
    </v-navigation-drawer>
    <Snackbar />
    <Footer
      v-if="!reportMode && !isPrintScreen"
      @printScreen="printScreen"
    />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import ProgramList from './components/common/ProgramList.vue';
import ReAuth from './components/common/ReAuth.vue';
import Snackbar from './components/common/Snackbar.vue';
import Footer from './components/common/Footer.vue';
import UpdateUserPassword from './components/common/UpdateUserPassword.vue';
import InputDialogForWorkstationId from './components/common/InputDialogForWorkstationId.vue';
import NotificationBadge from './components/common/announcement/NotificationBadge.vue';
import NotificationSnackbar from './components/common/announcement/NotificationSnackbar.vue';

export default {
  name: 'App',

  components: {
    ProgramList,
    ReAuth,
    Snackbar,
    Footer,
    UpdateUserPassword,
    InputDialogForWorkstationId,
    NotificationBadge,
    NotificationSnackbar,
  },
  data: () => ({
    drawer: false,
    group: null,
  }),
  created() {
    this.initOrRehydrateState(); // async fire and forget
    window.addEventListener('keydown', this.globalShortcutHandler);
  },
  methods: {
    ...mapActions('enums', [
      'cacheEnums',
    ]),
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
    ...mapMutations('auth/token', [
      'rehydrateLogin',
    ]),
    ...mapMutations([
      'setPrintScreen',
    ]),
    // things in state which should be re-set after a page reload
    // (or on initial load of the page) can go here globally
    // items of note: localStorage
    //   localStorage needs to be duplicated in state, so that it's reactive
    async initOrRehydrateState() {
      this.cacheEnums();
      this.rehydrateLogin();
      this.loadConfig({ requiredScope: 'global' });
      /* For isTestEnvironment flag */
      this.loadConfig({ requiredScope: 'devConfig' });
    },
    globalShortcutHandler(e) {
      // console.log(JSON.stringify(e, null, 1));
      if (e.key === '+') {
        // simulate 'tab' (full keyboard number pad convenience feature for TM Consulting)
        // doesn't work - security in modern browsers does not allow it
        // Nehemiah found a way to use autohotkey instead
        // e.target.dispatchEvent(new KeyboardEvent('keydown', { key: 'Tab' }));
      }
    },
    printScreen(print) {
      this.setPrintScreen(print);
      this.$nextTick(() => {
        window.print();
        this.$nextTick(() => {
          this.setPrintScreen(false);
        });
      });
    },
  },
  computed: {
    ...mapState({
      isPrintScreen: (state) => state.isPrintScreen,
      wkst: (state) => state.SystemConfig.workStationItem.wkst,
    }),
    reportMode() {
      const { path } = this.$route;
      // If required any report mode please add '/reports' at pre route
      return (path.indexOf('reports/') > -1);
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
<style>
.workstation-number {
  font-weight: bold;
  color: #ffa500;
};
</style>
