import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import {
  fieldsToGqlString,
  mergeByIdNewAtTop,
} from '../../../util/shared/vue-global';
import { programToModel } from '../../../components/common/whom/cmnWhom.util';

const { backend } = clients.direct;
const defaultCmnWhomFields = {
  _id: '',
  description: '',
  fiscalYear: '',
};

export const cmnWhom = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async loadCmnWhom({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      const updatedCriteria = { ...criteria };
      const gqlfields = fields || defaultCmnWhomFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query cmnwhoms($criteria: cmnWhomCriteriaType, $program:  String) {
            cmnwhoms(criteria: $criteria, program:  $program) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: {
            criteria: updatedCriteria,
            program: programToModel(baseCrudKey),
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const cmnWhoms = results.data ? results.data.cmnwhoms || [] : [];
        commit('base/crud/setItems', [baseCrudKey, cmnWhoms], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Recieved Of list.', { root: true });
      }
    },
    async delCmnWhom({ commit, rootGetters, dispatch }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation deleteCmnWhom($id: ID!, $program: String)  {
            deleteCmnWhom(id: $id, program: $program) {
                  _id
                }
              }`,
          variables: {
            id: item._id,
            program: programToModel(baseCrudKey),
          },
        });
        clients.handleGQLErrors(results, commit);
        const delId = results.data.deleteCmnWhom._id;
        const receivedStateData = rootGetters['base/crud/items'](baseCrudKey);
        const updatedData = receivedStateData.filter((d) => d._id !== delId);
        commit('base/crud/setItems', [baseCrudKey, updatedData], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem deleting the Recieved Of list', { root: true });
      }
    },
    async upsrtCmnWhom({ dispatch, rootGetters, commit }, { item, fields, baseCrudKey }) {
      let results;
      try {
        const gqlfields = fields || defaultCmnWhomFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsert ($item: cmnWhomInputType!, $program:  String)  {
            upsertCmnWhom(upsert: $item, program:  $program) {
                  ${fieldsToGqlString(gqlfields)},
                }
              }`,
          variables: {
            item,
            program: programToModel(baseCrudKey),
          },
        });
        clients.handleGQLErrors(results, commit);
        const whomsStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newWhom = results.data.upsertCmnWhom;
        const newList = mergeByIdNewAtTop(whomsStateData || [], newWhom);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Recieved Of-List', { root: true });
      }
    },
  },
};

export default cmnWhom;
