import store from '../../store';
import { isAuthorised } from '../../store/auth/token';
import { getRawJwt } from '../jwt';

export const accessCriteria = async (authorizedRoles, next) => {
  const jwt = getRawJwt();
  if (!jwt) {
    await store.dispatch('reAuth', null, { root: true });
  } else {
    const authorized = isAuthorised(authorizedRoles);
    if (!authorized) {
      next({ name: 'Home' });
    } else {
      next();
    }
  }
};

// helper methods for base64 encoding/decoding for WebAuthN
// Based on a function from here: https://stackoverflow.com/a/21797381

export const base64StrToArray = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const arrayToBase64Str = (arrayBuffer) => {
  const charStr = String.fromCharCode.apply(
    null,
    new Uint8Array(arrayBuffer),
  );
  return window.btoa(charStr);
};

export default {
  accessCriteria,
};
