import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../util/shared/vue-global';

const { backend } = clients.direct;

const bankfields = {
  _id: '',
  bankName: '',
  altKeyBase: '',
  groupCode: '',
  accountNumber: '',
  routingNumber: '',
  bankAddress: '',
  bankCode: '',
  isInvAcct: '',
  isReconAcct: '',
  isSubledger: '',
  line: '',
};

const bankWithCollateralfields = {
  _id: '',
  bankName: '',
  altKeyBase: '',
  groupCode: '',
  accountNumber: '',
  routingNumber: '',
  bankAddress: '',
  bankCode: '',
  isInvAcct: '',
  isReconAcct: '',
  isSubledger: '',
  line: '',
  bankCollateralRecord: {
    _id: '',
    bankName: '',
    fiscalYear: '',
    asOfDate: '',
    bankIntials: '',
    cashBalance: '',
    pledgesLeft: '',
    details: {
      asOfDate: '',
      description: '',
      amount: '',
      fdic: '',
    },
  },
};

export const bank = {
  namespaced: true,
  state: () => ({
    selectedItems: [],
  }),
  mutations: {
    setSelectedItems(state, items) {
      state.selectedItems = items;
    },
  },
  actions: {
    async loadBanks({ commit, dispatch }, {
      criteria, baseCrudKey,
    }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query banks($criteria: BankCriteriaType) {
                  banks(criteria: $criteria) {
                    ${fieldsToGqlString(bankfields)},
                  }
                }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.banks) {
          const banks = results.data ? results.data.banks || [] : [];
          commit('base/crud/setItems', [baseCrudKey, banks], { root: true });
          return banks;
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the banks list.', { root: true });
      }
      return [];
    },
    async loadBankWithCollateralList({ commit, dispatch }, {
      criteria, baseCrudKey,
    }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query bankWithCollateralList($criteria: BankCriteriaType) {
                  bankWithCollateralList(criteria: $criteria) {
                    ${fieldsToGqlString(bankWithCollateralfields)},
                  }
                }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.bankWithCollateralList) {
          const bankWithCollateralList = results.data
            ? results.data.bankWithCollateralList || [] : [];
          commit('base/crud/setItems', [baseCrudKey, bankWithCollateralList], { root: true });
          return bankWithCollateralList;
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the bank list.', { root: true });
      }
      return [];
    },
    async upsertBank({ dispatch, rootGetters, commit }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertBank ($item: BankInputType!)  {
            upsertBank(upsert: $item) {
              ${fieldsToGqlString(bankfields)},
            }
          }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const banksStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newBank = results.data.upsertBank;
        const newList = mergeByIdNewAtTop(banksStateData || [], newBank);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Bank', { root: true });
      }
    },
  },
};

export default bank;
