/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { BlobServiceClient } from '@azure/storage-blob';

export const userGuide = {
  namespaced: true,
  state: () => ({
    userGuides: [],
  }),
  mutations: {
    setUserGuides(state, userGuideArray) {
      state.userGuides = userGuideArray;
    },
  },
  actions: {
    async listBlobs({ commit }) {
      const userGuideArray = [];
      const blobArray = [];

      const accountName = 'tmcpublicartifacts';
      const containerName = 'userguides';
      const sasToken = 'sp=rl&st=2023-02-23T14:05:47Z&se=2024-02-23T22:05:47Z&sv=2021-06-08&sr=c&sig=9woiNTCZvw%2FZWyEGu%2BZ3%2FuyXGHcHukIvQxlZx4IYKuw%3D';

      const blobServiceClient = new BlobServiceClient(
        `https://${accountName}.blob.core.windows.net?${sasToken}`,
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);

      // issue -> during this function's iteration, each blob is being processed all the way through before the blobArray is being populated fully... this is leading to blobs being processed more than once
      for await (const blob of containerClient.listBlobsFlat({ includeMetadata: true })) {
        blobArray.push(blob);
      }

      const generateUrlFromBlobName = (blob) => `https://${accountName}.blob.core.windows.net/${containerName}/${encodeURIComponent(blob.name)}`;

      const fetchUrlFromJsonFile = async (blob) => {
        async function blobToString(blob) {
          const fileReader = new FileReader();
          return new Promise((resolve, reject) => {
            fileReader.onloadend = (ev) => {
              resolve(ev.target.result);
            };
            fileReader.onerror = reject;
            fileReader.readAsText(blob);
          });
        }
        const blobClient = containerClient.getBlobClient(blob.name);
        const downloadBlockBlobResponse = await blobClient.download();
        const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
        const downloadedObject = JSON.parse(downloaded);
        return downloadedObject.video_link;
      };

      const blobArrayCategory = {
        singleOccurrence: [],
        multiOccurrence: {},
      };
      const blobNewArray = blobArray.map((blob) => {
        const noExt = blob.name.split('.').slice(0, -1).join('.');
        return {
          ...blob,
          nameNoExt: noExt,
        };
      });

      // eslint-disable-next-line no-loop-func
      blobNewArray.forEach((blob) => {
        if (blobNewArray.filter((b) => b.nameNoExt === blob.nameNoExt).length === 1) {
          blobArrayCategory.singleOccurrence.push(blob);
        } else if (blobNewArray.filter((b) => b.nameNoExt === blob.nameNoExt).length > 1) {
          if (blobArrayCategory.multiOccurrence[blob.nameNoExt]) {
            blobArrayCategory.multiOccurrence[blob.nameNoExt].push(blob);
          } else {
            blobArrayCategory.multiOccurrence[blob.nameNoExt] = [blob];
          }
        }
      });

      blobArrayCategory.singleOccurrence.forEach(async (blob) => {
        if (blob.properties.contentType !== 'application/json') {
          const category = blob.metadata.category || '';
          const blobUrl = generateUrlFromBlobName(blob);
          const topicName = blob.nameNoExt;
          const userGuideObject = {
            topic: topicName,
            category,
            pdf: {
              url: blobUrl,
              pdfName: `${topicName}.pdf`,
            },
            video: '',
          };
          userGuideArray.push(userGuideObject);
        } else if (blob.properties.contentType === 'application/json') {
          const category = blob.metadata.category || '';
          const vidLink = await fetchUrlFromJsonFile(blob);
          const topicName = blob.nameNoExt;
          const userGuideObject = {
            topic: topicName,
            category,
            pdf: '',
            video: vidLink,
          };
          userGuideArray.push(userGuideObject);
        }
      });
      // eslint-disable-next-line guard-for-in
      for (const property in blobArrayCategory.multiOccurrence) {
        const category = blobArrayCategory.multiOccurrence[property][0].metadata.category || '';
        const userGuideObject = {
          topic: property,
          category,
          pdf: '',
          video: '',
        };
        blobArrayCategory.multiOccurrence[property].forEach(async (blob) => {
          if (blob.properties.contentType !== 'application/json') {
            const blobUrl = generateUrlFromBlobName(blob);
            userGuideObject.pdf = {
              url: blobUrl,
              pdfName: `${property}.pdf`,
            };
          } else if (blob.properties.contentType === 'application/json') {
            const vidLink = await fetchUrlFromJsonFile(blob);
            userGuideObject.video = vidLink;
          }
        });
        userGuideArray.push(userGuideObject);
      }
      commit('setUserGuides', userGuideArray);
    },
  },
};

export default userGuide;
