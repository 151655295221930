import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import {
  fieldsToGqlString,
  mergeByIdNewAtTop,
} from '../../../util/shared/vue-global';

const { backend } = clients.direct;
const defaultReportHeadingsTotalsFields = {
  _id: '',
  fiscalYear: '',
  fromLine: '',
  toLine: '',
  description: '',
  type: '',
};

export const glReportHeadersTotalsTransaction = {
  namespaced: true,
  state: () => ({
    regYears: {},
  }),
  mutations: {
    setFiscalRegYears(state, regYears) {
      state.regYears = regYears;
    },
  },
  actions: {
    async loadGLReportHeadingsTotalsTrans({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      const gqlfields = fields || defaultReportHeadingsTotalsFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query glReportHeadingsTotals($criteria: glReportHeadingsTotalsCriteriaType) {
            glReportHeadingsTotals(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        const glheadingsTotalsTrans = results.data ? results.data.glReportHeadingsTotals
            || [] : [];
        commit('base/crud/setItems', [baseCrudKey, glheadingsTotalsTrans], { root: true });
        return glheadingsTotalsTrans;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Transaction List!', { root: true });
      }
      return [];
    },
    async upsertGLReportHeadingsTotalsTrans({ dispatch, rootGetters, commit }, {
      item, fields, baseCrudKey,
    }) {
      let results;
      try {
        const gqlfields = fields || defaultReportHeadingsTotalsFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertGlReportHeadingsTotals($item: glReportHeadingsTotalsInputType!)  {
                upsertGlReportHeadingsTotals(upsert: $item) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const glDailyTransStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newTransaction = results.data ? (results.data.upsertGlReportHeadingsTotals
            || {}) : {};
        const newList = mergeByIdNewAtTop(glDailyTransStateData || [], newTransaction);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
        return newTransaction;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the transaction!', { root: true });
      }
      return {};
    },
    async deleteGLReportHeadingsTotals({ commit, rootGetters, dispatch }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation deleteGlReportHeadersTotals($id: ID!)  {
            deleteGlReportHeadingsTotals(id: $id) {
                  _id
                }
              }`,
          variables: {
            id: item._id,
          },
        });
        clients.handleGQLErrors(results, commit);
        const delId = results.data ? (results.data.deleteGlReportHeadersTotals || {})._id : null;
        const glReportHeadingsTotalsTransData = rootGetters['base/crud/items'](baseCrudKey);
        const updatedData = glReportHeadingsTotalsTransData.filter((d) => d._id !== delId);
        commit('base/crud/setItems', [baseCrudKey, updatedData], { root: true });
        return delId;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem while deleting the transaction!', { root: true });
      }
      return null;
    },
    async loadFiscalRegYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            glReportHeadingsTotalsStatsFYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const range = results.data ? (results.data.glReportHeadingsTotalsStatsFYRange || {}) : null;
        commit('setFiscalRegYears', results.data.glReportHeadingsTotalsStatsFYRange);
        return range;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
      return null;
    },
  },
};

export default glReportHeadersTotalsTransaction;
