<template>
    <div>
      <v-menu
        offset-y
        :close-on-click="false"
        :close-on-content-click="false"
         v-model="menuOpen">
        <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mx-2"
              color="white"
              @click="menuOpen = true"
              v-bind="attrs"
              v-on="on"
              large>
              {{icons.mdiBellRing}}
            </v-icon>
        </template>
        <v-card
          class="mx-auto"
          width="35vw"
          tile
        >
        <v-list
          style="max-height: 55vh"
          class="overflow-y-auto">
          <v-list-item-group>
            <v-list-item
              v-for="item in notificationList"
              :key="item._id"
              two-line>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ formatNotification(item.body) }}</v-list-item-subtitle>
              </v-list-item-content>
                <v-list-item-action>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="notification-badge-read-more"
                    v-if="(item.body || '').length > 75"
                    color="blue"
                    text
                    @click="openNotification(item)">
                    Read More
                  </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        </v-card>
      </v-menu>
      <NotificationDialog
      :notification="announcement"
      :notificationDialog="openDialogBox"
      @showNotificationDialog="closeNotification"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mdiBellRing, mdiEmailCheck } from '@mdi/js';
import { formatNotification, markNotificationRead } from './notificationUtil';
import NotificationDialog from './NotificationDialog.vue';

export default {
  name: 'NotificationBadge',
  components: {
    NotificationDialog,
  },
  data: () => ({
    icons: {
      mdiBellRing,
      mdiEmailCheck,
    },
    menuOpen: false,
    openDialogBox: false,
    announcement: {
      _id: ' ',
      title: ' ',
      body: ' ',
    },
  }),
  computed: {
    ...mapState({
      notificationList: (state) => state.notifications.announcements || [],
    }),
  },
  methods: {
    formatNotification,
    markRead(message) {
      markNotificationRead(message);
    },
    openNotification(message) {
      this.openDialogBox = true;
      this.announcement = message;
    },
    closeNotification(val) {
      this.openDialogBox = val;
    },
  },
};
</script>
