import gql from 'graphql-tag';
import { clients } from '../../util/clients';
import { mergeByIdNewAtTop } from '../../util/shared/vue-global';

const { backend } = clients.direct;

export const DepartmentActions = {
  async loadDepartments({ commit, dispatch }, criteria = {}) {
    let results;
    let deptResult = [];
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` query odmasts($wildcard: String, $skip: Int, $limit: Int, $fiscalYear: Int) {
          odmasts(skip: $skip, limit: $limit, wildcard: $wildcard, fiscalYear: $fiscalYear) {
            id,
            dept,
            description,
            officer,
            title,
            bankConfig,
            postivePayBankConfig,
            trackInterest,
            dailyAverageOverride,
            lastVoucher,
            deptBalance,
          }
        }`,
        variables: criteria,
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.odmasts) {
        deptResult = results.data.odmasts;
        commit('setDepartments', results.data.odmasts);
      }
    } catch (e) {
      dispatch('flashError', 'There was a problem loading departments.', { root: true });
    }
    return deptResult;
  },
  async upsDepartment({ commit, state, dispatch }, department) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation upsertDept ($id: ID!, $dept: Int!, $description: String!, $officer: String!, $title: String!, $bankConfig: ID, $postivePayBankConfig: String, $trackInterest: Boolean!, $dailyAverageOverride: Float! )  {
          upsertODMast(id: $id, dept: $dept, description: $description, officer: $officer, title: $title, bankConfig: $bankConfig, postivePayBankConfig: $postivePayBankConfig, trackInterest: $trackInterest, dailyAverageOverride: $dailyAverageOverride ) {
            id,
            dept,
            description,
            officer,
            title,
            bankConfig,
            postivePayBankConfig,
            trackInterest,
            dailyAverageOverride,
            lastVoucher,
            deptBalance,
          }
        }`,
        variables: department,
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      let wasInList = false;
      const newTran = results.data.upsertODMast;
      let newList = state.departments.map((t) => {
        if (t.id === newTran.id) {
          wasInList = true;
          return newTran;
        }
        return t;
      });
      if (!wasInList) {
        newList = [newTran, ...newList];
      }
      commit('setDepartments', newList);
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem saving the department.', { root: true });
    }
  },
  async deleteDepartment({ commit, state, dispatch }, department) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation delDept ($id: ID!)  {
          deleteODMast(id: $id) {
            id
          }
        }`,
        variables: {
          id: department.id,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      const delId = results.data.deleteODMast.id;
      const newDepts = state.departments.filter((d) => d.id !== delId);
      commit('setDepartments', newDepts);
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem deleting the department.', { root: true });
    }
  },
};

export const WhatActions = {
  async loadWhats({ commit, dispatch }, criteria = {}) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` query odwhats($wildcard: String, $skip: Int, $limit: Int, $fiscalYear: Int) {
          odwhats(skip: $skip, limit: $limit, wildcard: $wildcard, fiscalYear: $fiscalYear) {
            _id,
            description,
            fiscalYear,
          }
        }`,
        variables: criteria,
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.odwhats) {
        commit('setWhats', results.data.odwhats);
      }
    } catch (e) {
      dispatch('flashError', 'There was a problem loading the "For What" list.', { root: true });
    }
  },
  async upsWhat({ commit, state, dispatch }, update) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation upsertWhat ($upsert: ODWhatInput!)  {
          upsertODWhat(upsert: $upsert) {
            _id,
            description,
            fiscalYear,
          }
        }`,
        variables: {
          upsert: update,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      const newList = mergeByIdNewAtTop(state.whats || [], results.data.upsertODWhat);
      commit('setWhats', newList);
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem saving the item.', { root: true });
    }
  },
  async deleteWhat({ commit, state, dispatch }, what) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation delWhat ($id: ID!)  {
          deleteODWhat(id: $id) {
            _id,
          }
        }`,
        variables: {
          id: what._id,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      const delId = results.data.deleteODWhat._id;
      const newWhats = state.whats.filter((d) => d._id !== delId);
      commit('setWhats', newWhats);
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem deleting the item.', { root: true });
    }
  },
};

export const WhomActions = {
  async loadWhoms({ commit, dispatch }, criteria = {}) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` query odwhoms($wildcard: String, $skip: Int, $limit: Int, $fiscalYear: Int) {
          odwhoms(skip: $skip, limit: $limit, wildcard: $wildcard, fiscalYear: $fiscalYear) {
            _id,
            description,
            fiscalYear,
          }
        }`,
        variables: criteria,
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.odwhoms) {
        commit('setWhoms', results.data.odwhoms);
      }
    } catch (e) {
      dispatch('flashError', 'There was a problem loading the "To Whom" list.', { root: true });
    }
  },
  async upsWhom({ commit, state, dispatch }, update) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation upsertWhom ($upsert: ODWhomInput!)  {
          upsertODWhom(upsert: $upsert) {
            _id,
            description,
            fiscalYear,
          },
        }`,
        variables: {
          upsert: update,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      const newList = mergeByIdNewAtTop(state.whoms || [], results.data.upsertODWhom);
      commit('setWhoms', newList);
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem saving the item.', { root: true });
    }
  },
  async deleteWhom({ commit, state, dispatch }, whom) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation delWhom ($id: ID!)  {
          deleteODWhom(id: $id) {
            _id
          }
        }`,
        variables: {
          id: whom._id,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      const delId = results.data.deleteODWhom._id;
      const newWhoms = state.whoms.filter((d) => d._id !== delId);
      commit('setWhoms', newWhoms);
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem deleting the item.', { root: true });
    }
  },
};
