import { crud } from './crud/index';

export const base = {
  namespaced: true,
  modules: {
    crud,
  },
};

export default base;
