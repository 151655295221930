import { cmnWhom } from './whom/index';
import { cmnTransNumber } from './transactionNumber/index';
import { renumberLines } from './renumberLines/index';

export const common = {
  namespaced: true,
  modules: {
    cmnWhom,
    cmnTransNumber,
    renumberLines,
  },
};

export default common;
