import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import {
  fieldsToGqlString,
  mergeByIdNewAtTop,
} from '../../../util/shared/vue-global';
import { bulkResult } from '../../../util/shared/graphql-global';

const { backend } = clients.direct;
const defaultGlDailyTransactionFields = {
  _id: '',
  fiscalYear: '',
  businessDate: '',
  dailySection: '',
  order: '',
  fromNumber: '',
  toNumber: '',
  deposit: '',
  transferIn: '',
  transferOut: '',
  payments: '',
  description: '',
  bank: {
    _id: '',
    accountNumber: '',
    altKeyBase: '',
    bankCode: '',
    bankName: '',
    groupCode: '',
    routingNumber: '',
    bankAddress: '',
  },
  fund: {
    _id: '',
    fund: '',
    fiscalYear: '',
    description: '',
  },
  isLocked: '',
};

export const glDailyTransaction = {
  namespaced: true,
  state: () => ({
    selectedItems: [],
    regYears: {},
    totalBalance: [],
  }),
  mutations: {
    setSelectedItems(state, items) {
      state.selectedItems = items;
    },
    setFiscalRegYears(state, regYears) {
      state.regYears = regYears;
    },
    setGLBalance(state, totalBalance) {
      state.totalBalance = totalBalance;
      // console.info(state.totalBalance, 'state.tot');
    },
  },
  actions: {
    async loadGlDailyTransaction({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      const gqlfields = fields || defaultGlDailyTransactionFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query glDailyTransaction($criteria: glDailyTransactionCriteriaType) {
            glDailyTransaction(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        const glDailyTrans = results.data ? results.data.glDailyTransaction
            || [] : [];
        commit('base/crud/setItems', [baseCrudKey, glDailyTrans], { root: true });
        return glDailyTrans;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Transaction List!', { root: true });
      }
      return [];
    },
    async upsertGlDailyTrans({ dispatch, rootGetters, commit }, {
      item, fields, baseCrudKey,
    }) {
      let results;
      try {
        const gqlfields = fields || defaultGlDailyTransactionFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertGlDailyTransaction ($item: glDailyTransactionInputType!)  {
                upsertGlDailyTransaction(upsert: $item) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const glDailyTransStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newTransaction = results.data ? (results.data.upsertGlDailyTransaction
            || {}) : {};
        const newList = mergeByIdNewAtTop(glDailyTransStateData || [], newTransaction);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
        return newTransaction;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the transaction!', { root: true });
      }
      return {};
    },
    async deleteGlDailyTransaction({ commit, rootGetters, dispatch }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation deleteGlDailyTransaction ($id: ID!)  {
            deleteGlDailyTransaction(id: $id) {
                  _id
                }
              }`,
          variables: {
            id: item._id,
          },
        });
        clients.handleGQLErrors(results, commit);
        const delId = results.data ? (results.data.deleteGlDailyTransaction || {})._id : null;
        const glDailyTransData = rootGetters['base/crud/items'](baseCrudKey);
        const updatedData = glDailyTransData.filter((d) => d._id !== delId);
        commit('base/crud/setItems', [baseCrudKey, updatedData], { root: true });
        return delId;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem while deleting the transaction!', { root: true });
      }
      return null;
    },
    async loadFiscalRegYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            glDailyStatsFYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const range = results.data ? (results.data.glDailyStatsFYRange || {}) : null;
        commit('setFiscalRegYears', results.data.glDailyStatsFYRange);
        return range;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
      return null;
    },
    async loadGLBalance({ commit, dispatch }, { criteria }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query
            glBalance ($criteria: glBalanceStatsCriteriaType) {
              glBalance(criteria: $criteria) { totalDeposit, totalTransferIn,
                totalTransferOut, totalPayments, _dailySection, _fund, _bank },
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        // console.info(results.data.glBalance, 'res.data');
        const balance = results.data ? (results.data.glBalance || {}) : null;
        commit('setGLBalance', results.data.glBalance);
        return balance;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting Balance.', { root: true });
      }
      return null;
    },
    async glCollectInformation({ commit, dispatch }, { criteria }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query
          glCollectInformation ($criteria: glDailyTransactionCriteriaType) {
            glCollectInformation(criteria: $criteria) {
              status
            },
          }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const result = results.data ? (results.data.glCollectInformation || {}) : null;
        return result;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There is a problem while collecting GL information.', { root: true });
      }
      return null;
    },
    async glLockUnlockTransaction({ commit, dispatch }, { criteria }) {
      let results;
      const updatedCriteria = { ...criteria };
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query glLockUnlock($criteria: glLockUnlockCriteriaType) {
            glLockUnlock(criteria: $criteria) {
                ${bulkResult},
              }
            }`,
          variables: {
            criteria: updatedCriteria,
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const glLockUnlockResult = results.data ? results.data.glLockUnlock
        || {} : {};
        return glLockUnlockResult;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem while locking/unlocking the transactions!', { root: true });
        return {};
      }
    },
  },
};

export default glDailyTransaction;
