import { mtgTransaction } from './transaction/index';

export const mtg = {
  namespaced: true,
  modules: {
    mtgTransaction,
  },
};

export default mtg;
