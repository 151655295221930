<template>
  <v-list
    nav
    dense
    class="program-list-group"
    id="ProgramListGroup"
  >
    <v-list-item-group>
      <v-list-item :to="{ name: 'Home' }" link id="programListHome">
        <v-icon>{{icons.mdiHome}}</v-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isODAccessible"
        :to="{ name: 'Official Depository - Deposits & Vouchers' }" link id="programListOD">
        <v-icon>{{icons.mdiBankTransfer}}</v-icon>
        <v-list-item-title>Official Depository</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isWarAccessible"
        :to="{ path: '/war/transactions' }" link id="programListWar">
        <v-icon>{{icons.mdiEqualBox}}</v-icon>
        <v-list-item-title>Warrants & Appropriations</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isWarAccessible && isPayrollRegisterProgram"
        :to="{ path: '/payroll/transactions' }" link id="programListWar">
        <v-icon>{{icons.mdiBook }}</v-icon>
        <v-list-item-title>{{ payrollProgramName }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isMiscAccessible"
        :to="{ name: 'Miscellaneous Receipts - Transactions' }" link id="programListMisc">
        <v-icon>{{icons.mdiReceipt}}</v-icon>
        <v-list-item-title>(BETA) Miscellaneous Receipts</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isMtgAccessible"
        :to="{ name: 'Mortgage Tax - Transactions' }" link id="programListMtg">
        <v-icon>{{icons.mdiBankTransferOut}}</v-icon>
        <v-list-item-title>(BETA) Mortgage Tax</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isGlAccessible"
        :to="{ name: 'General Ledger - Transactions' }" link id="programListGl">
        <v-icon>{{icons.mdiBookOpen }}</v-icon>
        <v-list-item-title>(BETA) General Ledger</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isSharedProgAccessible"
        :to="{ name: 'Shared Program - Banks' }" link id="programListShared">
        <v-icon>{{icons.mdiTableArrowRight}}</v-icon>
        <v-list-item-title>Shared Lists</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isIEBAccessible || isBankProfileAccessible"
        :to="{ name: 'Import / Export' }" link id="programListImportExport">
        <v-icon>{{icons.mdiFileImport}}</v-icon>
        <v-list-item-title>Import / Export / Backup</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="authUser !== 'anonymous'"
        :to="{ name: 'Profile' }" link id="programListProfile">
        <v-icon>{{icons.mdiAccount}}</v-icon>
        <v-list-item-title>Profile</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isSysConfigAccessible"
        :to="{ name: 'System Configuration' }" link id="programListSettings">
        <v-icon>{{icons.mdiApplicationCog}}</v-icon>
        <v-list-item-title>Settings</v-list-item-title>
      </v-list-item>
      <v-list-item  :to="{ name: 'User Guide' }" link id="programListUserGuide">
        <v-icon>{{icons.mdiFileDocumentMultiple}}</v-icon>
        <v-list-item-title>User Guides</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isUserAdminAccessible"
        :to="{ name: 'User Administration' }" link id="programListUserAdministration">
        <v-icon>{{icons.mdiAccountCog}}</v-icon>
        <v-list-item-title>User Administration</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isAuditUserAccessible"
        :to="{ name: 'Audit Log' }" link id="programListAuditLog">
        <v-icon>{{icons.mdiNote}}</v-icon>
        <v-list-item-title>Audit Log</v-list-item-title>
      </v-list-item>
      <v-list-item id="tmc-list-logout" @click="logout"
        v-if="authUser !== 'anonymous'">
        <v-icon>{{icons.mdiShieldLockOutline}}</v-icon>
        <v-list-item-title>Logout (as {{authUser}})</v-list-item-title>
      </v-list-item>
      <v-list-item id="tmc-list-login" @click="login"
        v-if="authUser === 'anonymous'">
        <v-icon>{{icons.mdiShieldLockOutline}}</v-icon>
        <v-list-item-title>Login</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import {
  mdiHome,
  mdiBankTransfer,
  mdiFileImport,
  mdiAccount,
  mdiCash,
  mdiApplicationCog,
  mdiAccountCog,
  mdiShieldLockOutline,
  mdiTableArrowRight,
  mdiEqualBox,
  mdiNote,
  mdiReceipt,
  mdiBook,
  mdiBankTransferOut,
  mdiFileDocumentMultiple,
  mdiBookOpen,
} from '@mdi/js';
import {
  mapGetters,
  mapActions,
  mapState,
} from 'vuex';
import { isCanadian } from '../../util/shared/county-specific.config';

export default {
  name: 'ProgramList',
  data: () => ({
    icons: {
      mdiHome,
      mdiBankTransfer,
      mdiFileImport,
      mdiAccount,
      mdiCash,
      mdiApplicationCog,
      mdiAccountCog,
      mdiShieldLockOutline,
      mdiTableArrowRight,
      mdiEqualBox,
      mdiNote,
      mdiReceipt,
      mdiBook,
      mdiBankTransferOut,
      mdiFileDocumentMultiple,
      mdiBookOpen,
    },
  }),
  created() {
    this.loadConfig({ requiredScope: 'warconfig' });
  },
  computed: {
    ...mapGetters('auth/token', [
      'authUser',
      'isODAccessible',
      'isIEBAccessible',
      'isBankProfileAccessible',
      'isMtgAccessible',
      'isSysConfigAccessible',
      'isUserAdminAccessible',
      'isAuditUserAccessible',
      'isWarAccessible',
      'isMiscAccessible',
      'isSharedProgAccessible',
      'isGlAccessible',
    ]),
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
      globalSettingItem: (state) => state.SystemConfig.globalSettingItem,
    }),
    isPayrollRegisterProgram() {
      return this.warSettingItem && this.warSettingItem.payrollRegisterProgram;
    },
    payrollProgramName() {
      const { countyName } = this.globalSettingItem;
      if (isCanadian(countyName)) {
        return 'Payroll/School Register';
      }
      return 'Payroll Register';
    },
  },
  methods: {
    ...mapActions('SystemConfig', ['loadConfig']),
    ...mapActions([
      'reAuth',
    ]),
    logout() {
      this.$store.commit('auth/token/setLoggedOut');
    },
    login() {
      this.$store.commit('auth/token/setLoggedOut');
      this.reAuth();
    },
  },
};
</script>
