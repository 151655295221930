import { amountToNumber, extractId } from '../../../util/shared/vue-global';

export const warDetailsElements = ['fund', 'department', 'account'];

export const dateForCriteria = ['fromRegisterDate', 'toRegisterDate', 'fromPaidCancelledDate', 'toPaidCancelledDate'];

export const getDefaultSumOfTrans = () => ({ isDisplay: false, totalAmt: 0.0, totalCount: 0 });

export const createWarTransactionGroup = (transArr = []) => {
  const groupedData = {};
  transArr.forEach((element) => {
    let keyObj = {
      fundId: element.details[0].fund._id,
      fund: element.details[0].fund.fund,
      fiscalYear: element.fiscalYear,
      warrant: element.warrant,
    };
    keyObj = JSON.stringify(keyObj);
    const stringKey = groupedData[keyObj];
    if (stringKey) {
      const updatedArr = stringKey;
      updatedArr.push(element);
      groupedData[keyObj] = updatedArr;
    } else {
      const newArr = [];
      newArr.push(element);
      groupedData[keyObj] = newArr;
    }
  });
  return groupedData;
};

export const upsertTransactionItem = async (item) => {
  const updatedItem = { ...item };
  delete updatedItem.hasFiles;
  if (updatedItem.paidCancelledDate === '' || updatedItem.status === 'OUTSTANDING') {
    updatedItem.paidCancelledDate = null;
  }
  if (updatedItem.details !== undefined && updatedItem.details.length > 0) {
    updatedItem.details = updatedItem.details.map((element) => {
      const amount = amountToNumber(element.amount);
      const detailItem = { ...element };
      if (detailItem.fund) {
        detailItem.fund = extractId(detailItem.fund);
      }
      if (detailItem.account) {
        detailItem.account = extractId(detailItem.account);
      }
      if (detailItem.department) {
        detailItem.department = extractId(detailItem.department);
      }
      return { ...detailItem, amount };
    });
  }
  return updatedItem;
};

export const programDetails = (program) => {
  const warTransactionDetails = {
    model: 'warTransaction',
    baseCrudKey: 'warTransaction',
    programName: 'Warrants & Appropriations',
    attachement: {
      bucketName: 'war',
      program: 'war',
      parentObjectType: 'warTransaction',
    },
  };
  const payrollTransactionDetails = {
    model: 'payrollTransaction',
    baseCrudKey: 'payrollTransaction',
    programName: 'Payroll Register',
    attachement: {
      bucketName: 'payroll',
      program: 'payroll',
      parentObjectType: 'payrollTransaction',
    },
  };

  /*
    This util capable to return same details for multiple keys as per requirenemnt
  */
  const programMapper = {
    // baseCrudKey
    warTransaction: warTransactionDetails,
    payrollTransaction: payrollTransactionDetails,
    // router
    war: warTransactionDetails,
    payroll: payrollTransactionDetails,
    // for profile mappers
    WAR: warTransactionDetails,
    PAYROLL: payrollTransactionDetails,
  };

  return programMapper[program];
};

export const programToModel = (program) => programDetails(program).model;

export const getProgramDetailsByParams = (self) => {
  const { program } = self.$route.params;
  if (program) {
    return [programDetails(program), program];
  }
  return [{}, ''];
};
/* FYI fundBalance & accountBalance values are consuming by backend, please update them carefully */
export const warStatsBalanceParams = () => ({
  fundBalance: 'fundBalance',
  accountBalance: 'accountBalance',
});

export const extractClearingFundNumber = (clearingFundNumWithBank) => {
  const clearingFundNumOnly = clearingFundNumWithBank.map((item) => {
    const match = item.match(/^\s*(\d+)(?:-|\s|$)/);
    return match ? match[1] : null;
  }).filter(Boolean); // Boolean is to remove null values in array.
  return clearingFundNumOnly;
};
