import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';

export const WarRoutes = [
  {
    path: '/:program(\\war|payroll)',
    name: 'Warrant/Payroll Register',
    component: () => import('../views/Warrants.vue'),
    children: [
      {
        path: 'reports',
        name: 'Warrant/Payroll Register - Reports',
        component: () => import('../views/War/Reports.vue'),
      },
      {
        path: 'transactions',
        name: 'Warrant/Payroll Register - Transactions',
        component: () => import('../views/War/Transactions.vue'),
      },
      {
        path: 'payroll',
        name: 'Payroll Register - Transactions',
        component: () => import('../views/War/Transactions.vue'),
      },
      {
        path: 'browse',
        name: 'Warrant/Payroll Register - Browse',
        component: () => import('../views/War/browse/browse.vue'),
        children: [
          {
            path: 'departments',
            name: 'Warrant/Payroll Register - Departments',
            component: () => import('../views/War/browse/Departments.vue'),
          },
          {
            path: 'accounts',
            name: 'Warrant/Payroll Register - Accounts',
            component: () => import('../views/War/browse/Accounts.vue'),
          },
          {
            path: 'whoms',
            name: 'Warrant/Payroll Register - To Whom',
            component: () => import('../views/War/browse/Whoms.vue'),
          },
        ],
      },
      {
        path: 'utils',
        name: 'Warrant/Payroll Register - Utils',
        component: () => import('../views/War/utils/Utils.vue'),
        children: [
          {
            path: 'fundreconciliation',
            name: 'Warrant/Payroll Register - Fund Reconciliation',
            component: () => import('../views/War/utils/FundRecon.vue'),
            children: [
              {
                path: 'reconTransaction',
                name: 'Warrant/Payroll Register - Reconciliation Transaction',
                component: () => import('../../src/components/War/Utils/FundReconciliation/FundReconciliation.vue'),
              },
              {
                path: 'glFund',
                name: 'Warrant/Payroll Register - GL Fund',
                component: () => import('../../src/components/War/Utils/FundReconciliation/GLFundList.vue'),
              },
            ],
          },
          {
            path: 'createfiscalyear',
            name: 'Warrant/Payroll Register - Create Fiscal Year',
            component: () => import('../views/War/utils/CreateFiscalYear.vue'),
          },
        ],
      },
    ],
  },
];

WarRoutes.forEach((element, index) => {
  WarRoutes[index].beforeEnter = async (to, from, next) => {
    await accessCriteria(authRoles.WarRead, next);
  };
});

export default WarRoutes;
