export const programDetails = {
  miscPaymentDetailForTransactionNumber:
  {
    program: 'miscPaymentDetailForTransactionNumber',
  },
  mtgPaymentDetailForTransactionNumber: {
    program: 'mtgPaymentDetailForTransactionNumber',
  },
};

export const getProgramDetailsByKey = (key) => {
  // programDetails[program]
  const programMapper = {
    // program baseCrudKey
    miscTransaction: programDetails.miscPaymentDetailForTransactionNumber,
    mtgTransaction: programDetails.mtgPaymentDetailForTransactionNumber,
    // transaction number Key
    miscPaymentDetailForTransactionNumber: programDetails.miscPaymentDetailForTransactionNumber,
    mtgPaymentDetailForTransactionNumber: programDetails.mtgPaymentDetailForTransactionNumber,
  };
  return programMapper[key];
};

export const getProgramByKey = (key) => getProgramDetailsByKey(key).program;
