import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../util/shared/vue-global';

const { backend } = clients.direct;

const fields = {
  _id: '',
  fiscalYear: '',
  orderNumber: '',
  appSection: '',
  description: '',
  subSection: '',
  generalLineAt: '',
  averageDailyAttendence: '',
  toUnitDescForAppro: '',
  motorVehicle: '',
  isJt4Mill: '',
  isMtgTax: '',
  isMVTS: '',
  details: {
    fundDescription: '',
    appFund: '',
    sharedFundAltKey: '',
    linkToMisc: '',
    remitTo: '',
    SDRType: '',
  },
};

export const taxUnit = {
  namespaced: true,
  state: () => ({
    regYears: {},
  }),
  mutations: {
    setFiscalRegYears(state, regYears) {
      state.regYears = regYears;
    },
  },
  actions: {
    async loadTaxUnit({ commit, dispatch }, {
      criteria, baseCrudKey,
    }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query taxUnit($criteria: taxUnitCriteriaType) {
            taxUnit(criteria: $criteria) {
                    ${fieldsToGqlString(fields)},
                  }
                }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.taxUnit) {
          const unit = results.data ? results.data.taxUnit || [] : [];
          commit('base/crud/setItems', [baseCrudKey, unit], { root: true });
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Tax Unit list.', { root: true });
      }
    },
    async upsertTaxUnit({ dispatch, rootGetters, commit }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertTaxUnit($item: taxUnitInputType!)  {
            upsertTaxUnit(upsert: $item) {
              ${fieldsToGqlString(fields)},
            }
          }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const taxFundStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newTaxUnit = results.data.upsertTaxUnit;
        const newList = mergeByIdNewAtTop(taxFundStateData || [], newTaxUnit);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Tax Unit', { root: true });
      }
    },
    async loadFiscalRegYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            taxUnitStatsFYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setFiscalRegYears', results.data.taxUnitStatsFYRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
    },
  },
};

export default taxUnit;
