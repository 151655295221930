import gql from 'graphql-tag';
import { clients } from '../../util/clients';
import { bulkResult } from '../../util/shared/graphql-global';
import { getWorkstationNumber } from '../../util/workstation-util';

const { auth } = clients.direct;

export const SystemConfigActions = {
  async loadConfig({ dispatch, commit }, { requiredScope, isSystemConfig }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      const scope = requiredScope;
      results = await auth.query({
        query: gql` query loadConfig($scope: String) {
          configs(scope: $scope) { id scope configuration }
        }`,
        variables: {
          scope,
        },
        fetchPolicy: 'no-cache',
      });
      let configData = [];
      if (results.data.configs !== null) {
        configData = results.data.configs;
      }
      if (!isSystemConfig) {
        commit('setSettingItem', { items: configData, scope: requiredScope });
      }
      return configData;
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading Configs.', { root: true });
      return [];
    }
  },
  async updateConfig({ dispatch, commit }, { item, calledScope }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      const itemData = { ...item };
      const itemId = itemData.id;
      delete itemData.id;
      const updates = [{
        id: itemId,
        scope: calledScope,
        configuration: itemData,
      }];
      results = await auth.mutate({
        mutation: gql`mutation upsertConfig ($transaction: [SystemConfigBulkInput])  {
          upsertConfig(upserts: $transaction ) { ${bulkResult} }
        }`,
        variables: { transaction: updates },
      });
      const upsertDoc = results.data.upsertConfig;
      if (upsertDoc !== undefined && upsertDoc.modifiedCount === 1) {
        commit('setAfterUpdate', { data: item, scope: calledScope, newRecordID: null });
        dispatch('flashSuccess', 'Configuration Successfully Updated', { root: true });
      } else if (upsertDoc !== undefined && upsertDoc.insertedCount === 1) {
        commit('setAfterUpdate', { data: item, scope: calledScope, newRecordID: upsertDoc.insertedIds['0'] });
        dispatch('flashSuccess', 'Configuration Successfully Added', { root: true });
      } else if (upsertDoc !== undefined && upsertDoc.modifiedCount === 0) {
        dispatch('flashInfo', 'Nothing to Update', { root: true });
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem on saving configuration.', { root: true });
    }
  },
  fetchAndSetWkst({ commit }, item) {
    const updatedItem = { ...item };
    /*
      Should override item's values as per need
      Worstation ID fetching from localstorage
    */
    updatedItem.wkst = updatedItem.wkst || getWorkstationNumber();
    commit('setWorkstationItem', updatedItem);
    return updatedItem;
  },
};

export default SystemConfigActions;
