import {
  isDate,
} from './vue-global';
// things that are probably useful across multiple programs
// (todo - subdivide this file it if gets longer than 100-400 lines of code?)

// As per business requirenmnt default value
export const defaultDurationOfCorrection = 2;

// see warnings about ISO 8601 and RFC 2822 at:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
// tries to prevent some of the most common ways that new Date(someStr) bites us with a non-UTC date
const tryReallyHardToStayUTC = (dateStr) => {
  const unknownLocaleDateStr = (dateStr || '');
  if (unknownLocaleDateStr.length > 0) {
    const formattedDateRegEx = /^([0-9]{1,4})[/\\-]{1}([0-9]{1,2})[/\\-]{1}([0-9]{1,4})$/;
    const match = formattedDateRegEx.exec(unknownLocaleDateStr);
    if (match) {
      if (match[1].length === 4) {
        const year = match[1].toString().padStart(4, '0');
        const month = match[2].toString().padStart(2, '0');
        const day = match[3].toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      // otherwise (match[3].length === 4)
      const year = match[3].toString().padStart(4, '0');
      const month = match[1].toString().padStart(2, '0');
      const day = match[2].toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }
  return unknownLocaleDateStr; // 🤷🏻‍♂️ don't break stuff
};

export const destructureDate = (date = new Date()) => {
  let dateObj = date;
  if (typeof dateObj === 'string') {
    const utcDateStr = tryReallyHardToStayUTC(dateObj);
    dateObj = new Date(utcDateStr);
  }
  return {
    year: dateObj.getUTCFullYear(),
    month: dateObj.getUTCMonth() + 1,
    day: dateObj.getUTCDate(),
  };
};

export const toTaxYear = (date = new Date()) => {
  const { year } = destructureDate(date);
  return year;
};

export const toIsoDate = (date = new Date()) => {
  const { year, month, day } = destructureDate(date);
  const monthStr = month.toString().padStart(2, '0');
  const dayStr = day.toString().padStart(2, '0');
  return `${year}-${monthStr}-${dayStr}`;
};

export const toShortDate = (date = new Date()) => {
  const { year, month, day } = destructureDate(date);
  const monthStr = month.toString().padStart(2, '0');
  const dayStr = day.toString().padStart(2, '0');
  return `${monthStr}-${dayStr}-${year}`;
};

export const dateDiff = (startDt, finishDt, config = {}) => {
  const start = isDate(startDt) ? startDt : new Date(startDt);
  const finish = isDate(finishDt) ? finishDt : new Date(finishDt);

  let diffMs;
  if (config.relative) {
    diffMs = finish - start; // milliseconds
  } else {
    diffMs = Math.abs(finish - start); // milliseconds
  }
  const mins = Math.floor(diffMs / 1000 / 60);
  const secs = Math.floor(diffMs / 1000) % 60;
  const years = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25));
  return {
    diffMs,
    mins,
    secs,
    years,
  };
};

export const daysOffset = (days = 1, now = new Date()) => {
  const nowDt = new Date(now);
  return new Date(nowDt.valueOf() - 1000 * 3600 * days * 24);
};

export const dateDiffStr = (start, finish) => {
  const { mins, secs } = dateDiff(start, finish);
  return `${mins} minute(s), ${secs} second(s)`;
};

export const isNotBeforeDate = (value, todaysDate, offset) => {
  const registerDt = new Date(value);
  const twoDaysBefore = daysOffset(offset, new Date(todaysDate));
  const { diffMs } = dateDiff(twoDaysBefore, registerDt, { relative: 1 });
  return (diffMs > 0);
};

export const toIsoDateStr = (date) => (date ? date.toJSON().slice(0, 10) : '');

export const toFiscalYear = (date) => {
  const dateAsIsoStr = toIsoDateStr(date);
  const year = date.getFullYear();
  const dateFyCutOffIsoStr = new Date(`${year}-07-01`).toJSON().slice(0, 10);
  if (dateAsIsoStr < dateFyCutOffIsoStr) {
    return year;
  }
  return year + 1;
};

// get the fiscal years which cover these dates
export const getFiscalYears = (minDate, maxDate, allowAll = true) => {
  // coalesce invalid dates to "now" to minimize range returned
  const min = Number.isNaN(minDate.getTime()) ? new Date() : minDate;
  const max = Number.isNaN(maxDate.getTime()) ? new Date() : maxDate;
  const { year: minYear, month: minMonth } = destructureDate(min);
  const { year: maxYear, month: maxMonth } = destructureDate(max);
  // July 1 is the cut-off for a FY
  const maxFY = (maxMonth >= 7) ? maxYear + 1 : maxYear;
  const minFY = (minMonth < 7) ? minYear - 1 : minYear;

  let fy = minFY;
  const fyOptions = [];
  if (allowAll) {
    fyOptions.push({
      range: {},
      year: 0,
      name: '(all years)',
    });
  }
  while (fy < maxFY) {
    fyOptions.push({
      range: {
        from: `${fy}-07-01`,
        to: `${fy + 1}-06-30`,
      },
      year: (fy + 1),
      name: `FY ${fy}-${fy + 1}`,
    });
    fy += 1;
  }
  return fyOptions;
};

export const getFirstChar = (value) => {
  const firstChar = (value || '').substring(0, 1);
  return firstChar;
};

export const boolToStr = (value) => {
  let str = 'No';
  if (value !== undefined && value !== '') {
    str = value ? 'Yes' : 'No';
  }
  return str;
};

export const calculateFiscalYears = (value, allowAll = true) => {
  const yearsRange = [];
  if (Number.isInteger(value.min) && Number.isInteger(value.max)) {
    if (allowAll) {
      yearsRange.push({
        year: 0,
        name: '(all years)',
      });
    }
    for (let year = value.min; year <= value.max; year += 1) {
      yearsRange.push({
        year,
        name: `FY ${year - 1}-${year}`,
      });
    }
  }
  return yearsRange;
};

export const getFYList = (value) => calculateFiscalYears(value, false).map((x) => x.year);

export const getMaxFY = (fyArray) => {
  const sortedFYArray = (fyArray || []).sort((a, b) => b - a);
  return sortedFYArray[0] || null;
};

export const bankVoucherDataHeaders = [
  { text: 'FY', value: 'fiscalYear' },
  { text: 'Registration Date', value: 'register' },
  { text: 'Register Number', value: 'registration' },
  { text: 'Voucher No', value: 'voucher' },
  { text: 'Amount', value: 'amount' },
  { text: 'Paid Date', value: 'date' },
  { text: 'Department Name', value: 'deptName' },
  { text: 'Department No', value: 'deptNo' },
];

export const importFileProcessedData = {
  importBankVoucher: 'loadBankVoucherData',
};

export const usersLogoutTimePeriods = [
  { timePeriod: 1800, description: '30 Minutes' },
  { timePeriod: 3600, description: '1 Hour' },
  { timePeriod: 7200, description: '2 Hour' },
  { timePeriod: 14400, description: '4 Hour' },
  { timePeriod: 28800, description: '8 Hour' },
  { timePeriod: 43200, description: '12 Hour' },
];

export const usersPassExpPeriods = [
  { expPeriod: 30, description: '1 Month' },
  { expPeriod: 60, description: '2 Months' },
  { expPeriod: 120, description: '4 Months' },
  { expPeriod: 180, description: '6 Months' },
  { expPeriod: 365, description: '1 Year' },
];

/*
  Don't change the order of objects
  Because at some places we are using forteApiModes[0].apiURL as default
*/
export const forteApiModes = [
  {
    apiMode: 'SANDBOX',
    apiURL: 'https://sandbox.forte.net/checkout/v2/js',
  },
  {
    apiMode: 'PROD',
    apiURL: 'https://checkout.forte.net/v2/js',
  },
];

export const checkDuplicate = ({
  stateArr, fieldName, val, isNewItem,
}) => {
  let stateData = stateArr.map((x) => x);
  if (!isNewItem) {
    stateData = stateData.filter((item) => item._id !== val._id);
  }
  const found = stateData.some((el) => el[fieldName] === val[fieldName]);
  if (found) {
    return `${val[fieldName]} already exist`;
  }
  return true;
};

export const validateInputLength = ({
  fieldName, label = 'This field', val, requiredLength,
}) => {
  if (!!val[fieldName] && val[fieldName].length !== requiredLength) {
    return `${label} needs to be ${requiredLength} characters long`;
  }
  return true;
};

export const updateDateFormat = (criteria, dateForCriteria) => {
  const updatedCriteria = { ...criteria };
  dateForCriteria.forEach((element) => {
    if (updatedCriteria[element]) {
      updatedCriteria[element] = toIsoDate(updatedCriteria[element]);
    } else {
      updatedCriteria[element] = null;
    }
  });
  return updatedCriteria;
};

export const getLatestFy = (fiscalYears) => {
  if (!Array.isArray(fiscalYears) || (fiscalYears || []).length === 0) return 0;
  return (fiscalYears[fiscalYears.length - 1] || {}).year || 0;
};

export const isHealthDeptImports = (program) => program === 'Health Dept Warrant Imports';

export const isWorkDayImports = (program) => program === 'Work Day Imports';
