import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import {
  fieldsToGqlString,
  mergeByIdNewAtTop,
} from '../../../util/shared/vue-global';

const { backend } = clients.direct;
const defaultTransactionFields = {
  _id: '',
  fiscalYear: '',
  isApportionLikeTax: '',
  bank: {
    _id: '',
    accountNumber: '',
    altKeyBase: '',
    bankCode: '',
    bankName: '',
    groupCode: '',
    routingNumber: '',
    bankAddress: '',
  },
  businessDate: '',
  description: '',
  whomStr: '',
  chunkID: '',
  amount: '',
  receiptNumber: '',
  isVoid: '',
  isCalculatePercentage: '',
  isSpecialApportionment: '',
  taxYear: '',
  hasFiles: '',
  transactionNumber: '',
  details: {
    fund: {
      _id: '',
      fund: '',
      fiscalYear: '',
      description: '',
    },
    source: {
      _id: '',
      type: '',
      sourceDescription: '',
      subSourceDescription: '',
      sourceSubSourceNumber: '',
      fiscalYear: '',
    },
    unit: {
      _id: '',
      unitCode: '',
      unitCodeNumber: '',
      description: '',
    },
    taxDistrict: {
      _id: '',
      districtCode: '',
      districtName: '',
    },
    percentageBreakdown: '',
    amount: '',
  },
  paymentDetails: {
    paymentType: '',
    amount: '',
    cashReceived: '',
    cashChange: '',
    checkNumber: '',
    checkPaidBy: '',
    eftType: '',
    creditType: '',
  },
  wkst: '',
};

export const miscTransaction = {
  namespaced: true,
  state: () => ({
    regYears: {},
    wkstNumList: [],
    nextReceiptNumber: undefined,
    receiptPulse: false,
    receiptPolling: undefined, // setInterval / clearInterval timer
    selectedItems: [],
    businessDateRange: undefined,
  }),
  mutations: {
    setFiscalRegYears(state, regYears) {
      state.regYears = regYears;
    },
    setStats(state, results) {
      if (results.miscStatsMaxReceipt && results.miscStatsMaxReceipt.max !== undefined) {
        const nextReceiptNumber = results.miscStatsMaxReceipt.max + 1;
        if (state.nextReceiptNumber !== nextReceiptNumber) {
          state.nextReceiptNumber = nextReceiptNumber;
        }
      }
    },
    setReceiptPulse(state, shouldPulse) {
      state.receiptPulse = shouldPulse;
    },
    setReceiptPolling(state, timer) {
      if (state.receiptPolling) {
        clearInterval(state.receiptPolling);
      }
      state.receiptPolling = timer;
    },
    setSelectedItems(state, items) {
      state.selectedItems = items;
    },
    setBusinessDateRange(state, businessDateRange) {
      state.businessDateRange = businessDateRange;
    },
    updateMiscLocalFileStatus(state, { objectId, status }) {
      const result = this.getters['base/crud/items']('miscTransaction').find(
        (object) => object._id === objectId,
      );
      result.hasFiles = status;
    },
    setWkstList(state, wkstNumList) {
      state.wkstNumList = wkstNumList;
    },
  },
  actions: {
    async loadMiscTransaction({ commit, dispatch }, {
      criteria, fields, baseCrudKey, postProcessTransaction,
    }) {
      let results;
      const gqlfields = fields || defaultTransactionFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query miscTransaction($criteria: miscTransCriteriaType) {
            miscTransaction(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        if (baseCrudKey && results.data && results.data.miscTransaction) {
          const miscTrans = postProcessTransaction
            ? postProcessTransaction(results.data.miscTransaction)
              || [] : results.data.miscTransaction;
          commit('base/crud/setItems', [baseCrudKey, miscTrans], { root: true });
        }
        return results.data.miscTransaction;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Transaction List!', { root: true });
        return [];
      }
    },
    async upsertMiscTrans({ dispatch, rootGetters, commit }, {
      item, fields, baseCrudKey, postProcessTransaction,
    }) {
      let results;
      try {
        const gqlfields = fields || defaultTransactionFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertMiscTrans ($item: miscTransInputType!)  {
                upsertMiscTrans(upsert: $item) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const miscTransStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newTransaction = results.data.upsertMiscTrans;
        const [processedTrans] = postProcessTransaction
          ? postProcessTransaction([newTransaction]) : [newTransaction];
        const newList = mergeByIdNewAtTop(miscTransStateData || [], processedTrans);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
        return newTransaction;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the transaction!', { root: true });
      }
      return {};
    },
    async deleteMiscTransaction({ commit, rootGetters, dispatch }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation deleteMiscTrans ($id: ID!)  {
            deleteMiscTrans(id: $id) {
                  _id
                }
              }`,
          variables: {
            id: item._id,
          },
        });
        clients.handleGQLErrors(results, commit);
        const delId = results.data.deleteMiscTrans._id;
        const miscTransData = rootGetters['base/crud/items'](baseCrudKey);
        const updatedData = miscTransData.filter((d) => d._id !== delId);
        commit('base/crud/setItems', [baseCrudKey, updatedData], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem while deleting the transaction!', { root: true });
      }
    },
    async loadFiscalRegYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            miscStatsFYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setFiscalRegYears', results.data.miscStatsFYRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
    },
    async loadBusinessDateRange({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            miscStatsBusinessDateRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setBusinessDateRange', results.data.miscStatsBusinessDateRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max business date ranges.', { root: true });
      }
    },
    async loadLastReceiptNo({ commit, dispatch }, { fiscalYear }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query miscStatsMaxReceipt ($fiscalYear: Int!) {
            miscStatsMaxReceipt (fiscalYear: $fiscalYear) { max }
          }`,
          variables: { fiscalYear },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        commit('setStats', results.data);
        return results.data.miscStatsMaxReceipt.max;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem determining the last receipt number!', { root: true });
      }
      return null;
    },
    async loadLastTransactionNo({ commit, dispatch }, { fiscalYear }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query miscStatsMaxTransNum ($fiscalYear: Int!) {
            miscStatsMaxTransNum (fiscalYear: $fiscalYear) { max }
          }`,
          variables: { fiscalYear },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const maxNo = results.data ? (results.data.miscStatsMaxTransNum || {}).max : null;
        commit('common/cmnTransNumber/setStats', maxNo, { root: true });
        return maxNo;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem determining the last transaction number!', { root: true });
      }
      return null;
    },
    async loadWkstList({ commit, dispatch }, { fiscalYear }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query miscStatsWkstList ($fiscalYear: Int!) {
            miscStatsWkstList(fiscalYear: $fiscalYear)
            {
              _id
              wkstInt
            }
          }`,
          variables: { fiscalYear },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setWkstList', results.data.miscStatsWkstList);
        }
        return results.data && results.data.miscStatsWkstList;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting wkst numbers.', { root: true });
      }
      return [];
    },
  },
};

export default miscTransaction;
