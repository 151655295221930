// This is some basic / boilerplate state and mutations for listing things,
// editing those things in a dialog, etc.
// Can use concurrently in multiple components, because of keyed storage

import Vue from 'vue';

const defaults = {
  itemIsVisible: false,
  criteria: {},
  items: [],
  item: {},
  index: -1,
};

const crudHelpers = {
  ensureInit(state, property, key) {
    if (state[property][key] === undefined) {
      Vue.set(state[property], key, defaults[property]);
    }
  },
  stdMutationFunc: (property) => {
    // vuex only supports one arg to a mutation, so use [array, destructuring]
    const returnFunc = function stdMutationFunc(state, [key, newState]) {
      crudHelpers.ensureInit(state, property, key);
      state[property][key] = newState;
    };
    return returnFunc;
  },
  stdGetterFunc: (property) => {
    const returnFunc = (state) => (key) => {
      crudHelpers.ensureInit(state, property, key);
      return state[property][key];
    };
    return returnFunc;
  },
};

export const crud = {
  namespaced: true,
  // ---------------
  // actions will tend to be GQL / API specific, so we probably won't define any at the base level
  // actions will certainly make use of the base features in this file, to save time in coding
  // ---------------
  // actions: {
  // },
  // ---------------
  state: () => ({
    criteria: {}, // criteria of items listed (pagination + any custom filtering combined)
    items: {}, // list of items
    item: {}, // item (to edit, to view, whatever/etc)
    index: {}, // index of item
    isVisible: {}, // item dialog visibility
  }),
  mutations: {
    // setCriteria: crudHelpers.stdMutationFunc('criteria'),
    setCriteria(state, [key, newState, merge]) {
      crudHelpers.ensureInit(state, 'criteria', key);
      if (merge) {
        state.criteria[key] = {
          ...state.criteria[key],
          ...newState,
        };
      } else {
        state.criteria[key] = newState;
      }
    },
    setItems: crudHelpers.stdMutationFunc('items'),
    setItem: crudHelpers.stdMutationFunc('item'),
    setIndex: crudHelpers.stdMutationFunc('index'),
    setIsVisible: crudHelpers.stdMutationFunc('isVisible'),
  },
  getters: {
    criteria: crudHelpers.stdGetterFunc('criteria'),
    items: crudHelpers.stdGetterFunc('items'),
    item: crudHelpers.stdGetterFunc('item'),
    index: crudHelpers.stdGetterFunc('index'),
    isVisible: crudHelpers.stdGetterFunc('isVisible'),
    skipLimitPage: (state, getters, rootState, rootGetters) => (page) => {
      const { itemsperpage } = rootGetters['user/getSettings'];
      const skip = (page - 1) * itemsperpage;
      const limit = itemsperpage;
      return {
        skip,
        limit,
      };
    },
  },
};

export default crud;
