export const bulkResult = `insertedCount,
  upsertedCount,
  matchedCount,
  deletedCount,
  modifiedCount,
  insertedIds,
  upsertedIds,
  result {ok, nInserted, nMatched, nRemoved, nUpserted, nModified}`;

export default {
  bulkResult,
};
