import { clients } from '../../util/clients';

const { backendRest } = clients.direct;

export const notifications = {
  namespaced: true,
  state: () => ({
    announcements: undefined,
    snackbarAnnouncements: [],
  }),
  mutations: {
    setAnnouncement(state, announcements) {
      state.announcements = announcements;
    },
    setSnackbarAnnouncements(state, announcementsArr) {
      state.snackbarAnnouncements = announcementsArr;
    },
  },
  actions: {
    async loadAnnouncements({ commit, dispatch }) {
      try {
        await dispatch('reAuth', null, { root: true });
        const response = await backendRest.get('/announcements/');
        if (response && response.data && response.status === 200) {
          const announcementRes = [...response.data] || [];
          announcementRes.sort((
            a, b,
          ) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          commit('setAnnouncement', announcementRes);
        } else {
          console.error('There was a problem listing announcements.');
        }
      } catch (e) {
        dispatch('logException', e, { root: true });
        dispatch('flashError', 'There was a problem listing announcements.', { root: true });
      }
    },
  },
};

export default notifications;
