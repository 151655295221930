import gql from 'graphql-tag';
import { clients } from '../../util/clients';

const { backend } = clients.direct;

export const files = {
  namespaced: true,
  state: () => ({
    files: [],
    pendingFiles: [],
  }),
  mutations: {
    setFiles(state, newFiles) {
      state.files = newFiles;
    },
    setPendingFiles(state, newPendingFiles) {
      state.pendingFiles = newPendingFiles;
    },
  },
  getters: {
    fileCount(state) {
      return state.files.length;
    },
    pendingFileCount(state) {
      return state.pendingFiles.length;
    },
  },
  actions: {
    async backgroundAttachFiles({ dispatch }, args) {
      await dispatch('reAuth', null, { root: true });
      await backend.mutate({
        mutation: gql`mutation backgroundAttachFiles ($bucketName: String!, $program: String!, $parentObjectType: String!, $parentObjectId: ID!) {
          upsertFiles(bucketName: $bucketName, program: $program, parentObjectType: $parentObjectType, parentObjectId: $parentObjectId)
        }`,
        variables: args,
      });
    },
    async rotateImage({ dispatch }, args) {
      await dispatch('reAuth', null, { root: true });
      await backend.mutate({
        mutation: gql`mutation rotateImage ($bucketName: String!, $id: ID!, $rotate: Int!) {
          upsertFile(bucketName: $bucketName, id: $id, rotate: $rotate)
        }`,
        variables: args,
      });
    },
    async removeAttachedFile({ dispatch, state, commit }, args) {
      await dispatch('reAuth', null, { root: true });
      await backend.mutate({
        mutation: gql`mutation removeAttachedFile ($bucketName: String!, $id: ID!) {
          deleteFile(bucketName: $bucketName, id: $id)
        }`,
        variables: args,
      });
      const newFiles = state.files.filter((f) => f.id !== args.id);
      commit('setFiles', newFiles);
      // $program: String!, $parentObjectType: String!, $parentObjectId: ID!
      // await dispatch('loadAttachedFiles', args);
    },
    async loadAttachedFiles({ commit, dispatch }, args) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query loadAttachedFiles ($bucketName: String!, $program: String!, $parentObjectType: String!, $parentObjectId: ID!) {
            files(bucketName: $bucketName, program: $program, parentObjectType: $parentObjectType, parentObjectId: $parentObjectId) {
              id,
              bucketName,
              lengthStr,
              filename,
              metadata,
            }
          }`,
          variables: args,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.files) {
          commit('setFiles', results.data.files);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem loading file attachments.', { root: true });
      }
    },
    async loadPendingFiles({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            pendingFiles {
              lengthStr,
              filename,
            }
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.pendingFiles) {
          commit('setPendingFiles', results.data.pendingFiles);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem loading a list of pending files for the current user.', { root: true });
      }
    },
    async clearPendingFiles({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        await backend.mutate({
          mutation: gql`mutation {
            clearPendingFiles
          }`,
        });
        commit('setPendingFiles', []);
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem loading a list of pending files for the current user.', { root: true });
      }
    },
  },
};

export default files;
