import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';

export const BulkRoutes = [
  {
    path: '/bulk',
    name: 'Import / Export',
    component: () => import('../views/bulk/Home.vue'),
  },
  {
    path: '/bulk/importfiles',
    name: 'Import Files',
    component: () => import('../views/bulk/ImportFiles.vue'),
    children: [
      {
        path: 'orig',
        name: 'Import Files (original)',
        component: () => import('../views/bulk/ImportFilesOrig.vue'),
      },
      {
        path: 'beta',
        name: 'Import Files (beta)',
        component: () => import('../views/bulk/ImportFilesBeta.vue'),
      },
      {
        path: 'statesysimpvouch',
        name: 'Import Vouchers from Depts',
        component: () => import('../views/bulk/StateSystemImportVouchers.vue'),
      },
    ],
  },
  {
    path: '/bulk/exportfiles',
    name: 'Export Files',
    component: () => import('../views/bulk/ExportFiles.vue'),
    children: [
      {
        path: 'orig',
        name: 'Export Files (original)',
        component: () => import('../views/bulk/ExportFilesOriginal.vue'),
      },
      {
        path: 'beta',
        name: 'Export Files (beta)',
        component: () => import('../views/bulk/ExportFilesBeta.vue'),
      },
    ],
  },
  {
    path: '/bulk/profileconfig',
    name: 'Profile Config',
    component: () => import('../views/bulk/ProfileConfig.vue'),
    children: [
      {
        path: 'profiles',
        name: 'Browse Import Profiles',
        component: () => import('../views/bulk/BrowseImportProfiles.vue'),
      },
      {
        path: 'transformmapper',
        name: 'Transform Mapper (beta)',
        component: () => import('../views/config/TransformMappers.vue'),
      },
    ],
  },
  {
    path: '/bulk/configimportprofile/:id?',
    name: 'Configure an Import Profile',
    component: () => import('../views/bulk/ConfigImportProfile.vue'),
  },
  {
    path: '/bulk/viewimportprofile/:id',
    name: 'View Import Files Profile',
    component: () => import('../views/bulk/ViewImportProfiles.vue'),
  },
  {
    path: '/bulk/transformfiles',
    name: 'Transform Files',
    component: () => import('../views/bulk/TransformFiles.vue'),
  },
  {
    path: '/bulk/backuprestoredb',
    name: 'Database Backups',
    component: () => import('../views/bulk/BackupRestoreDb.vue'),
  },
  {
    // This is deprecated version.
    path: '/reports/fileprocessingdetails/:type',
    name: 'File Processing Details',
    component: () => import('../views/bulk/FileProcessingDetails.vue'),
  },
  {
    // This is a reusable component, please prefer this for viewing file processing data.
    path: '/bulk/fileprocessingdetails/:type',
    name: 'View File Processing Details',
    component: () => import('../views/bulk/ImportFileProcessedDetails.vue'),
  },
];

BulkRoutes.forEach((element, index) => {
  BulkRoutes[index].beforeEnter = async (to, from, next) => {
    await accessCriteria([...authRoles.BankProfileAdmins, ...authRoles.ImportExportAdmins], next);
  };
});

export default BulkRoutes;
