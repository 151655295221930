// on Mac, Alt + C generates ç by default, so watch for both
export const isAltC = (e) => (e.altKey || e.metaKey)
  && (e.key.toLowerCase() === 'c' || e.key.toLowerCase() === 'ç');

export const isAltR = (e) => (e.altKey || e.metaKey)
  && (e.key.toLowerCase() === 'r' || e.key.toLowerCase() === '®');

export const isAltE = (e) => (e.altKey || e.metaKey)
  && (e.key.toLowerCase() === 'e' || e.key.toLowerCase() === '´');

export const isTabOrPlus = (e) => (!e.shiftKey && e.key === 'Tab')
  || (e.key === '+');

// Insert key on Windows, Ctrl + I on Mac
export const isInsert = (e) => e.key === 'Insert' || (e.key === 'i' && e.ctrlKey);

// todo - can't intercept these in a browser.. something else?
export const isCreditFnKey = (e) => e.key === 'F4';
export const isCashFnKey = (e) => e.key === 'F5';
export const isCheckFnKey = (e) => e.key === 'F6';

export const focusBySelector = (selector, whichOne = 'last') => {
  const allAmounts = document.querySelectorAll(selector);
  if (allAmounts.length > 0) {
    let focusMe;
    if (whichOne === 'last') {
      focusMe = allAmounts[allAmounts.length - 1];
    } else if (whichOne === 'first') {
      [focusMe] = allAmounts;
    }
    // console.log(focusMe);
    focusMe.focus();
  }
};

export const selectInputTextBySelector = (selector, whichOne = 'last') => {
  const allSelectors = document.querySelectorAll(selector);
  if (allSelectors.length > 0) {
    let selectMe;
    if (whichOne === 'last') {
      selectMe = allSelectors[allSelectors.length - 1];
    } else if (whichOne === 'first') {
      [selectMe] = allSelectors;
    }
    selectMe.select();
  }
};

export const amountToNumber = (amount) => {
  let amountStr = '0.00';
  // this checks if not undefined and if not null
  if (!(amount == null)) {
    amountStr = (amount.toString()).replace(/,/g, '');
  }
  // parse a float, truncate 2 decimals as a string, re-parse
  // https://stackoverflow.com/a/4435190/4161426 maybe slow? 🤷🏻‍♂️
  const amountFloat = parseFloat(parseFloat(amountStr).toFixed(2));
  return Number.isNaN(amountFloat) ? 0.00 : amountFloat;
};

export const amountFormat = (amount, withCommas = true) => {
  /* Sometime 'amountFormat' get called for already formatted amount */
  const amountInNumber = amountToNumber(amount);
  let amountStr = amountInNumber.toFixed(2);
  if (withCommas) {
    amountStr = amountStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return amountStr;
};

export const camelCaseToWords = (text) => {
  // importBankVoucher => Import Bank Voucher
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

// example: ('this.that', { this: { that: 'a' } })
export const resolveSubProp = (prop, obj) => {
  const reducer = (acc, curr) => {
    const result = acc ? acc[curr] : null;
    return result;
  };
  // Note to future self
  // prop.split is not a function (if you put it under computed: {} vs methods: {})
  return prop.split('.').reduce(reducer, obj);
};

// use to format a date/time (Date or String) in the local timezone's preferred format
export const toLocalDateTime = (date = '0001-01-01', unitTestLocaleIfAny = undefined) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleString(unitTestLocaleIfAny);
};

export const isDate = (dateString) => {
  if ((new Date(dateString)).getDate()) return true;
  return false;
};

export const focusAmount = (e) => {
  if (amountToNumber(e.target.value) === 0.00) {
    e.target.select();
  }
};

export const onFocusAutocomplete = (e) => {
  setTimeout(() => {
    e.target.select();
  }, 200);
};

export const focusNextField = (self, e, ref) => {
  e.preventDefault();
  self.$refs[`${ref}`].focus();
};

export const mergeByIdNewAtTop = (existingList, newItem) => {
  let wasInList = false;
  let newList = existingList.map((t) => {
    if ((t.id && t.id === newItem.id) || (t._id && t._id === newItem._id)) {
      wasInList = true;
      return newItem;
    }
    return t;
  });
  if (!wasInList) {
    newList = [newItem, ...newList];
  }
  return newList;
};

export const getItemsIndexByID = (itemsList, item) => itemsList.map((t) => t.id).indexOf(item.id);

export const mimeTypeByFileName = (fileName) => {
  const match = (fileName || '')
    .toLowerCase().match(/\.([a-zA-Z0-9]+)$/);
  const fileExtension = match ? match[1] : ''; // asDf.XyZ -> xyz
  switch (fileExtension) {
    case 'pdf': {
      return 'application/pdf';
    }
    case 'png': {
      return 'image/png';
    }
    case 'jpeg':
    case 'jpg': {
      return 'image/jpeg';
    }
    case 'tiff':
    case 'tif': {
      return 'image/tiff';
    }
    default: {
      return 'application/octet-stream';
    }
  }
};

export const sanitizeFilename = (filename) => (filename || '').replace(/[^a-z0-9]/gi, '_');

export const isEmptyObject = (obj) => !(obj != null && typeof obj === 'object' && Object.keys(obj).length > 0);

export const fieldsToGqlString = (fields) => {
  let string = '';
  Object.keys(fields).forEach((key) => {
    const value = fields[key];
    if (!isEmptyObject(value)) {
      string += `${key} {${fieldsToGqlString(value)}},`;
    } else {
      string += `${key},`;
    }
  });
  return string;
};

export const parseJson = (response) => {
  let parsedResponse;
  let isResParsed;
  try {
    parsedResponse = JSON.parse(response);
    isResParsed = true;
  } catch (e) {
    parsedResponse = response;
    isResParsed = false;
  }
  return [parsedResponse, isResParsed];
};

export const base64FromArrayBuffer = async (data) => {
  const base64url = await new Promise((r) => {
    const reader = new FileReader();
    reader.onload = () => r(reader.result);
    reader.readAsDataURL(new Blob([data]));
  });
  return base64url.split(',', 2)[1];
};

export const extractId = (item) => {
  if (item == null) return null;
  if (typeof item === 'object' && item._id !== undefined) return item._id;
  return item;
};

export const checkStringEmptiness = (stringVal) => (stringVal == null || stringVal === '');

export const resetWindow = (self, initdata, retainData = {}) => {
  const updatedObj = { ...initdata, ...retainData };
  Object.assign(self.$data, { ...updatedObj });
};

export const defaultOverlayConfig = () => ({ value: 0, flag: false });

export const defaultGridLoading = () => ({ text: '', flag: false });

export const incrementOverlay = (self, total, current) => {
  const dupSelf = self;
  dupSelf.overlay.value = parseInt((100 * (current + 1)) / total, 10);
};
export const manageGridLoading = (self, flag, text = '') => {
  const dupSelf = self;
  dupSelf.gridLoading.flag = flag;
  dupSelf.gridLoading.text = text;
};

export default {
  isAltC,
  isAltR,
  isAltE,
  isTabOrPlus,
  isCreditFnKey,
  isCashFnKey,
  focusAmount,
  focusBySelector,
  amountFormat,
  camelCaseToWords,
  isDate,
  amountToNumber,
  selectInputTextBySelector,
  getItemsIndexByID,
  mimeTypeByFileName,
  sanitizeFilename,
  parseJson,
  isEmptyObject,
  fieldsToGqlString,
  onFocusAutocomplete,
  focusNextField,
  checkStringEmptiness,
  extractId,
  defaultGridLoading,
  defaultOverlayConfig,
  incrementOverlay,
  manageGridLoading,
};
