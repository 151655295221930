import { clients } from '../../util/clients';

export const users = {
  namespaced: true,
  state: () => ({
    auth_users: undefined,
  }),
  mutations: {
    setAuthUsers(state, authUsers) {
      state.auth_users = authUsers;
    },
  },
  actions: {
    async loadAuthUsers({ commit, dispatch }) {
      let results;
      try {
        results = await clients.auth.getAuthUsers();
        if (results.data && results.data.auths) {
          commit('setAuthUsers', results.data.auths);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem loading the audit list.', { root: true });
      }
    },
  },
};

export default users;
