import { users } from './users';
import { token } from './token';

export const auth = {
  namespaced: true,
  modules: {
    users,
    token,
  },
};

export default auth;
