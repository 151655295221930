import gql from 'graphql-tag';
import { clients } from '../../util/clients';
import {
  // mergeByIdNewAtTop,
  fieldsToGqlString,
} from '../../util/shared/vue-global';

const { backend } = clients.direct;

const getPrevChange = (audits, c, pIndex, auditLength) => {
  let prevChange;
  if (pIndex < auditLength) {
    const prevItem = audits[pIndex];
    prevChange = (prevItem.changes || []).find((pc) => pc.key === c.key);
    if (prevChange) {
      return prevChange;
    }
    const nIndex = pIndex + 1;
    return getPrevChange(audits, c, nIndex, auditLength);
  }
  return prevChange;
};

/**
 * @deprecated Will delete soon when feature become stable, Use 'compareLogs' instead.
 */
export const flagDiffs = (items, amountChangeStyle) => {
  console.warn('Calling deprecated function!');
  // special post-processing for detecting entries where the amount changes
  let crudRowCssAppend;
  const auditItems = items
    .map((a, i, audits) => {
      const prevIdx = i + 1;
      let prevItem;
      if (prevIdx >= 0 && prevIdx < audits.length) {
        prevItem = audits[prevIdx];
      }
      let hasAmountChanged = false;
      const newChanges = (a.changes || [])
        .map((c) => {
          let thisIsTheAmountChange = false;
          if (c && c.key && c.to && c.key.match(/amount/)) {
            if (prevItem) {
              const prevChange = prevItem.changes.filter((pC) => pC.key === c.key)[0];
              if (prevChange && prevChange.to !== undefined && prevChange.to !== c.to) {
                crudRowCssAppend = amountChangeStyle;
                hasAmountChanged = true;
                thisIsTheAmountChange = true;
              }
            } else {
              crudRowCssAppend = amountChangeStyle;
              hasAmountChanged = true;
              thisIsTheAmountChange = true;
            }
          }
          let prevChange;
          if (prevItem) {
            [prevChange] = (prevItem.changes || []).filter((pc) => pc.key === c.key);
          }
          return {
            ...c,
            from: (prevChange && prevChange.to !== c.to) ? prevChange.to : undefined,
            crudRowCssAppend: thisIsTheAmountChange ? crudRowCssAppend : undefined,
          };
        });
      return {
        ...a,
        changes: newChanges,
        crudRowCssAppend: hasAmountChanged ? crudRowCssAppend : undefined,
      };
    });
  return auditItems;
};

export const compareLogs = (items, amountChangeStyle) => {
  // special post-processing for comparing logs so can get changes with 'from' and 'to'
  let crudRowCssAppend;
  const auditLength = items.length;
  const auditItems = items
    .map((a, i, audits) => {
      const prevIdx = i + 1;
      let hasAmountChanged = false;
      const newChanges = (a.changes || [])
        .map((c) => {
          let thisIsTheAmountChange = false;
          if (c && c.key && c.to && c.key.match(/amount/)) {
            crudRowCssAppend = amountChangeStyle;
            hasAmountChanged = true;
            thisIsTheAmountChange = true;
          }
          const prevChange = getPrevChange(audits, c, prevIdx, auditLength);
          return {
            ...c,
            from: (prevChange && prevChange.to !== c.to) ? prevChange.to : undefined,
            crudRowCssAppend: thisIsTheAmountChange ? crudRowCssAppend : undefined,
          };
        });
      return {
        ...a,
        changes: newChanges,
        crudRowCssAppend: hasAmountChanged ? crudRowCssAppend : undefined,
      };
    });
  return auditItems;
};

export const audit = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async loadAudits({ commit, dispatch }, {
      criteria, fields, baseCrudKey, postProcessAudits,
    }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        const cleanedCriteria = { ...criteria };
        if (cleanedCriteria.whenFrom === '') {
          delete cleanedCriteria.whenFrom;
        }
        if (cleanedCriteria.whenTo === '') {
          delete cleanedCriteria.whenTo;
        }
        results = await backend.query({
          query: gql` query audits($criteria: AuditCriteriaType) {
            audits(criteria: $criteria) {
              ${fieldsToGqlString(fields)}, changes { key, to }
            }
          }`,
          variables: { criteria: cleanedCriteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.audits) {
          const audits = postProcessAudits
            ? postProcessAudits(results.data.audits) : results.data.audits;
          commit('base/crud/setItems', [baseCrudKey, audits], { root: true });
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem loading the audit list.', { root: true });
      }
    },
  },
};

export default audit;
