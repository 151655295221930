<template>
  <v-dialog :style="{'z-index':zIndexLoginDialog}" v-model="dialog" persistent max-width="70vw">
    <v-overlay :value="overlay">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
    <v-card color="teal lighten-5">
      <v-card-title>Pardon the interruption</v-card-title>
      <v-card-subtitle>We need you to authenticate</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              solo
              v-model="username"
              label="Login"
              class="login-user"
              ref="loginUser"
              id="loginUser"
              name="loginUser"
              :prepend-inner-icon="icons.mdiAccount"
              :error-messages="loginErrorMsgs"
              @input="usernameChanged"
              @blur="usernameChanged"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <LoginUserPass v-if="preferPassword || !allowWebAuthNLoginType"
          @login="userpassAuth" />
        <span class="mr-2">{{userDeviceOtpDisplay}}</span>
        <span class="mr-2" v-if="canWebAuthNReg">
          Note: this device is eligible to go password-less using <a href="https://webauthn.guide/" target="_blank">WebAuthN</a>. After signing in,
          head over to your profile page to set it up.</span>
        <LoginWebAuthN v-if="allowWebAuthNLoginType"
          @login="webauthnAuth"
          @preferPassword="preferPassword = true" />
      </v-card-text>

      <v-card-actions>
        <v-btn v-if="hasDevBypass" color="primary darken-1" text @click="ofcourseistillauthyou">
          (dev bypass)
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import {
  mdiAccount,
} from '@mdi/js';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import LoginWebAuthN from './LoginWebAuthN.vue';
import LoginUserPass from './LoginUserPass.vue';

import {
  focusBySelector,
  base64FromArrayBuffer,
} from '../../util/shared/vue-global';
import { clients } from '../../util/clients';
import { checkWebAuthN, getChallengeByUsername } from './ReAuth.util';
import { base64StrToArray } from '../../util/authorization';
import { authRoles } from '../../router/roleGroups';
import { TMC_CONSTANTS } from '../../util/shared/constants';

export default {
  components: {
    LoginUserPass,
    LoginWebAuthN,
  },
  mixins: [validationMixin],
  validations: {
    username: { required },
  },
  data: () => ({
    icons: {
      mdiAccount,
    },
    selected: null,
    username: '',
    credentialIdStr: '',
    deviceOtp: '',
    canWebAuthN: false,
    registeredWebAuthN: false,
    preferPassword: false,
    delayTimerId: undefined,
    remoteAuthPolling: undefined,
    zIndexLoginDialog: TMC_CONSTANTS.COMPONENT_Z_INDEX.LOGIN_DIALOG,
  }),
  computed: {
    ...mapState({
      dialog: (state) => state.auth.token.dialog,
      types: (state) => state.auth.token.types,
      overlay: (state) => state.auth.token.overlay,
    }),
    userDeviceOtp() {
      return `${this.username}-${this.deviceOtp}`;
    },
    userDeviceOtpDisplay() {
      if ((this.deviceOtp !== '') && (this.username !== '')) {
        return `User-Device OTP: ${this.userDeviceOtp}`;
      }
      return '';
    },
    hasDevBypass() {
      return (this.types || []).includes('ofcourseistillauthyou');
    },
    loginErrorMsgs() {
      const v = this.$v;
      return (!v.username.$dirty) ? [] : [
        ...(v.username.required) ? [] : ['Required'],
      ];
    },
    allowWebAuthNLoginType() {
      return this.canWebAuthN && this.registeredWebAuthN;
    },
    canWebAuthNReg() {
      return this.canWebAuthN && !this.registeredWebAuthN;
    },
  },
  async mounted() {
    this.loadCredIdStr();
    this.loadDeviceOtp();
    this.loadLastPolledUser();
    this.canWebAuthN = await checkWebAuthN();
    this.loadAuthTypes();
    // pleeease focus :( (sometimes working)
    if (this.$refs.loginUser) {
      this.$refs.loginUser.$el.focus();
    }
    focusBySelector('div.v-input.login-user input');
  },
  methods: {
    ...mapMutations([
      'appendRecentLog',
    ]),
    // temporary for troubleshooting in live environments
    tempDebugLog(what, infoStr) {
      const msg = (infoStr || '').toString();
      this.appendRecentLog(['ReAuth', `${what}: ${msg}`]);
    },
    ...mapActions('auth/token', [
      'loadAuthTypes',
      'restartUserDeviceOtp',
    ]),
    ...mapActions([
      'flashWarn',
    ]),
    loadCredIdStr() {
      this.credentialIdStr = localStorage.getItem('webAuthN_credentialId') || '';
    },
    loadDeviceOtp() {
      this.deviceOtp = localStorage.getItem('deviceOtp') || '';
    },
    loadLastPolledUser() {
      const prevUser = localStorage.getItem('reauth.lastUser') || '';
      if ((prevUser || '').length > 0) {
        this.username = prevUser;
        setTimeout(() => {
          this.usernameChanged();
        }, 500);
      }
    },
    async startUserDeviceOtpPolling() {
      const startedPolling = Date.now();
      this.tearDownUserDeviceOtpPolling();
      if (this.username && this.deviceOtp) {
        localStorage.setItem('reauth.lastUser', this.username);
        await this.restartUserDeviceOtp(this.userDeviceOtp);
        this.remoteAuthPolling = setInterval(
          async () => {
            // check if someone signed in on our behalf
            const tokenContext = await this.$store.dispatch('auth/token/genToken', {
              type: 'remoteauth',
              username: this.username,
              userDeviceOtp: this.userDeviceOtp,
            });
            let status = 'failure';
            if (tokenContext && tokenContext.data && tokenContext.data.status) {
              status = tokenContext.data.status;
            }
            if (status === 'pending') {
              // keep waiting, do nothing
              if (startedPolling < Date.now() - 300000) { // 5 minutes
                this.flashWarn([
                  'You have been waiting for remote auth for a few minutes, ',
                  'so we turned it off. You can re-enter your username if you\'d like.',
                ].join(' '));
                this.username = '';
              }
            } else if (status === 'approved') {
              // got a token! we'll start using it due to the genToken call. Just stop polling.
              this.tearDownUserDeviceOtpPolling();
            } else {
              this.tempDebugLog('Unexpected token status. Stopping polling.', status);
              this.tearDownUserDeviceOtpPolling();
            }
          },
          2600,
        );
      } else {
        this.tearDownUserDeviceOtpPolling();
      }
    },
    tearDownUserDeviceOtpPolling() {
      if (this.remoteAuthPolling) {
        clearInterval(this.remoteAuthPolling);
      }
    },
    async usernameChanged() {
      this.loadCredIdStr();
      // cancel pending call
      clearTimeout(this.delayTimerId);

      // delay new call
      this.delayTimerId = setTimeout(async () => {
        this.$v.$touch();
        if (!this.$v.$invalid && this.canWebAuthN) {
          const resp = await clients.auth.getWebAuthNRegStatus(this.username || '');
          if (resp && resp.data && resp.data.status === 'registered') {
            // can use webauthn
            this.registeredWebAuthN = (this.credentialIdStr !== '');

            // start polling the OTP so that we can approve remote, if desired
            this.startUserDeviceOtpPolling();
          } else {
            this.registeredWebAuthN = false;
            this.preferPassword = false;
            this.tearDownUserDeviceOtpPolling();
          }
        } else {
          this.registeredWebAuthN = false;
          this.tearDownUserDeviceOtpPolling();
        }
      }, 250);
    },
    userpassAuth(inputs) {
      this.$store.dispatch('auth/token/genToken', {
        type: 'userpass',
        username: this.username,
        password: inputs.password,
      });
    },
    async webauthnAuth() { // inputs
      this.loadCredIdStr();
      localStorage.setItem('reauth.lastUser', this.username);
      const {
        challengeArray,
      } = await getChallengeByUsername(this.username);
      const publicKeyCredentialRequestOptions = {
        challenge: challengeArray,
        allowCredentials: [{
          id: base64StrToArray(this.credentialIdStr),
          type: 'public-key',
          // transports: ['usb', 'ble', 'nfc'],
        }],
        timeout: 60000,
        userVerification: 'preferred',
      };
      this.tempDebugLog('publicKeyCredentialRequestOptions', JSON.stringify(publicKeyCredentialRequestOptions));

      const assertion = await navigator.credentials.get({
        publicKey: publicKeyCredentialRequestOptions,
      });
      if (assertion && assertion.response) {
        this.tempDebugLog('ReAuth', 'assertion response');
      } else {
        this.tempDebugLog('ReAuth', 'no assertion or response');
      }
      const authAssertResp = {
        authenticatorData: await base64FromArrayBuffer(assertion.response.authenticatorData),
        clientDataJSON: await base64FromArrayBuffer(assertion.response.clientDataJSON),
        signature: await base64FromArrayBuffer(assertion.response.signature),
        userHandle: await base64FromArrayBuffer(assertion.response.userHandle),
        id: this.credentialIdStr,
      };
      this.tempDebugLog('authAssertResp', JSON.stringify(authAssertResp));

      this.$store.dispatch('auth/token/genToken', {
        type: 'webauthn',
        username: this.username,
        authAssertResp,
      });
    },
    ofcourseistillauthyou() {
      this.$store.dispatch('auth/token/genToken', {
        type: 'ofcourseistillauthyou',
        username: 'dbg', // made-up 'debug' user
        roles: authRoles.SuperAdmin, // assign full privilege
      });
    },
    failAuth() {
      this.$store.dispatch('auth/token/rejectAuth', 'User did not authenticate');
    },
  },
};
</script>
