import { glDailyTransaction } from './transaction/index';
import { glReportHeadersTotalsTransaction } from './reportHeadingsTotals/index';
import { glCollateralBankTransaction } from './collateralBank/index';

export const glDaily = {
  namespaced: true,
  modules: {
    glDailyTransaction,
    glReportHeadersTotalsTransaction,
    glCollateralBankTransaction,
  },
};

export default glDaily;
