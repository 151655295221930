import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';

export const AuditRoutes = [
  {
    path: '/common/audits',
    name: 'Audit Log',
    component: () => import('../views/common/AuditLog.vue'),
  },
];

AuditRoutes.forEach((element, index) => {
  AuditRoutes[index].beforeEnter = async (to, from, next) => {
    await accessCriteria(authRoles.AuditUsers, next);
  };
});

export default AuditRoutes;
