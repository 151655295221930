import gql from 'graphql-tag';
import { clients } from '../../util/clients';

const { backend } = clients.direct;

export const MigrationManager = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async migrateData({ dispatch, commit }, { migrationName, direction, params = {} }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query migrateData($migrationName: String!, $direction: String!, $params: JSONObject) {
            migrateData(migrationName: $migrationName, direction: $direction, params: $params) { status }
          }`,
          variables: { migrationName, direction, params },
          fetchPolicy: 'no-cache',
        });
        dispatch('flashSuccess', 'Data got migrated successfully.', { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'While migrating data something went wrong.', { root: true });
      }
      return true;
    },
  },
};

export default MigrationManager;
