import {
  DepartmentActions,
  WhatActions,
  WhomActions,
} from './browse.actions';

import {
  InterestApportionmentActions,
} from './utils.actions';

import {
  TransactionActions,
} from './transaction.actions';

const defaultAutoFocus = {
  isDepartment: false,
  isAmount: true,
  isToWhom: false,
};

export const OD = {
  namespaced: true,
  state: () => ({
    criteria: undefined,
    headers: undefined,
    transactions: undefined,
    departments: undefined,
    whats: undefined,
    whoms: undefined,
    nextVoucherNumber: undefined,
    nextRegistrationNumber: undefined,
    deptBalanceBefore: 0.0,
    newEditDialog: false, // todo - rename with 'Trans'
    newEditItem: undefined, // todo - rename with 'Trans'
    newEditIndex: undefined, // todo - rename with 'Trans'
    voucherRegPolling: undefined, // setInterval / clearInterval timer
    voucherRegPulse: false, // animation related
    selectedrecords: [],
    odStatsFY: [],
    currentTransactionType: undefined,
    registerRange: {},
    interestApportionment: {},
    visitedTransaction: undefined,
    preWhatStr: undefined,
    preWhomStr: undefined,
    autoFocus: defaultAutoFocus,
  }),
  mutations: {
    setVoucherRegPulse(state, shouldPulse) {
      state.voucherRegPulse = shouldPulse;
    },
    setVoucherRegPolling(state, timer) {
      if (state.voucherRegPolling) {
        clearInterval(state.voucherRegPolling);
      }
      state.voucherRegPolling = timer;
    },
    setselectedrecords(state, payload) {
      state.selectedrecords = payload;
    },
    setSortBy(state, payload) {
      state.criteria.sortBy = payload;
    },
    setCriteria(state, criteria, merge = 1) {
      if (merge) {
        state.criteria = {
          ...state.criteria,
          ...criteria,
        };
      } else {
        state.criteria = criteria;
      }
    },
    setHeaders(state, headers) {
      state.headers = headers;
    },
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
    setDepartments(state, departments) {
      state.departments = departments.map((dept) => ({
        ...dept,
        caption: `${dept.dept} ${dept.description}`,
      }));
    },
    setWhats(state, whats) {
      state.whats = whats;
    },
    setWhoms(state, whoms) {
      state.whoms = whoms;
    },
    setFyRanges(state, odStatsFY) {
      state.odStatsFY = odStatsFY;
    },
    setStats(state, results) {
      if (results.odstatsmaxvoucher && results.odstatsmaxvoucher.max !== undefined) {
        // reduces flickering in Safari
        const nextVoucherNumber = results.odstatsmaxvoucher.max + 1;
        if (state.nextVoucherNumber !== nextVoucherNumber) {
          state.nextVoucherNumber = nextVoucherNumber;
        }
      }
      if (results.odstatsmaxregistration && results.odstatsmaxregistration.max !== undefined) {
        state.nextRegistrationNumber = results.odstatsmaxregistration.max + 1;
      }
      if (results.odfydeptcache && (results.odfydeptcache.balance !== undefined)) {
        state.deptBalanceBefore = results.odfydeptcache.balance;
      }
    },
    setNewEditDialog(state, dialog) {
      state.newEditDialog = dialog;
    },
    setNewEditItem(state, item) {
      state.newEditItem = item;
    },
    setNewEditIndex(state, index) {
      state.newEditIndex = index;
    },
    setTransactionType(state, type) {
      state.currentTransactionType = type;
    },
    setVisitiedTransaction(state, id) {
      state.visitedTransaction = id;
    },
    setDefaultStats(state) {
      state.nextVoucherNumber = undefined;
      state.nextRegistrationNumber = undefined;
    },
    setRegisterRange(state, registerRange) {
      state.registerRange = registerRange;
    },
    setDefaultInterestApportionment(state) {
      state.interestApportionment = {};
    },
    setInterestApportionment(state, interestApportionment) {
      state.interestApportionment = interestApportionment;
    },
    setPreWhatStr(state, whatStr) {
      state.preWhatStr = whatStr;
    },
    setPreWhomStr(state, whomStr) {
      state.preWhomStr = whomStr;
    },
    setAutoFocus(state, autoFocus) {
      state.autoFocus = autoFocus || defaultAutoFocus;
    },
    updateOdLocalFileStatus(state, { objectId, status }) {
      const result = state.transactions.find((object) => object.id === objectId);
      result.hasFiles = status;
    },
  },
  actions: {
    ...DepartmentActions,
    ...WhatActions,
    ...WhomActions,
    ...TransactionActions,
    ...InterestApportionmentActions,
  },
};

export default OD;
