import gql from 'graphql-tag';
import { calculateFiscalYears } from '../../util/shared/tmc-global';
import { clients } from '../../util/clients';
import { amountToNumber, mergeByIdNewAtTop } from '../../util/shared/vue-global';

const { backend } = clients.direct;

const transactionGridColumns = `id, type, registration,
    register, fiscalYear,
    department,
    departmentDetail {
      dept, description
    },
    whatStr, whomStr, voucher, finalized, hasPrinted, hasFiles, depositAddress, wkst,
    voucherAddress { address1, address2, city, state, zip },
    depositType, amount, status, remarks,
    cashCheckItems { description, amount, drawnBy },
    creditItems { cardType, amount, isCleared },
    EFTItems { EFTType, amount },
    trustItems { taxId, taxYear, description, amount, paymentType, isPaid }`;

const errorList = {
  'index: fiscalYear_1_type_1_registration_1 dup key': 'The entered registration number already exists!',
};

const customError = ({ commit, dispatch }, e, results) => {
  const errorToString = e.toString();
  const errList = Object.keys(errorList);
  const matchedError = errList.find((err) => errorToString.includes(err));
  if (matchedError) {
    dispatch('flashError', errorList[matchedError], { root: true });
    return false;
  }
  dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
  dispatch('flashError', 'There was a problem saving that transaction.', { root: true });
  return false;
};

export const TransactionActions = {
  async searchTransactions({ commit, dispatch, state }, params = {}) {
    let results;
    try {
      commit('setGridLoading', {
        loading: true,
        loadingText: 'Searching...',
      }, { root: true });
      await dispatch('reAuth', null, { root: true });
      const queryData = (Object.keys(params).length === 0) ? state.criteria : params;
      // console.log(`criteria: ${JSON.stringify(state.criteria, null, 1)}`);
      results = await backend.query({
        query: gql`query searchTransactions($departments: [ID], $depositType: [ODDepositEnum], $types: [ODTransType], $wildcard: String,
          $fromDate: Date, $toDate: Date, $fromFinalizedDate: Date, $toFinalizedDate: Date, $status: ODStatus, $wkst: String,
          $fiscalYear: Int, $skip: Int, $limit: Int, $sortBy: [SortBy], $hasPrinted: Int) {
          odtransactions(departments: $departments, depositType: $depositType, types: $types, wildcard: $wildcard,
            fromDate: $fromDate, toDate: $toDate, fromFinalizedDate: $fromFinalizedDate, toFinalizedDate: $toFinalizedDate,
            fiscalYear: $fiscalYear, status: $status, skip: $skip, limit: $limit, sortBy: $sortBy, hasPrinted: $hasPrinted, wkst: $wkst) {
            ${transactionGridColumns}
          }
        }`,
        variables: queryData,
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (Object.keys(params).length === 0 && results.data && results.data.odtransactions) {
        commit('setTransactions', results.data.odtransactions);
      }
      return results.data.odtransactions;
    } catch (e) {
      dispatch('flashError', 'There was a problem loading deposits or vouchers.', { root: true });
      return [];
    } finally {
      commit('setGridLoading', { loading: false }, { root: true });
    }
  },
  async upsTransaction({ commit, state, dispatch }, { transaction }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      const updates = transaction;
      // editing something existing (manually in the odtrans UI)
      if (updates.id && state.transactions && state.transactions.length > 0) {
        const existingTrans = state.transactions.filter((t) => t.id === updates.id);
        if (existingTrans.length > 0) {
          // did it change?
          if (JSON.stringify(existingTrans[0]) === JSON.stringify(updates)) {
            // TODO - remove once this feature is stable
            console.info('no need to save...');
            return true; // no need to update, so exit early
          }
        }
      }
      // TODO - remove once this feature is stable
      console.info('saving...');
      // TODO - does this logic belong here, or at another layer?
      // computed resolvers are not defined on the input type
      delete updates.departmentDetail;
      delete updates.hasFiles;
      if (updates.amount) {
        updates.amount = amountToNumber(updates.amount);
      }
      const isUnregisteredOrDeposit = updates.type === 'DEPOSIT'
        || updates.type === 'UNREGISTERED';

      // null is not a valid ENUM
      if (!isUnregisteredOrDeposit || updates.depositType === null) {
        delete updates.depositType; // deposit types should only be stored on deposits
      } else if (updates.type !== 'VOUCHER') {
        delete updates.voucher;
        delete updates.whatStr;
        delete updates.whomStr;
        delete updates.voucherAddress;
      }
      results = await backend.mutate({
        mutation: gql`mutation upsertODTransaction ($transaction: ODTransactionInput!)  {
          upsertODTransaction(upsert: $transaction) {
            ${transactionGridColumns}
          }
        }`,
        variables: { transaction: updates },
      });
      clients.handleGQLErrors(results, commit);

      const newTran = results.data.upsertODTransaction;
      const newList = mergeByIdNewAtTop(state.transactions || [], newTran);
      commit('setTransactions', newList);
      commit('setNewEditItem', { ...newTran });

      return true;
    } catch (e) {
      customError({ commit, dispatch }, e, results);
      return false;
    }
  },
  async loadTransaction({ commit, state, dispatch }, transaction) {
    dispatch('files/loadAttachedFiles', {
      bucketName: 'OD',
      program: 'OD',
      parentObjectType: 'ODTransaction',
      parentObjectId: transaction.id,
    }, { root: true });
    dispatch('loadDeptBalance', transaction);
    const criteria = {
      fiscalYear: transaction.fiscalYear,
    };
    dispatch('loadWhoms', criteria);
    dispatch('loadWhats', criteria);
    commit('setVisitiedTransaction', transaction.id);
    commit('setNewEditIndex', state.transactions.indexOf(transaction));
    commit('setNewEditItem', { ...transaction });
  },
  async delTransaction({ commit, state, dispatch }, transaction) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation deleteODTransaction ($id: ID!)  {
          deleteODTransaction(id: $id) {
            id
          }
        }`,
        variables: {
          id: transaction.id,
        },
      });
      clients.handleGQLErrors(results, commit);
      const delId = results.data.deleteODTransaction.id;
      const newTrans = state.transactions.filter((t) => t.id !== delId);
      commit('setTransactions', newTrans);
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem deleting the department.', { root: true });
    }
  },
  async loadTransStats({ commit, dispatch }, { type, department, fiscalYear }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      let gqlDatatype = '$type: ODTransType!, $fiscalYear: Int ';
      let gqlQuery = 'odstatsmaxregistration (type: $type, fiscalYear: $fiscalYear ) { max }';
      if (department) {
        gqlDatatype += ', $department: ID!';
        gqlQuery += 'odstatsmaxvoucher (department: $department, fiscalYear: $fiscalYear) { max }';
      }
      results = await backend.query({
        query: gql`query loadTransStats (${gqlDatatype}) {
          ${gqlQuery}
        }`,
        variables: {
          type,
          department,
          fiscalYear,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data) {
        commit('setStats', results.data);
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem determining voucher/registration numbers.', { root: true });
    }
  },
  async loadVoucherStats({ commit, dispatch }, { department, fiscalYear }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql`query loadTransStats ($department: ID!, $fiscalYear: Int!) {
          odstatsmaxvoucher (department: $department, fiscalYear: $fiscalYear) { max }
        }`,
        variables: { department, fiscalYear },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.odstatsmaxvoucher) {
        commit('setStats', results.data);
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem determining the last voucher number.', { root: true });
    }
  },
  async loadDeptBalance({ commit, dispatch }, { department, fiscalYear }) {
    let results;
    try {
      // if we have selected/input enough to calculate the balance..
      if (!department || !fiscalYear || fiscalYear === 0) {
        return;
      }
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql`query loadDeptBalance ($department: ID!, $fiscalYear: Int!) {
          odfydeptcache(department: $department, fiscal: $fiscalYear)
          {
            balance
          }
        }`,
        variables: {
          department,
          fiscalYear,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data && results.data.odfydeptcache) {
        commit('setStats', results.data);
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem determining the department FY balance.', { root: true });
    }
  },
  async loadFiscalYears({ commit, dispatch }, allowAll = true) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql`{
          odStatsFYRange { max, min }
        }`,
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data) {
        const { odStatsFYRange } = results.data;
        const odStatsFY = calculateFiscalYears(odStatsFYRange, allowAll);
        commit('setFyRanges', odStatsFY);
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem getting min/max date ranges.', { root: true });
    }
  },
  async loadRegisterRange({ commit, dispatch }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql`{
          odstatsregisterrange { max, min },
        }`,
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      if (results.data) {
        commit('setRegisterRange', results.data.odstatsregisterrange);
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem getting min/max date ranges.', { root: true });
    }
  },
  async loadWkstList({ commit, dispatch }, { fiscalYear }) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql`query odStatsWkstList ($fiscalYear: Int!) {
          odStatsWkstList(fiscalYear: $fiscalYear)
          {
            _id
            wkstInt
          }
        }`,
        variables: {
          fiscalYear,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results, commit);
      return results.data && results.data.odStatsWkstList;
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem getting min/max date ranges.', { root: true });
    }
    return [];
  },
};

export default TransactionActions;
