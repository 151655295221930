import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';

export const GlRoutes = [
  {
    path: '/gl',
    name: 'GeneralLedger',
    component: () => import('../views/GeneralLedger.vue'),
    children: [
      {
        path: 'transactions',
        name: 'General Ledger - Transactions',
        component: () => import('../views/Gl/Transactions.vue'),
      },
      {
        path: 'browse',
        name: 'General Ledger - Browse',
        component: () => import('../views/Gl/browse/browse.vue'),
        children: [
          {
            path: 'headings',
            name: 'General Ledger - Headings & Totals',
            component: () => import('../views/Gl/browse/ReportHeadingsTotals.vue'),
          },
          {
            path: 'collateralBank',
            name: 'General Ledger - Collateral Bank',
            component: () => import('../views/Gl/browse/CollateralBank.vue'),
          },
        ],
      },
    ],
  },
];

GlRoutes.forEach((element, index) => {
  GlRoutes[index].beforeEnter = async (to, from, next) => {
    await accessCriteria(authRoles.GlRead, next);
  };
});

export default GlRoutes;
