import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';

export const SharedRoutes = [
  {
    path: '/shared',
    name: 'Shared Program',
    component: () => import('../views/SharedProgram.vue'),
    children: [
      {
        path: 'banks',
        name: 'Shared Program - Banks',
        component: () => import('../views/shared/Banks.vue'),
      },
      {
        path: 'funds',
        name: 'Shared Program - Funds',
        component: () => import('../views/shared/Funds.vue'),
      },
      {
        path: 'source',
        name: 'Shared Program - Source',
        component: () => import('../views/shared/Source.vue'),
      },
      {
        path: 'taxFund',
        name: 'Shared Program - Tax Fund',
        component: () => import('../views/shared/taxFund.vue'),
      },
      {
        path: 'taxUnit',
        name: 'Shared Program - Tax Unit',
        component: () => import('../views/shared/taxUnit.vue'),
      },
      {
        path: 'taxDistrict',
        name: 'Shared Program - Tax District',
        component: () => import('../views/shared/taxDistrict.vue'),
      },
      {
        path: 'taxLevy',
        name: 'Shared Program - Tax Levy',
        component: () => import('../views/shared/taxLevy.vue'),
      },
    ],
  },
];

SharedRoutes.forEach((element, index) => {
  SharedRoutes[index].beforeEnter = async (to, from, next) => {
    await accessCriteria(authRoles.shared.SharedProgram, next);
  };
});

export default SharedRoutes;
