// until we have a more robust need for JWT parsing, this will suffice

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
// parse a JWT token into decoded JSON, without a library, without verification
export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decoder = (c) => {
    const cVal = c.charCodeAt(0).toString(16);
    const sliceCode = (`00${cVal}`);
    const sliceMin2 = sliceCode.slice(-2);
    return `%${sliceMin2}`;
  };
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(decoder).join(''));

  return JSON.parse(jsonPayload);
};

// convert iat or exp numbers to a proper Date object
export const parseJwtDate = (num) => new Date(num * 1000);

export const getRawJwt = () => localStorage.getItem('auth.jwt');
