const authRoles = {
  SuperAdmin: ['TmcSupport'],
  UserAdmins: ['UserAdministration'],
  AuditUsers: ['AuditUser'],
  SettingsUsers: ['SettingsUser'],
  BankProfileAdmins: ['BankProfileAdministration'],
  ImportExportAdmins: ['ImportExportAdministration'],
  CommonUsers: ['CommonUser'],
  WarEdit: [
    'WarAdministration',
    'WarUser',
  ],
  WarRead: [
    'WarAdministration',
    'WarUser',
    'ReadOnly',
  ],
  OD: [
    'ODAdministration',
    'ODUser',
  ],
  ODRead: [
    'ODAdministration',
    'ODUser',
    'ReadOnly',
  ],
  MiscAuth: [
    'MiscAdministration',
    'MiscUser',
  ],
  MiscRead: [
    'MiscAdministration',
    'MiscUser',
    'ReadOnly',
  ],
  MtgAuth: [
    'MtgAdministration',
    'MtgUser',
  ],
  MtgRead: [
    'MtgAdministration',
    'MtgUser',
    'ReadOnly',
  ],
  GlAuth: [
    'GLAdministration',
    'GLUser',
  ],
  GlRead: [
    'GLAdministration',
    'GLUser',
    'ReadOnly',
  ],
};

authRoles.shared = {
  BankAuth: [
    ...authRoles.WarEdit,
    ...authRoles.OD,
    ...authRoles.MiscAuth,
    ...authRoles.MtgAuth,
    ...authRoles.GlAuth,
  ],
  SharedProgram: [
    ...authRoles.WarRead,
    ...authRoles.ODRead,
    ...authRoles.MiscRead,
    ...authRoles.MtgRead,
    ...authRoles.GlRead,
  ],
  BankRead: [
    ...authRoles.WarRead,
    ...authRoles.ODRead,
    ...authRoles.MiscRead,
    ...authRoles.MtgRead,
    ...authRoles.GlRead,
  ],
};

export { authRoles };

export default { authRoles };
