import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../util/shared/vue-global';

const { backend } = clients.direct;

const defaultDeptFields = {
  _id: '',
  dept: '',
  description: '',
  govFunction: '',
  resources: '',
};

export const department = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async loadDepartments({ commit, dispatch }, {
      criteria, fields, baseCrudKey, postProcessDeparments,
    }) {
      let results;
      try {
        const sortingDir = (criteria.sortBy || [])[0] ? criteria.sortBy[0].direction : 'asc';
        const gqlfields = fields || defaultDeptFields;
        await dispatch('reAuth', null, { root: true });
        const cleanedCriteria = { ...criteria };
        results = await backend.query({
          query: gql` query departments($criteria: DepartmentCriteriaType) {
                  departments(criteria: $criteria) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: { criteria: cleanedCriteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.departments) {
          let departments = postProcessDeparments
            ? postProcessDeparments(results.data.departments) : results.data.departments;
          if (sortingDir === 'desc') {
            departments = departments.sort((a, b) => b.dept - a.dept);
          } else {
            departments = departments.sort((a, b) => a.dept - b.dept);
          }
          commit('base/crud/setItems', [baseCrudKey, departments], { root: true });
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the departments list.', { root: true });
      }
    },
    async upsrtDepartment({ dispatch, rootGetters, commit }, {
      item, fields,
      baseCrudKey, postProcessDeparments,
    }) {
      let results;
      const transaction = { ...item };
      const gqlfields = fields || defaultDeptFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertDepartment ($transaction: DepartmentInputType!)  {
            upsertDepartment(upsert: $transaction) {
              ${fieldsToGqlString(gqlfields)},
            }
          }`,
          variables: {
            transaction,
          },
        });
        clients.handleGQLErrors(results, commit);
        const departmentsStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newDept = results.data.upsertDepartment;
        const newList = mergeByIdNewAtTop(departmentsStateData || [], newDept);
        const departments = postProcessDeparments
          ? postProcessDeparments(newList) : newList;
        commit('base/crud/setItems', [baseCrudKey, departments], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Department', { root: true });
      }
    },
  },
};

export default department;
