import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { amountToNumber, mergeByIdNewAtTop } from '../../../util/shared/vue-global';
import { programToModel, warStatsBalanceParams } from '../../../components/War/Transaction/warTransaction.util';

const { backend } = clients.direct;
const changeTransDetails = (transaction) => {
  const dupTrans = JSON.parse(JSON.stringify(transaction));
  if (Object.prototype.toString.call(dupTrans) === '[object Object]') {
    if (Array.isArray(dupTrans.details)) {
      dupTrans.details = dupTrans.details.map((detail) => {
        const dupDetails = { ...detail };
        dupDetails.fund = detail.fund && detail.fund._id;
        dupDetails.department = detail.department && detail.department._id;
        dupDetails.account = detail.account && detail.account._id;
        return dupDetails;
      });
    }
  }
  return dupTrans;
};

const isTransactionUpdated = ({ rootGetters }, { item, baseCrudKey }) => {
  const cleanedItem = { ...item };
  const warTransState = rootGetters['base/crud/items'](baseCrudKey);
  if (cleanedItem._id && warTransState && warTransState.length > 0) {
    let existingTrans = warTransState.find((t) => t._id === cleanedItem._id);
    if (existingTrans) {
      delete existingTrans.hasFiles;
      existingTrans = changeTransDetails(existingTrans);
      if (existingTrans && cleanedItem.amount) {
        cleanedItem.amount = amountToNumber(cleanedItem.amount);
        if ((JSON.stringify(existingTrans) === JSON.stringify(cleanedItem))) {
          console.info('no need to save...');
          return true;
        }
      }
    }
  }
  return false;
};

export const transaction = {
  namespaced: true,
  state: () => ({
    preWhomStr: undefined,
    headers: undefined,
    regYears: {},
    nextWarrantNumber: undefined,
    nextPaymentNumber: undefined,
    warrantRegPulse: false,
    paymentRegPulse: false, // animation related
    warrantRegPolling: undefined, // setInterval / clearInterval timer
    selectedItems: [],
    fundListByFy: [],
    registerDateRange: undefined,
    warFundBalance: undefined,
    warAccountBalance: undefined,
  }),
  mutations: {
    setPreWhomStr(state, whomStr) {
      state.preWhomStr = whomStr;
    },
    setHeaders(state, headers) {
      state.headers = headers;
    },
    setFiscalRegYears(state, regYears) {
      state.regYears = regYears;
    },
    setStats(state, results) {
      if (results.warStatsMaxWarrant && results.warStatsMaxWarrant.max !== undefined) {
        // reduces flickering in Safari
        const nextWarrantNumber = results.warStatsMaxWarrant.max + 1;
        if (state.nextWarrantNumber !== nextWarrantNumber) {
          state.nextWarrantNumber = nextWarrantNumber;
        }
      }
      if (results.warStatsMaxPayment && results.warStatsMaxPayment.max !== undefined) {
        const nextPaymentNumber = results.warStatsMaxPayment.max + 1;
        if (state.nextPaymentNumber !== nextPaymentNumber) {
          state.nextPaymentNumber = nextPaymentNumber;
        }
      }
    },
    setWarrantRegPulse(state, shouldPulse) {
      state.warrantRegPulse = shouldPulse;
    },
    setPaymentRegPulse(state, shouldPulse) {
      state.paymentRegPulse = shouldPulse;
    },
    setWarrantRegPolling(state, timer) {
      if (state.warrantRegPolling) {
        clearInterval(state.warrantRegPolling);
      }
      state.warrantRegPolling = timer;
    },
    setDefaultStats(state) {
      state.nextWarrantNumber = undefined;
      state.nextPaymentNumber = undefined;
    },
    setSelectedItems(state, items) {
      state.selectedItems = items;
    },
    updateWarLocalFileStatus(state, { objectId, status }) {
      const result = this.getters['base/crud/items']('warTransaction').find(
        (object) => object._id === objectId,
      );
      result.hasFiles = status;
    },
    setFundListByFy(state, items) {
      state.fundListByFy = items;
    },
    setRegisterDateRange(state, registerDateRange) {
      state.registerDateRange = registerDateRange;
    },
    setStatsBalance(state, { result, params = {} }) {
      const { balance } = (result || {}).warStatsBalance || {};
      if (params.balanceType === warStatsBalanceParams().fundBalance) {
        state.warFundBalance = balance;
      } else if (params.balanceType === warStatsBalanceParams().accountBalance) {
        state.warAccountBalance = balance;
      }
    },
  },
  actions: {
    async loadTransactionList({ commit, dispatch }, { criteria, baseCrudKey, programKey }) {
      let results;
      const updatedCriteria = { ...criteria };
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query warTransactions($criteria: TransactionCriteriaType, $program:  String) {
            warTransactions(criteria: $criteria, program:  $program) {
              _id, fiscalYear, type, warrant, registerDate, whomStr, issueDate, amount, cancelledAmount, isEftCode,
              isNonPayable, payment, paidCancelledDate, status, clearingFundNumber, purchaseOrder,hasFiles, details{ fund {_id, fund, type}
              department{ _id, dept}
              account{ _id, account},account{ _id, account}, amount }
                  }
                }`,
          variables: {
            criteria: updatedCriteria,
            program: programToModel(baseCrudKey || programKey),
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (baseCrudKey && results.data && results.data.warTransactions) {
          const warTransactions = results.data ? results.data.warTransactions || [] : [];
          commit('base/crud/setItems', [baseCrudKey, warTransactions], { root: true });
        }
        return results.data.warTransactions;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Warrants Appropiation list.', { root: true });
        return [];
      }
    },
    async delWarTransaction({ commit, rootGetters, dispatch }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation deleteWarTransaction ($id: ID!, $program:  String)  {
            deleteWarTransaction(id: $id, program:  $program) {
                  _id
                }
              }`,
          variables: {
            id: item._id,
            program: programToModel(baseCrudKey),
          },
        });
        clients.handleGQLErrors(results, commit);
        const delId = results.data.deleteWarTransaction._id;
        const warTransData = rootGetters['base/crud/items'](baseCrudKey);
        const updatedData = warTransData.filter((d) => d._id !== delId);
        commit('base/crud/setItems', [baseCrudKey, updatedData], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem deleting the Transaction', { root: true });
      }
    },
    async upsrtTransaction({ dispatch, rootGetters, commit }, { item, baseCrudKey, programKey }) {
      let results;
      const cleanedItem = { ...item };
      if (baseCrudKey && isTransactionUpdated({ rootGetters }, { item, baseCrudKey })) {
        return true;
      }
      if (cleanedItem.amount) {
        cleanedItem.amount = amountToNumber(cleanedItem.amount);
      }
      if (cleanedItem.fund) {
        delete cleanedItem.fund;
      }
      if (cleanedItem.account) {
        delete cleanedItem.account;
      }
      if (cleanedItem.department) {
        delete cleanedItem.department;
      }
      console.info('saving...');
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertWarTransaction ($item: TransactionInputType!, $program:  String)  {
            upsertWarTransaction(upsert: $item, program:  $program) {
              _id, fiscalYear, type, warrant, registerDate, whomStr, issueDate, amount, cancelledAmount, isEftCode,
              isNonPayable, payment, paidCancelledDate, status, clearingFundNumber, purchaseOrder, hasFiles,
              details{
                fund {_id, fund, type},
                department{ _id, dept},
                account{ _id, account},
                amount
              }
            }
          }`,
          variables: {
            item: cleanedItem,
            program: programToModel(baseCrudKey || programKey),
          },
        });
        clients.handleGQLErrors(results, commit);
        const newTransaction = results.data.upsertWarTransaction;
        if (baseCrudKey) {
          const transactionStateData = rootGetters['base/crud/items'](baseCrudKey);
          const newList = mergeByIdNewAtTop(transactionStateData || [], newTransaction);
          commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
        }
        return newTransaction;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Warrant Appropiation Transaction', { root: true });
      }
      return {};
    },
    async loadFiscalRegYears({ commit, dispatch }, { baseCrudKey }) {
      let results;
      let fiscalYearRegYears = {
        min: null,
        max: null,
      };
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query warStatsFYRange ($program:  String) {
            warStatsFYRange (program:  $program) { min, max }
          }`,
          variables: {
            program: programToModel(baseCrudKey),
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          fiscalYearRegYears = results.data.warStatsFYRange;
          commit('setFiscalRegYears', results.data.warStatsFYRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
      return fiscalYearRegYears;
    },
    async loadLastWarrantNo({ commit, dispatch }, { fund, fiscalYear, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query warStatsMaxWarrant ($criteria: warStatsCriteriaType, $program:  String) {
            warStatsMaxWarrant (criteria: $criteria, program:  $program) { max }
          }`,
          variables: {
            criteria: { fund, fiscalYear },
            program: programToModel(baseCrudKey),
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        commit('setStats', results.data);
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem determining the last warrant number.', { root: true });
      }
    },
    async loadLastPaymentNo({ commit, dispatch }, { fund, fiscalYear, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query warStatsMaxPayment ($criteria: warStatsCriteriaType, $program:  String) {
            warStatsMaxPayment (criteria: $criteria, program:  $program) { max }
          }`,
          variables: {
            criteria: { fund, fiscalYear },
            program: programToModel(baseCrudKey),
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        commit('setStats', results.data);
        return results.data;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem determining the last payment number.', { root: true });
      }
      return {};
    },
    // Todo - update this api according to warStatsFundBalance & warStatsAccountBalance
    async loadFYBalance({ commit, dispatch },
      requestParams) {
      let results = {};
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query warFyBalance ($fund: ID!, $fiscalYear: Int!, $account: ID, $deaprtment: ID) {
            warFyBalance (fund: $fund, fiscalYear: $fiscalYear, account: $account, deaprtment: $deaprtment) { fund, fiscalYear, account, deaprtment, balance }
          }`,
          variables: requestParams,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem determining the balance.', { root: true });
      }
      return results;
    },
    async loadRegisterDateRange({ commit, dispatch }, { baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query warStatsRegisterDateRange ($program: String) {
              warStatsRegisterDateRange (program: $program) { max, min },
          }`,
          variables: {
            program: programToModel(baseCrudKey),
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data && results.data.warStatsRegisterDateRange) {
          commit('setRegisterDateRange', results.data.warStatsRegisterDateRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max business date ranges.', { root: true });
      }
    },
    async warStatsBalance({ commit, dispatch }, { criteria, params = {} }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query warStatsBalance ($criteria: warCacheBalanceCriteriaType, $params: JSONObject) {
            warStatsBalance (criteria: $criteria, params: $params) {  id, fiscalYear, fund, account, department, balance }
          }`,
          variables: {
            criteria,
            params,
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        commit('setStatsBalance', { result: results.data, params });
        return results.data;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem determining the last payment number.', { root: true });
      }
      return {};
    },
    async warStatsImportSummary({ commit, dispatch }, { criteria, programKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query warStatsImportSummary ($criteria: TransactionCriteriaType, $program: String) {
            warStatsImportSummary (criteria: $criteria, program: $program) {  count, totalAmount }
          }`,
          variables: {
            criteria,
            program: programKey,
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        return results.data && results.data.warStatsImportSummary;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      }
      return {};
    },
  },
};

export default transaction;
