import gql from 'graphql-tag';
import { clients } from '../../util/clients';

const { backend } = clients.direct;

export const createNewFiscalYearManager = {
  namespaced: true,
  state: () => ({

  }),
  mutations: {
  },
  actions: {
    async createNewFiscalYear({ dispatch, commit }, {
      programName, nextFiscalYear,
      currentFiscalYear, params = {},
    }) {
      let results;
      let resultData = {};
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query createNewFiscalYear($programName: String!, $nextFiscalYear: Int!, $currentFiscalYear: Int, $params: JSONObject) {
          createNewFiscalYear(programName: $programName, nextFiscalYear: $nextFiscalYear, currentFiscalYear: $currentFiscalYear, params: $params)
           { status, componentStatusArr, programName, additionalDataGroup }
        }`,
          variables: {
            programName, nextFiscalYear, currentFiscalYear, params,
          },
          fetchPolicy: 'no-cache',
        });
        if (results.data && results.data.createNewFiscalYear) {
          resultData = results.data.createNewFiscalYear;
          dispatch('flashSuccess', resultData.status, { root: true });
          return resultData;
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'While creating New Fiscal Year data something went wrong.', { root: true });
      }
      return {};
    },
  },
};

export default createNewFiscalYearManager;
