import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { bulkResult } from '../../../util/shared/graphql-global';

const { backend } = clients.direct;

export const programDetails = {
  cmnBank:
  {
    program: 'cmnBank',
  },
  cmnFund: {
    program: 'cmnFund',
  },
};

export const getProgramDetailsByKey = (key) => {
  // programDetails[program]
  const programMapper = {
    // program baseCrudKey
    sharedBank: programDetails.cmnBank,
    sharedFund: programDetails.cmnFund,
    // shared bank/fund Key
    cmnBank: programDetails.cmnBank,
    cmnFund: programDetails.cmnFund,
  };
  return programMapper[key];
};

export const getProgramByKey = (key) => getProgramDetailsByKey(key).program;

export const renumberLines = {
  namespaced: true,
  actions: {
    async cmnRenumberLines({ commit, dispatch }, {
      criteria, baseCrudKey,
    }) {
      let results;
      const updatedCriteria = { ...criteria };
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query cmnRenumberLines($criteria: cmnRenumberLinesCriteriaType, $program:  String) {
            cmnRenumberLines(criteria: $criteria, program:  $program) {
                ${bulkResult},
              }
            }`,
          variables: {
            criteria: updatedCriteria,
            program: getProgramByKey(baseCrudKey),
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const renumberedLinesResult = results.data ? results.data.cmnRenumberLines
        || {} : {};
        return renumberedLinesResult;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem while renumbering the line numbers.', { root: true });
        return {};
      }
    },
  },
};

export default renumberLines;
