import { clients } from '../../util/clients';

const { backendRest } = clients.direct;

const commonConfig = {
  headers: {
    'Content-type': 'application/json',
  },
  timeout: 240000,
};

export const jobs = {
  namespaced: true,
  state: () => ({
    group: undefined,
    prefix: undefined,
    nsInclude: '*',
    backup: undefined,
    backups: [],
    totalSizeStr: '',
    activeDbOp: false,
    activeDbOpMsg: '',
  }),
  mutations: {
    setBackup(state, backup) {
      state.backup = backup;
    },
    setBackups(state, [files, totalSizeStr]) {
      state.backups = files;
      state.totalSizeStr = totalSizeStr;
    },
    setGroup(state, group) {
      state.group = group;
    },
    setPrefix(state, prefix) {
      state.prefix = prefix;
    },
    setNsInclude(state, nsInclude) {
      state.nsInclude = nsInclude;
    },
    setActiveDbOp(state, { isActive, msg }) {
      state.activeDbOp = isActive;
      state.activeDbOpMsg = msg;
    },
  },
  actions: {
    async listDumps({ state, commit, dispatch }) {
      try {
        await dispatch('reAuth', null, { root: true });
        const { group } = state;
        const queryParams = `?group=${group}`;
        const response = await backendRest.get(`/jobs/dumps/list${queryParams}`);
        if (response && response.data && response.data.status === 'ok') {
          const { files, totalSizeStr } = response.data;
          commit('setBackups', [files || [], totalSizeStr]);
        } else {
          dispatch('flashError', 'There was a problem listing backups.', { root: true });
        }
      } catch (e) {
        dispatch('logException', e, { root: true });
        dispatch('flashError', 'There was a problem listing backups.', { root: true });
      }
    },
    async backup({ state, commit, dispatch }) {
      try {
        const isTestCafe = localStorage.getItem('isTestCafe') || '0';
        if (isTestCafe !== '0') {
          return true; // bypass backups for TestCafe automated testing
        }
        commit('setActiveDbOp', { isActive: true, msg: 'Backing up...' });
        await dispatch('reAuth', null, { root: true });
        const { group, prefix } = state;
        const body = {
          // don't pass group, let the route name choose the group
          prefix,
        };
        const route = group === 'tmc-local-backend' ? 'backend' : 'auth';
        const response = await backendRest.post(`/jobs/dump/${route}`, body, commonConfig);
        if (response && response.data && response.data.status === 'ok') {
          dispatch('listDumps');
          commit('setActiveDbOp', { isActive: false, msg: 'Backing up...done!' });
          dispatch('flashInfo', 'Backing up...done!', { root: true });
          return true;
        }
        dispatch('flashError', 'There was a problem performing a backup.', { root: true });
        return false;
      } catch (e) {
        dispatch('logException', e, { root: true });
        dispatch('flashError', 'There was a problem performing a backup.', { root: true });
        commit('setActiveDbOp', { isActive: false, msg: 'There was a problem performing a backup.' });
        return false;
      }
    },
    async restore({ state, commit, dispatch }) {
      try {
        commit('setActiveDbOp', { isActive: true, msg: 'Restoring...' });
        await dispatch('reAuth', null, { root: true });
        const { group, backup, nsInclude } = state;
        const body = {
          // don't pass group, let the route name choose the group
          backup,
          nsInclude,
        };
        const route = group === 'tmc-local-backend' ? 'backend' : 'auth';
        const response = await backendRest.post(`/jobs/restore/${route}`, body, commonConfig);
        if (response && response.data && response.data.status === 'ok') {
          // dispatch('listDumps');
          commit('setActiveDbOp', { isActive: false, msg: 'Restoring...done!' });
        } else {
          dispatch('flashError', 'There was a problem performing a DB restore.', { root: true });
        }
      } catch (e) {
        dispatch('logException', e, { root: true });
        dispatch('flashError', 'There was a problem performing a DB restore.', { root: true });
        commit('setActiveDbOp', { isActive: false, msg: 'There was a problem performing a DB restore.' });
      }
    },
  },
  getters: {
    backupFiles(state) { // , getters
      return (state.backups || []).map((b) => b.file);
    },
  },
};

export default jobs;
