import Vue from 'vue';
import { VTextField } from 'vuetify/lib';
import VCurrencyField from 'v-currency-field';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/my-extensions';

Vue.use(VCurrencyField, {
  locale: 'en-US',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

// Google Analytics
Vue.use(VueGtag, {
  config: { id: 'G-ELFYC6PW02' },
}, router);

// as per docs, for treeshaking
Vue.component('v-text-field', VTextField);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
