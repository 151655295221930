<template>
  <v-dialog
    :style="{'z-index':zIndexWkStnDialog}"
    v-model="dialog"
    persistent max-width="30vw"
  >
    <v-card ref="inputDialogForWorkstationId">
      <v-card-title>Please Enter Workstation ID</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-2">
        <v-form ref="form">
          <v-text-field
            class="workstation-id-text-field"
            id="workstationIdTextField"
            v-model="wkst"
            :rules="wkstValidation"
            hint="please enter numbers only"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="workstation-id-submit-btn"
            id="workstationIdSubmitBtn"
            color="primary"
            text
            @click="setWkst"
          >
            Save
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { setWorkstationNumber, getWorkstationNumber } from '../../util/workstation-util';
import { checkStringEmptiness } from '../../util/shared/vue-global';
import { TMC_CONSTANTS } from '../../util/shared/constants';

export default {
  name: 'InputDialogForWorkstationId',
  data: () => ({
    wkst: undefined,
    zIndexWkStnDialog: TMC_CONSTANTS.COMPONENT_Z_INDEX.WKST_DIALOG,
  }),
  created() {
    this.checkOrInputWkst();
    this.fetchAndSetWkst({});
  },
  computed: {
    ...mapState({
      wkstDialog: (state) => state.wkstDialog,
    }),
    dialog: {
      get() {
        return this.wkstDialog;
      },
      set(value) {
        this.setWkstDialog(value);
      },
    },
    wkstValidation() {
      return [
        (value) => /^(?!0+$)\d+$/.test(value) || 'Please enter numbers > 0 only',
      ];
    },
  },
  methods: {
    ...mapMutations(['setWkstDialog']),
    ...mapActions(['flashSuccess']),
    ...mapActions('SystemConfig', [
      'fetchAndSetWkst',
    ]),
    checkOrInputWkst() {
      setWorkstationNumber(window);
      const wkstNumber = getWorkstationNumber();
      const isWkstNumeric = /^(?!0+$)\d+$/.test(wkstNumber);
      if (checkStringEmptiness(wkstNumber) || !isWkstNumeric) {
        this.dialog = true;
      }
    },
    setWkst() {
      if (this.$refs.form.validate()) {
        setWorkstationNumber(window, this.wkst);
        this.flashSuccess('Workstation ID Successfully Saved');
        this.dialog = false;
        this.fetchAndSetWkst({});
      }
    },
  },
};
</script>

<style>

</style>
