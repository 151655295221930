import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import {
  fieldsToGqlString,
  mergeByIdNewAtTop,
} from '../../../util/shared/vue-global';
import { getProgramByKey } from '../../../components/common/transactionNumber/transactionNumber.util';

const { backend } = clients.direct;
const defaultTransNumberFields = {
  _id: '',
  transactionNumber: '',
  fiscalYear: '',
  amount: '',
  paymentDetails: {
    paymentType: '',
    amount: '',
    cashReceived: '',
    cashChange: '',
    checkNumber: '',
    checkPaidBy: '',
    eftType: '',
    creditType: '',
  },
};

export const cmnTransNumber = {
  namespaced: true,
  state: () => ({
    nextTransNumber: null,
    transNoPulse: false,
    transNoPolling: undefined,
  }),
  mutations: {
    setStats(state, maxTransactionNumber) {
      if (Number.isInteger(maxTransactionNumber)) {
        state.nextTransNumber = maxTransactionNumber + 1;
      } else {
        state.nextTransNumber = null;
      }
    },
    setTransNoPulse(state, shouldPulse) {
      state.transNoPulse = shouldPulse;
    },
    setTransNoPolling(state, timer) {
      if (state.transNoPolling) {
        clearInterval(state.transNoPolling);
      }
      state.transNoPolling = timer;
    },
  },
  actions: {
    async loadCmnTransNum({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      const updatedCriteria = { ...criteria };
      const gqlfields = fields || defaultTransNumberFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query cmnPymntDetailsForTrans($criteria: cmnPymntDetailsForTransCriteriaType, $program:  String) {
            cmnPymntDetailsForTrans(criteria: $criteria, program:  $program) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: {
            criteria: updatedCriteria,
            program: getProgramByKey(baseCrudKey),
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const cmnPymntDetailsForTrans = results.data ? results.data.cmnPymntDetailsForTrans
        || [] : [];
        commit('base/crud/setItems', [baseCrudKey, cmnPymntDetailsForTrans], { root: true });
        return cmnPymntDetailsForTrans;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Payment Details for Transaction Number list.', { root: true });
      }
      return [];
    },
    async delCmnTransNum({ commit, rootGetters, dispatch }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation deleteCmnPymntDetailsForTrans($id: ID!, $program: String)  {
            deleteCmnPymntDetailsForTrans(id: $id, program: $program) {
                  _id
                }
              }`,
          variables: {
            id: item._id,
            program: getProgramByKey(baseCrudKey),
          },
        });
        clients.handleGQLErrors(results, commit);
        const delId = results.data ? (results.data.deleteCmnPymntDetailsForTrans || {})._id : null;
        const receivedStateData = rootGetters['base/crud/items'](baseCrudKey);
        const updatedData = receivedStateData.filter((d) => d._id !== delId);
        commit('base/crud/setItems', [baseCrudKey, updatedData], { root: true });
        return delId;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem deleting the Transaction Number list', { root: true });
      }
      return null;
    },
    async upsrtCmnTransNum({ dispatch, rootGetters, commit }, { item, fields, baseCrudKey }) {
      const gettingBaseCrudKey = getProgramByKey(baseCrudKey);
      let results;
      try {
        const gqlfields = fields || defaultTransNumberFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsert ($item: cmnPymntDetailsForTransInputType!, $program:  String)  {
            upsertCmnPymntDetailsForTrans(upsert: $item, program:  $program) {
                  ${fieldsToGqlString(gqlfields)},
                }
              }`,
          variables: {
            item,
            program: gettingBaseCrudKey,
          },
        });
        clients.handleGQLErrors(results, commit);
        const transNumPaymentDetailsStateData = rootGetters['base/crud/items'](gettingBaseCrudKey);
        const newTransNumPaymentDetails = results.data ? (results.data.upsertCmnPymntDetailsForTrans
        || {}) : {};
        const newList = mergeByIdNewAtTop(
          transNumPaymentDetailsStateData || [],
          newTransNumPaymentDetails,
        );
        commit('base/crud/setItems', [gettingBaseCrudKey, newList], { root: true });
        return newTransNumPaymentDetails;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Transaction Number List', { root: true });
      }
      return {};
    },
    async loadLastTransactionNo({ commit, dispatch }, { fiscalYear, baseCrudKey }) {
      const gettingBaseCrudKey = getProgramByKey(baseCrudKey);
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query cmnTransNumStatsMaxTransNum ($fiscalYear: Int!, $program:  String) {
                cmnTransNumStatsMaxTransNum (fiscalYear: $fiscalYear, program:  $program) { max }
            }`,
          variables: {
            fiscalYear,
            program: gettingBaseCrudKey,
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const maxNo = results.data ? (results.data.cmnTransNumStatsMaxTransNum || {}).max : null;
        commit('common/cmnTransNumber/setStats', maxNo, { root: true });
        return maxNo;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem determining the last transaction number.', { root: true });
      }
      return null;
    },
  },
};

export default cmnTransNumber;
