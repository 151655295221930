import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../util/shared/vue-global';

const { backend } = clients.direct;
const defaultAccountFields = {
  _id: '',
  account: '',
  longDesc: '',
  shortDesc: '',
};

export const account = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async loadAccounts({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      const gqlfields = fields || defaultAccountFields;
      try {
        const sortingDir = (criteria.sortBy || [])[0] ? criteria.sortBy[0].direction : 'asc';
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query accounts($criteria: AccountCriteriaType) {
              accounts(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        let accounts = results.data ? results.data.accounts || [] : [];
        if (sortingDir === 'desc') {
          accounts = accounts.sort((a, b) => b.account - a.account);
        } else {
          accounts = accounts.sort((a, b) => a.account - b.account);
        }
        commit('base/crud/setItems', [baseCrudKey, accounts], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the accounts list.', { root: true });
      }
    },
    async upsrtAccount({ dispatch, rootGetters, commit }, { item, fields, baseCrudKey }) {
      let results;
      try {
        const gqlfields = fields || defaultAccountFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertAccount ($item: AccountInputType!)  {
                upsertAccount(upsert: $item) {
                  ${fieldsToGqlString(gqlfields)},
                }
              }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const accountsStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newAcct = results.data.upsertAccount;
        const newList = mergeByIdNewAtTop(accountsStateData || [], newAcct);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Account', { root: true });
      }
    },
  },
};

export default account;
