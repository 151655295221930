import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';

export const CommonRoutes = [
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/common/Profile.vue'),
  },
  {
    path: '/profile/settings',
    name: 'User Settings',
    component: () => import('../components/common/profile-settings/index.vue'),
  },
  {
    path: '/profile/webauthn',
    name: 'User Passwordless Registration',
    component: () => import('../components/common/UserRegWebAuthN.vue'),
  },
  {
    path: '/dependency-list',
    name: 'Auto Created Dependencies',
    component: () => import('../components/common/ImportAutoCreatedDependencyList.vue'),
  },
];

CommonRoutes.forEach((element, index) => {
  CommonRoutes[index].beforeEnter = async (to, from, next) => {
    await accessCriteria(authRoles.CommonUsers, next);
  };
});

export default CommonRoutes;
