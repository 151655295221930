import fetch from 'cross-fetch';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core';
// importing from @apollo/client/core because of this issue:
// https://github.com/apollographql/apollo-client/issues/6650

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import axios from 'axios';
import gql from 'graphql-tag';

import errorHandlers from './error-handlers';
import { configUrls } from './client-config';
import { getRawJwt } from '../jwt';
import { getWorkstationNumber } from '../workstation-util';

const URLS = configUrls();

const httpLinkAuthFetch = createHttpLink({
  uri: URLS.AUTH_GQL,
  fetch,
});

const httpLinkBackendFetch = createHttpLink({
  uri: URLS.BACKEND_GQL,
  fetch,
});

// type name allows for local cache perf improvements
const addTypename = true;
// but we need to remove __typename from input types in GQL operations
// https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
    // eslint-disable-next-line no-param-reassign
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
  // todo - important?
  // .map((data) => {
  //   return data;
  // });
});

const linkJwtHeader = setContext((_, { headers }) => {
  // this runs on nearly every graphQL call as you go from page to page
  // thus, it should always have the latest token, even after reAuth
  const token = getRawJwt();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const logGqlErrors = onError(({ graphQLErrors, networkError }) => {
  /* eslint-disable global-require */
  // eslint-disable-next-line prefer-destructuring
  const store = require('../../store/index.js').default;
  /* eslint-enable global-require */
  if (graphQLErrors) {
    const errorMsgs = graphQLErrors.map(
      ({ message, locations, path }) => {
        if (message === '403 Forbidden') {
          store.dispatch('flashError', 'Uh-oh! Not Authorized to perform this action');
        }
        return `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      },
    );
    errorHandlers.cacheErrors(errorMsgs);
  }

  if (networkError) {
    const errorMsg = `[Network error]: ${networkError}`;
    errorHandlers.cacheErrors([errorMsg]);
  }
});

const authLink = ApolloLink.from([
  cleanTypeName,
  linkJwtHeader,
  logGqlErrors,
  httpLinkAuthFetch,
]);

const backendLink = ApolloLink.from([
  cleanTypeName,
  linkJwtHeader,
  logGqlErrors,
  httpLinkBackendFetch,
]);

const internalClients = {
  auth: new ApolloClient({
    link: authLink,
    cache: new InMemoryCache({
      addTypename,
    }),
  }),
  authRest: axios.create({
    baseURL: URLS.AUTH_REST,
  }),
  backend: new ApolloClient({
    link: backendLink,
    cache: new InMemoryCache({
      addTypename,
    }),
  }),
  backendRest: axios.create({
    baseURL: URLS.BACKEND_REST,
  }),
};

const generateJwtAuthorizationHeader = () => {
  // this also runs for every RESTful call, so should
  // be the latest token
  const token = getRawJwt();
  return token ? `Bearer ${token}` : '';
};

const interceptAxiosReq = (config) => {
  if (config && config.headers && config.headers.common) {
    // eslint-disable-next-line no-param-reassign
    config.headers.common.authorization = generateJwtAuthorizationHeader();
  }
  return config;
};

// Any status code that lie within the range of 2xx cause this function to trigger
// TODO - could log responses here, if ever needed for troubleshooting
const resp200Axios = (response) => response;

const respErrorAxios = (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error && error.response) {
    /* eslint-disable global-require */
    // eslint-disable-next-line prefer-destructuring
    const store = require('../../store/index.js').default;
    /* eslint-enable global-require */
    if (error.response.status === 403) {
      store.dispatch('flashError', 'Uh-oh! Not Authorized to perform this action.');
    } else if (error.response.status === 401) {
      store.dispatch('flashError', 'Unauthorized! Please Login again.');
    }
    const respDataStr = JSON.stringify(error.response.data || {});
    errorHandlers.cacheErrors(['Error response body', respDataStr]);
  }
  errorHandlers.cacheErrors(error);

  return Promise.reject(error);
};

internalClients.authRest.interceptors.request.use(interceptAxiosReq);
internalClients.backendRest.interceptors.request.use(interceptAxiosReq);

internalClients.authRest.interceptors.response.use(resp200Axios, respErrorAxios);
internalClients.backendRest.interceptors.response.use(resp200Axios, respErrorAxios);

export const queries = {
  auth: {
    GET_AUTHS: gql` {
      auths {
        id,
        username,
        pwdhash
      }
    }`,
  },
};

export const clients = {
  direct: internalClients,
  ...errorHandlers,
  auth: {
    getAuthUsers: async () => {
      const results = await internalClients.auth.query({ query: queries.auth.GET_AUTHS });
      clients.handleGQLErrors(results);
      return results;
    },
    getRemoteAuths: async (username) => {
      const results = await clients.direct.auth.query({
        query: gql` query getRemoteAuths($username: String) {
          remoteauths(username: $username) {
            userDeviceOtp,
            issued,
            descriptor,
            approvalStatus,
          }
        }`,
        variables: {
          username,
        },
        fetchPolicy: 'no-cache',
      });
      clients.handleGQLErrors(results);
      return results;
    },
    restartUserDeviceOtp: async (userDeviceOtp) => {
      try {
        const response = await internalClients.authRest.get(`/reset/otp?userDeviceOtp=${userDeviceOtp}`);
        return response;
      } catch (error) {
        return clients.handleAxiosErrors(error);
      }
    },
    getLoginTypes: async () => {
      try {
        const response = await internalClients.authRest.get('/types/login/list');
        return response;
      } catch (error) {
        return clients.handleAxiosErrors(error);
      }
    },
    getWebAuthNRegStatus: async (username) => {
      try {
        const response = await internalClients.authRest.get(`/webAuthN/registration/${username}`);
        return response;
      } catch (error) {
        return clients.handleAxiosErrors(error);
      }
    },
    getWebAuthNPubKeyChallenge: async (username) => {
      try {
        const response = await internalClients.authRest.get(`/webAuthN/pubkey/${username}`);
        return response;
      } catch (error) {
        return clients.handleAxiosErrors(error);
      }
    },
    setWebAuthNPubKeyChallenge: async (username, challengeResults) => {
      try {
        const response = await internalClients.authRest.post(
          `/webAuthN/pubkey/${username}`,
          challengeResults,
        );
        return response;
      } catch (error) {
        return clients.handleAxiosErrors(error);
      }
    },
    clearWebAuthNPubKeyChallenge: async (username) => {
      try {
        const response = await internalClients.authRest.delete(`/webAuthN/pubkey/${username}`);
        return response;
      } catch (error) {
        return clients.handleAxiosErrors(error);
      }
    },
    genToken: async (payload, skipWkst = false) => {
      const updatedPayload = { ...payload };
      if (!skipWkst) {
        updatedPayload.wkst = getWorkstationNumber();
      }
      try {
        const response = await internalClients.authRest.post(`/login/${payload.type}`, updatedPayload);
        return response;
      } catch (error) {
        return clients.handleAxiosErrors(error);
      }
    },
  },
};
