import { UserAdministrationActions } from './useradministration.actions';

const convertArrayIntoObject = (array) => {
  const mapped = array.map((item) => ({ [item.name]: item.description }));
  return Object.assign({}, ...mapped);
};

export const UserAdministration = {
  namespaced: true,
  state: () => ({
    userList: [],
    editedItem: {},
    editedIndex: undefined,
    dialog: undefined,
    rolesList: [],
    rolesConst: {},
  }),
  mutations: {
    setUsersList(state, results) {
      state.userList = results;
    },
    setEditedItem(state, item) {
      state.editedItem = item;
    },
    setEditedIndex(state, index) {
      state.editedIndex = index;
    },
    setDialog(state, dialog) {
      state.dialog = dialog;
    },
    setRolesList(state, roles) {
      state.rolesList = roles;
    },
    setRolesConst(state, roles) {
      state.rolesConst = convertArrayIntoObject(roles);
    },
  },
  actions: {
    ...UserAdministrationActions,
  },
};

export default UserAdministration;
