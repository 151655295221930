<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          solo
          v-model="password"
          label="Password"
          class="login-pass"
          ref="loginPass"
          id="loginPass"
          name="loginPass"
          autocomplete="new-password"
          :prepend-inner-icon="icons.mdiAccountKey"
          :type="showPass ? 'text' : 'password'"
          @click:append="showPass = !showPass"
          :append-icon="showPass ? icons.mdiEye : icons.mdiEyeOff"
          :error-messages="passErrorMsgs"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
          @keydown.enter="login"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="secondary"
          class="ma-2 white--text"
          id="signin"
          name="signin"
          @click="login"
        >
          Sign-in
          <v-icon right> {{icons.mdiLogin}} </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {
  mdiAccount,
  mdiAccountKey,
  mdiEye,
  mdiEyeOff,
  mdiLogin,
} from '@mdi/js';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  validations: {
    password: { required },
  },
  computed: {
    inputs() {
      return {
        username: this.username,
        password: this.password,
      };
    },
    passErrorMsgs() {
      const v = this.$v;
      return (!v.password.$dirty) ? [] : [
        ...(v.password.required) ? [] : ['Required'],
      ];
    },
  },

  name: 'LoginUserPass',
  data: () => ({
    icons: {
      mdiAccount,
      mdiAccountKey,
      mdiEye,
      mdiEyeOff,
      mdiLogin,
    },
    showPass: false,
    password: '',
  }),
  props: {
    title: {
      type: String,
      default: 'Sign-in',
    },
    username: {
      type: String,
      default: '',
    },
  },
  methods: {
    login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('login', this.inputs);
      }
    },
  },
};
</script>
