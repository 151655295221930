export const configUrls = () => {
  const URLS = {
    AUTH_GQL: '',
    AUTH_REST: '',
    BACKEND_GQL: '',
    BACKEND_REST: '',
  };
  const prodUrlRe = /(http[s]?):\/\/([a-zA-Z0-9-]*)\.local\.tmconsulting\.dev/;
  if (window.location.href.indexOf('http://') !== -1
    && window.location.href.indexOf(':5000') !== -1) {
    const hostName = window.location.href.match(/^http:\/\/([a-zA-Z0-9.]+):5000/);
    // when using docker-compose
    URLS.AUTH_GQL = `http://${hostName[1]}:3001/graphiql`;
    URLS.AUTH_REST = `http://${hostName[1]}:3001/`;
    URLS.BACKEND_GQL = `http://${hostName[1]}:3000/graphiql`;
    URLS.BACKEND_REST = `http://${hostName[1]}:3000/api`;
  } else {
    const matches = window.location.href.match(prodUrlRe);
    if (matches && matches.length === 3) {
      const protocol = matches[1];
      const countyName = matches[2];
      URLS.AUTH_GQL = `${protocol}://auth.${countyName}.local.tmconsulting.dev/graphiql`;
      URLS.AUTH_REST = `${protocol}://auth.${countyName}.local.tmconsulting.dev`;
      URLS.BACKEND_GQL = `${protocol}://backend.${countyName}.local.tmconsulting.dev/graphiql`;
      URLS.BACKEND_REST = `${protocol}://backend.${countyName}.local.tmconsulting.dev/api`;
    } else {
      console.error('Unable to set dependent URLs!');
    }
  }
  return URLS;
};

export default {
  configUrls,
};
