const privateUtil = {
  // globally configure DataTable footers
  footerProps: (itemsperpage) => {
    const options = {
      'show-current-page': true,
      'page-text': '',
      'disable-items-per-page': true,
      // 'items-per-page-text': '',
      'items-per-page-options': [-1, itemsperpage],
    };
    return options;
  },
};

export const privateVuex = {
  setSettings(newState) {
    localStorage.setItem('settings.lightdark', newState.lightdark);
    localStorage.setItem('settings.itemsperpage', newState.itemsperpage);
    localStorage.setItem('settings.betaFlag', newState.betaFlag);
  },
  getSettings() {
    const lightdark = localStorage.getItem('settings.lightdark') || 'light';
    const itemsperpage = parseFloat(localStorage.getItem('settings.itemsperpage')) || 100;
    const betaFlag = localStorage.getItem('settings.betaFlag') === 'true';
    const footerProps = privateUtil.footerProps(itemsperpage);
    // // todo - calculate this in the b/g for any page where a static value becomes problematic
    const serverItemsLength = 20000;
    return {
      lightdark,
      itemsperpage,
      betaFlag,
      footerProps,
      serverItemsLength,
    };
  },
};

export default privateVuex;
