import gql from 'graphql-tag';
import { clients } from '../../util/clients';
import { bulkResult } from '../../util/shared/graphql-global';

const { backend } = clients.direct;

export const ProfileActions = {
  async loadProfiles({ dispatch, commit }, pipeline = false) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` query {
          ImportFileProfiles { id, type action noheader }
        }`,
        fetchPolicy: 'no-cache',
      });
      const importFileProfiles = results.data.ImportFileProfiles;
      if (importFileProfiles !== undefined) {
        if (pipeline) {
          commit('setPipelineData', importFileProfiles);
        } else {
          commit('setProfiles', importFileProfiles);
        }
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading Profiles.', { root: true });
    }
  },
  async fetchProfileByID({ dispatch, commit }, id) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.query({
        query: gql` query fetchProfileByID($id: ID) {
          ImportFileProfile(id: $id) { id type mappingConfig action noheader }
        }`,
        variables: { id },
        fetchPolicy: 'no-cache',
      });
      const importFileProfile = results.data.ImportFileProfile;
      if (importFileProfile !== undefined) {
        commit('setProfile', importFileProfile);
        dispatch('fetchDestionationByAction', importFileProfile.action);
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There was a problem loading Profile.', { root: true });
    }
  },
  async upsertProfile({ dispatch, state, commit }, profileData) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation upsertProfile ($profile: [ImportFileProfileBulkInput])  {
          upsertImportFileProfiles(upserts: $profile ) {
            ${bulkResult}
          }
        }`,
        variables: { profile: profileData },
      });
      clients.handleGQLErrors(results, commit);
      const upsertDoc = results.data.upsertImportFileProfiles;
      let updatedDocList = [];
      let replaceId = '';
      let newUpdatedDoc = {};

      if (upsertDoc !== undefined
        && (upsertDoc.modifiedCount === 1
          || upsertDoc.insertedCount === 1)
      ) {
        if (upsertDoc.modifiedCount === 1) {
          replaceId = profileData.id;
          newUpdatedDoc = profileData;
        } else if (upsertDoc.insertedCount === 1) {
          replaceId = upsertDoc.insertedIds['0'];
          newUpdatedDoc = profileData;
          newUpdatedDoc.id = replaceId;
        }
        updatedDocList = state.listOfProfiles.filter((p) => p.id !== replaceId);
        commit('setProfiles', [{ ...newUpdatedDoc }, updatedDocList]);
        commit('setProfile', { ...newUpdatedDoc });
        dispatch('flashSuccess', 'Profile saved Successfully.', { root: true });
      } else {
        // Do Nothing
      }
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There is some problem to save Profile.', { root: true });
    }
  },
  async delImportFileProfile({ state, dispatch, commit }, profile) {
    let results;
    try {
      await dispatch('reAuth', null, { root: true });
      results = await backend.mutate({
        mutation: gql`mutation delImportFileProfile ($id: ID!)  {
          deleteImportFileProfile(id: $id) { id }
        }`,
        variables: {
          id: profile.id,
        },
      });
      clients.handleGQLErrors(results, commit);
      const delId = results.data.deleteImportFileProfile.id;
      const newTrans = state.listOfProfiles.filter((p) => p.id !== delId);
      state.listOfProfiles = newTrans;
    } catch (e) {
      dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
      dispatch('flashError', 'There is some problem on deleting the Profile.', { root: true });
    }
  },
};

export default ProfileActions;
