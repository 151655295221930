// TM Consulting Azure portal-related constants
export const TMC_CONSTANTS = {
  SUBSCRIPTION_ID: '02bb3a1b-0ce0-4d93-ad39-9580f36009e2',
  STORAGE_ACCT_ID: 'f8cdfcd992bdb43e4a391ea',
  COMPONENT_Z_INDEX: {
    WKST_DIALOG: 2100,
    LOGIN_DIALOG: 1900,
    UPDATE_USER_PASSWORD_DIALOG: 1700,
    NOTIFICATION_DIALOG: 1500,
    NOTIFICATION_SNACKBAR: 1300,
  },
};

export default {};
