import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import {
  fieldsToGqlString,
  mergeByIdNewAtTop,
} from '../../../util/shared/vue-global';

const { backend } = clients.direct;
const defaultSourceFields = {
  _id: '',
  type: '',
  sourceDescription: '',
  subSourceDescription: '',
  sourceSubSourceNumber: '',
  fiscalYear: '',
  altKey: '',
  isSkipMiscellaneousTransaction: '',
};

const defaultMasterFields = {
  _id: '',
  sourceNumber: '',
  sourceDescription: '',
};

export const source = {
  namespaced: true,
  state: () => ({
    regYears: {},
    masterSourceList: undefined,
  }),
  mutations: {
    setFiscalRegYears(state, regYears) {
      state.regYears = regYears;
    },
    setMasterSourceList(state, masterSourceList) {
      state.masterSourceList = masterSourceList;
    },
  },
  actions: {
    async loadCmnSource({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      const gqlfields = fields || defaultSourceFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query cmnSource($criteria: cmnSourceCriteriaType) {
            cmnSource(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        const { cmnSource } = results.data;
        if (baseCrudKey) {
          commit('base/crud/setItems', [baseCrudKey, cmnSource], { root: true });
        }
        return cmnSource;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Source list!', { root: true });
      }
      return [];
    },
    async upsertCmnSource({ dispatch, rootGetters, commit }, {
      item, fields, baseCrudKey, postProccessSource,
    }) {
      let results;
      try {
        const gqlfields = fields || defaultSourceFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertCmnSource ($item: cmnSourceInputType!)  {
            upsertCmnSource(upsert: $item) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const sourceStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newSource = results.data.upsertCmnSource;
        const [processedSource] = postProccessSource
          ? postProccessSource([newSource]) : [newSource];
        const newList = mergeByIdNewAtTop(sourceStateData || [], processedSource);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Source!', { root: true });
      }
    },
    async loadFiscalRegYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            sourceStatsFYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setFiscalRegYears', results.data.sourceStatsFYRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
    },
    async loadMasterSource({ commit, dispatch }, {
      criteria, fields,
    }) {
      let results;
      const gqlfields = fields || defaultMasterFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query masterSource($criteria: masterSourceCriteriaType) {
            masterSource(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        const { masterSource } = results.data;
        commit('setMasterSourceList', masterSource);
        return masterSource;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Source list!', { root: true });
      }
      return [];
    },
  },
};

export default source;
