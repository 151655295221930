import Vue from 'vue';
import VueRouter from 'vue-router';
import { CommonRoutes } from './Common';
import { AuditRoutes } from './Audit';
import { ODRoutes } from './OD';
import { WarRoutes } from './War';
import { BulkRoutes } from './Bulk';
import { SharedRoutes } from './shared';
import { UserAdministrationRoutes } from './UserAdministration';
import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';
import { MiscRoutes } from './Misc';
import { MtgRoutes } from './Mtg';
import { UserGuideRoutes } from './UserGuide';
import { GlRoutes } from './Gl';

Vue.use(VueRouter);

/*
  '/reports' is reserve for report mode
  If required any report mode please add '/reports' at pre route
*/

const routes = [
  {
    path: '/',
    name: 'Home',
    // route-level code-splitting
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'HomeLogin',
    // route-level code-splitting
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/system-config',
    name: 'System Configuration',
    component: () => import('../views/common/SystemConfig.vue'),
    beforeEnter: async (to, from, next) => {
      await accessCriteria(authRoles.SettingsUsers, next);
    },
  },
  {
    path: '/system-info',
    name: 'View System Info',
    component: () => import('../views/common/SystemInfo.vue'),
    beforeEnter: async (to, from, next) => {
      await accessCriteria(authRoles.SuperAdmin, next);
    },
  },
  {
    path: '/dev-specific-config',
    name: 'Dev Specific Config',
    component: () => import('../views/common/DevSpecificConfig.vue'),
    beforeEnter: async (to, from, next) => {
      await accessCriteria(authRoles.SuperAdmin, next);
    },
  },
  {
    path: '/migration-manager',
    name: 'Migration Manager',
    component: () => import('../views/common/MigrationManager.vue'),
    beforeEnter: async (to, from, next) => {
      await accessCriteria(authRoles.SuperAdmin, next);
    },
  },
  ...CommonRoutes,
  ...AuditRoutes,
  ...ODRoutes,
  ...BulkRoutes,
  ...UserAdministrationRoutes,
  ...WarRoutes,
  ...SharedRoutes,
  ...MiscRoutes,
  ...MtgRoutes,
  ...UserGuideRoutes,
  ...GlRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
