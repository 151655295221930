import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import {
  fieldsToGqlString,
  mergeByIdNewAtTop,
} from '../../../util/shared/vue-global';

const { backend } = clients.direct;
const defaultTransactionFields = {
  _id: '',
  fiscalYear: '',
  businessDate: '',
  receiptNumber: '',
  receivedOf: '',
  mortgagor: '',
  mortgagee: '',
  isVoid: '',
  hasFiles: '',
  chunkID: '',
  amount: '',
  remark: '',
  dateOfMortgage: '',
  mortgageDueDate: '',
  termYear: '',
  mortgageAmount: '',
  rateOfTax: '',
  mortgageTax: '',
  fee: '',
  property: '',
  sectionLot: '',
  township: '',
  rangeBlk: '',
  transactionNumber: '',
  transactionNumberObject: {
    _id: '',
    transactionNumber: '',
    fiscalYear: '',
    amount: '',
    paymentDetails: {
      paymentType: '',
      amount: '',
      cashReceived: '',
      cashChange: '',
      checkNumber: '',
      checkPaidBy: '',
      eftType: '',
      creditType: '',
    },
  },
  paymentDetails: {
    paymentType: '',
    amount: '',
    cashReceived: '',
    cashChange: '',
    checkNumber: '',
    checkPaidBy: '',
    eftType: '',
    creditType: '',
  },
  wkst: '',
};

export const mtgTransaction = {
  namespaced: true,
  state: () => ({
    regYears: {},
    nextReceiptNumber: undefined,
    receiptPulse: false,
    receiptPolling: undefined,
    selectedItems: [],
    nextTransNumber: null,
    transNoPulse: false,
    transNoPolling: undefined,
    businessDateRange: {},
  }),
  mutations: {
    setFiscalRegYears(state, regYears) {
      state.regYears = regYears;
    },
    setSelectedItems(state, items) {
      state.selectedItems = items;
    },
    updateMtgLocalFileStatus(state, { objectId, status }) {
      const result = this.getters['base/crud/items']('mtgTransaction').find(
        (object) => object._id === objectId,
      );
      result.hasFiles = status;
    },
    setStats(state, results) {
      if (results.mtgStatsMaxReceipt && results.mtgStatsMaxReceipt.max !== undefined) {
        const nextReceiptNumber = results.mtgStatsMaxReceipt.max + 1;
        if (state.nextReceiptNumber !== nextReceiptNumber) {
          state.nextReceiptNumber = nextReceiptNumber;
        }
      }
    },
    setReceiptPulse(state, shouldPulse) {
      state.receiptPulse = shouldPulse;
    },
    setReceiptPolling(state, timer) {
      if (state.receiptPolling) {
        clearInterval(state.receiptPolling);
      }
      state.receiptPolling = timer;
    },
    setTransNoStats(state, maxTransactionNumber) {
      if (Number.isInteger(maxTransactionNumber)) {
        state.nextTransNumber = maxTransactionNumber + 1;
      } else {
        state.nextTransNumber = null;
      }
    },
    setTransNoPulse(state, shouldPulse) {
      state.transNoPulse = shouldPulse;
    },
    setTransNoPolling(state, timer) {
      if (state.transNoPolling) {
        clearInterval(state.transNoPolling);
      }
      state.transNoPolling = timer;
    },
    setBusinessDateRange(state, businessDateRange) {
      state.businessDateRange = businessDateRange;
    },
  },
  actions: {
    async loadMtgTransaction({ commit, dispatch }, {
      criteria, fields, baseCrudKey, postProcessTransaction,
    }) {
      let results;
      const gqlfields = fields || defaultTransactionFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query mtgTransaction($criteria: mtgTransCriteriaType) {
            mtgTransaction(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        if (baseCrudKey && results.data && results.data.mtgTransaction) {
          const mtgTrans = results.data.mtgTransaction || [];
          const withPostProcess = postProcessTransaction
            ? postProcessTransaction(mtgTrans) : mtgTrans;
          commit('base/crud/setItems', [baseCrudKey, withPostProcess], { root: true });
        }
        return results.data.mtgTransaction;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Transaction List!', { root: true });
        return [];
      }
    },
    async upsertMtgTrans({ dispatch, rootGetters, commit }, {
      item, fields, baseCrudKey, postProcessTransaction,
    }) {
      let results;
      try {
        const gqlfields = fields || defaultTransactionFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertMtgTrans ($item: mtgTransInputType!)  {
            upsertMtgTrans(upsert: $item) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const mtgTransStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newTransaction = results.data.upsertMtgTrans;
        const [processedTrans] = postProcessTransaction
          ? postProcessTransaction([newTransaction]) : [newTransaction];
        const newList = mergeByIdNewAtTop(mtgTransStateData || [], processedTrans);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
        return newTransaction;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the transaction!', { root: true });
      }
      return {};
    },
    async deleteMtgTransaction({ commit, rootGetters, dispatch }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation deleteMtgTrans ($id: ID!)  {
            deleteMtgTrans(id: $id) {
                  _id
                }
              }`,
          variables: {
            id: item._id,
          },
        });
        clients.handleGQLErrors(results, commit);
        const delId = results.data.deleteMtgTrans._id;
        const mtgTransData = rootGetters['base/crud/items'](baseCrudKey);
        const updatedData = mtgTransData.filter((d) => d._id !== delId);
        commit('base/crud/setItems', [baseCrudKey, updatedData], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem while deleting the transaction!', { root: true });
      }
    },
    async loadFiscalRegYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            mtgStatsFYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setFiscalRegYears', results.data.mtgStatsFYRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
    },
    async loadLastReceiptNo({ commit, dispatch }, { fiscalYear }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query mtgStatsMaxReceipt ($fiscalYear: Int!) {
            mtgStatsMaxReceipt (fiscalYear: $fiscalYear) { max }
          }`,
          variables: { fiscalYear },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        commit('setStats', results.data);
        return results.data.mtgStatsMaxReceipt.max;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem determining the last receipt number!', { root: true });
      }
      return null;
    },
    async loadLastTransactionNo({ commit, dispatch }, { fiscalYear }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query mtgStatsMaxTransNum ($fiscalYear: Int!) {
            mtgStatsMaxTransNum (fiscalYear: $fiscalYear) { max }
          }`,
          variables: { fiscalYear },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const maxNo = results.data ? (results.data.mtgStatsMaxTransNum || {}).max : null;
        commit('common/cmnTransNumber/setStats', maxNo, { root: true });
        return maxNo;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem determining the last transaction number!', { root: true });
      }
      return null;
    },
    async loadBusinessDateRange({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            mtgStatsBusinessDateRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const range = results.data ? (results.data.mtgStatsBusinessDateRange || {}) : null;
        commit('setBusinessDateRange', results.data.mtgStatsBusinessDateRange);
        return range;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max business date ranges.', { root: true });
      }
      return null;
    },
    async loadWkstList({ commit, dispatch }, { fiscalYear }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`query mtgStatsWkstList ($fiscalYear: Int!) {
            mtgStatsWkstList(fiscalYear: $fiscalYear)
            {
              _id
              wkstInt
            }
          }`,
          variables: {
            fiscalYear,
          },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        return results.data && results.data.mtgStatsWkstList;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max date ranges.', { root: true });
      }
      return [];
    },
  },
};

export default mtgTransaction;
