import { parseJwt, getRawJwt } from '../../util/jwt';

const privateState = {
  reauth: undefined, // Promise (if waiting on user re-authentication)
  resolve: undefined,
  reject: undefined,
};

const NOW_THRESHOLD = 20; // how many seconds before "now" should we go ahead and re-authenticate?
export const privateVuex = {
  resolve(tokenContext) {
    // console.log('resolving reAuth');
    if (privateState.resolve) {
      privateState.resolve(tokenContext);
    } else {
      console.warn('no resolve fired');
    }
  },
  reject(error) {
    // console.log('rejecting reAuth');
    if (privateState.reject) {
      privateState.reject(error);
    } else {
      console.warn('no reject fired');
    }
  },
  getReAuthPromise() {
    return privateState.reauth;
  },
  setPrivateState(newState) {
    // console.log('reAuth saving in-mem private');
    privateState.reauth = newState.reauth;
    privateState.resolve = newState.reauthResolve;
    privateState.reject = newState.reauthReject;
  },
  setLoggedIn(context) {
    // console.log('set localStorage auth.*');

    localStorage.setItem('auth.user', context.username);
    localStorage.setItem('auth.jwt', context.jwt);

    // decode to cache exp value
    const decodedToken = parseJwt(context.jwt);
    localStorage.setItem('auth.exp', decodedToken.exp);
  },
  setLoggedOut() {
    localStorage.removeItem('auth.jwt');
    localStorage.removeItem('auth.user');
    localStorage.removeItem('auth.exp');
  },
  rehydrateAuth: () => {
    const jwt = getRawJwt();
    const user = localStorage.getItem('auth.user');
    const exp = localStorage.getItem('auth.exp');

    return {
      jwt,
      user,
      exp,
    };
  },
  // gets authentication info from local storage
  // invalid or expired auth is indicated with an undefined token
  getAuthIfValid: (now = new Date()) => {
    // console.log('get localStorage auth.*');
    let jwt = getRawJwt();
    const user = localStorage.getItem('auth.user');
    let exp = localStorage.getItem('auth.exp');
    const expThreshold = (now.getTime() / 1000) - NOW_THRESHOLD;

    let reauth = false;
    if (!(jwt && user && exp)) {
      // need to login
      reauth = true;
    } else if (exp < expThreshold) {
      // time to re-auth
      reauth = true;
    }

    if (reauth) {
      jwt = undefined;
      exp = undefined;
    }

    return {
      jwt,
      user,
      exp,
    };
  },
};

export default privateVuex;
