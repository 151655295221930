import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import {
  fieldsToGqlString,
  mergeByIdNewAtTop,
} from '../../../util/shared/vue-global';

const { backend } = clients.direct;
const defaultGLCollateralBankFields = {
  _id: '',
  fiscalYear: '',
  asOfDate: '',
  bankIntials: '',
  bankName: '',
  cashBalance: '',
  pledgesLeft: '',
  details: {
    asOfDate: '',
    description: '',
    amount: '',
    fdic: '',
  },
};

export const glCollateralBankTransaction = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async loadGLCollateralBankTrans({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      const gqlfields = fields || defaultGLCollateralBankFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query glCollateralBank($criteria: glCollateralBankCriteriaType) {
            glCollateralBank(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        const glCollateralBankTrans = results.data ? results.data.glCollateralBank
            || [] : [];
        commit('base/crud/setItems', [baseCrudKey, glCollateralBankTrans], { root: true });
        return glCollateralBankTrans;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the Transaction List!', { root: true });
      }
      return [];
    },
    async upsertGLCollateralBankTrans({ dispatch, rootGetters, commit }, {
      item, fields, baseCrudKey,
    }) {
      let results;
      try {
        const gqlfields = fields || defaultGLCollateralBankFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertGlCollateralBank ($item: glCollateralBankInputType!)  {
                upsertGlCollateralBank(upsert: $item) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const glCollateralBankTransStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newTransaction = results.data ? (results.data.upsertGlCollateralBank
            || {}) : {};
        const newList = mergeByIdNewAtTop(glCollateralBankTransStateData || [], newTransaction);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
        return newTransaction;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the transaction!', { root: true });
      }
      return {};
    },
    async deleteGLCollateralBankTrans({ commit, rootGetters, dispatch }, { item, baseCrudKey }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation deleteGlDailyTransaction ($id: ID!)  {
            deleteGlDailyTransaction(id: $id) {
                  _id
                }
              }`,
          variables: {
            id: item._id,
          },
        });
        clients.handleGQLErrors(results, commit);
        const delId = results.data ? (results.data.deleteGlDailyTransaction || {})._id : null;
        const glCollateralBankData = rootGetters['base/crud/items'](baseCrudKey);
        const updatedData = glCollateralBankData.filter((d) => d._id !== delId);
        commit('base/crud/setItems', [baseCrudKey, updatedData], { root: true });
        return delId;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem while deleting the transaction!', { root: true });
      }
      return null;
    },
  },
};

export default {
  glCollateralBankTransaction,
};
