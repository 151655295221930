import { accessCriteria } from '../util/authorization/index';

export const MtgRoutes = [
  {
    path: '/mtg',
    name: 'MortgageTax',
    component: () => import('../views/MortgageTax.vue'),
    children: [
      {
        path: 'transactions',
        name: 'Mortgage Tax - Transactions',
        component: () => import('../views/Mtg/Transactions.vue'),
      },
      {
        path: 'browse',
        name: 'Mortgage Tax - Browse',
        component: () => import('../views/Mtg/browse/browse.vue'),
        children: [
          {
            path: 'receivedof',
            name: 'Mortgage Tax - Received Of',
            component: () => import('../views/common/Whom.vue'),
          },
        ],
      },
      {
        path: 'reports',
        name: 'Mortgage Tax - Reports',
        component: () => import('../views/Mtg/Reports.vue'),
      },
      {
        path: 'utils',
        name: 'Mortgage Tax - Utils',
        component: () => import('../views/Mtg/utils/MtgUtils.vue'),
        children: [
          {
            path: 'createfiscalyear',
            name: 'Mortgage Tax - Create Fiscal Year',
            component: () => import('../views/Mtg/utils/MortgageCreateFiscalYear.vue'),
          },
        ],
      },
    ],
  },
];

MtgRoutes.forEach((element, index) => {
  MtgRoutes[index].beforeEnter = async (to, from, next) => {
    await accessCriteria(['MtgAdministration', 'MtgUser'], next);
  };
});

export default MtgRoutes;
