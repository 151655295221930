import { department } from './department/index';
import { account } from './account/index';
import { whom } from './whom/index';
import { transaction } from './transaction/index';
import { util } from './util/index';

export const war = {
  namespaced: true,
  modules: {
    department,
    account,
    whom,
    transaction,
    util,
  },
};

export default war;
