import { accessCriteria } from '../util/authorization/index';
import { authRoles } from './roleGroups';

export const ODRoutes = [
  {
    path: '/od',
    name: 'Official Depository',
    component: () => import('../views/OfficialDepository.vue'),
    children: [
      {
        path: '/reports',
        name: 'Official Depository - Reports',
        component: () => import('../views/OD/Reports.vue'),
      },
      {
        path: 'transactions',
        name: 'Official Depository - Deposits & Vouchers',
        component: () => import('../views/OD/Transactions.vue'),
      },
      {
        path: 'browse',
        name: 'Official Depository - Browse',
        component: () => import('../views/OD/browse/Browse.vue'),
        children: [
          {
            path: 'departments',
            name: 'Official Depository - Departments',
            component: () => import('../views/OD/browse/Departments.vue'),
          },
          {
            path: 'whats',
            name: 'Official Depository - For What',
            component: () => import('../views/OD/browse/Whats.vue'),
          },
          {
            path: 'whoms',
            name: 'Official Depository - To Whom',
            component: () => import('../views/OD/browse/Whoms.vue'),
          },
        ],
      },
      {
        path: 'utils',
        name: 'Official Depository - Utils',
        component: () => import('../views/OD/utils/Utils.vue'),
        children: [
          {
            path: 'interestapportionment',
            name: 'Official Depository - Interest Apportionment',
            component: () => import('../views/OD/utils/InterestApportionment.vue'),
          },
          {
            path: 'createfiscalyear',
            name: 'Official Depository - Create New Fiscal Year',
            component: () => import('../views/OD/utils/CreateFiscalYear.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/reports/interestapportionment',
    name: 'Official Depository - Interest Apportionment Report',
    component: () => import('../views/OD/utils/InterestApportionmentReport.vue'),
  },
];

ODRoutes.forEach((element, index) => {
  ODRoutes[index].beforeEnter = async (to, from, next) => {
    await accessCriteria(authRoles.ODRead, next);
  };
});

export default ODRoutes;
