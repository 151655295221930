import { privateVuex } from './private';
// import { clients } from '../../util/clients';

export const user = {
  namespaced: true,
  actions: { // actions has both local state, and rootState
    updateSettings(_, updateSettings) {
      privateVuex.setSettings(updateSettings);
    },
  },
  getters: {
    getSettings() {
      return privateVuex.getSettings();
    },
  },
};

export default user;
