import gql from 'graphql-tag';
import { clients } from '../../../../util/clients';
import { fieldsToGqlString, mergeByIdNewAtTop } from '../../../../util/shared/vue-global';

const { backend } = clients.direct;
const defaultfundsReconFields = {
  _id: '',
  fiscalYear: '',
  registerDate: '',
  glBalance: '',
  glAdjustments: '',
  glAdjustmentsBal: '',
  apprBalance: '',
  apprAdjustments: '',
  apprAdjustmentsBal: '',
  variance: '',
  fund: {
    _id: '',
    fund: '',
    fiscalYear: '',
    description: '',
  },
  details: {
    type: '',
    description: '',
    amount: '',
    memo: '',
  },
};

export const fundReconciliation = {
  namespaced: true,
  state: () => ({
    regYears: {},
  }),
  mutations: {
    setFiscalRegYears(state, regYears) {
      state.regYears = regYears;
    },
  },
  actions: {
    async loadfundsRecon({ commit, dispatch }, {
      criteria, fields, baseCrudKey, postProccessFundRecon,
    }) {
      let results;
      const gqlfields = fields || defaultfundsReconFields;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query fundReconcillation($criteria: fundReconCriteriaType) {
            fundReconcillation(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        const fundsRecon = postProccessFundRecon
          ? postProccessFundRecon(results.data.fundReconcillation)
          : results.data.fundReconcillation;
        commit('base/crud/setItems', [baseCrudKey, fundsRecon], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the funds Recon list.', { root: true });
      }
    },
    async upsertfundRecon({ dispatch, rootGetters, commit }, {
      item, fields, baseCrudKey, postProccessFundRecon,
    }) {
      let results;
      try {
        const gqlfields = fields || defaultfundsReconFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertfundRecon ($item: fundReconInputType!)  {
                upsertfundRecon(upsert: $item) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const fundsReconStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newRecon = results.data.upsertfundRecon;
        const [processedRecon] = postProccessFundRecon
          ? postProccessFundRecon([newRecon]) : [newRecon];
        const newList = mergeByIdNewAtTop(fundsReconStateData || [], processedRecon);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the Funds Recon', { root: true });
      }
    },
    async loadFiscalRegYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            funReconStatsFYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setFiscalRegYears', results.data.funReconStatsFYRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
    },
    async loadgatherInformation({ commit, dispatch }, {
      criteria, fields, baseCrudKey, postProccessFundRecon,
    }) {
      let results;
      const gqlfields = fields || defaultfundsReconFields;
      try {
        commit('setGridLoading', {
          loading: true,
          loadingText: 'Searching...',
        }, { root: true });
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query gatherInformation($criteria: fundReconCriteriaType) {
            gatherInformation(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        const fundsRecon = postProccessFundRecon
          ? postProccessFundRecon(results.data.gatherInformation)
          : results.data.gatherInformation;
        commit('base/crud/setItems', [baseCrudKey, fundsRecon], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the funds Recon list.', { root: true });
      } finally {
        commit('setGridLoading', { loading: false }, { root: true });
      }
    },
  },
};

export default fundReconciliation;
