import gql from 'graphql-tag';
import { clients } from '../../../util/clients';
import {
  fieldsToGqlString,
  mergeByIdNewAtTop,
} from '../../../util/shared/vue-global';

const { backend } = clients.direct;
const defaultSchoolUnitFields = {
  _id: '',
  unitCode: '',
  unitCodeNumber: '',
  description: '',
  fiscalYear: '',
};

export const schoolUnit = {
  namespaced: true,
  state: () => ({
    regYears: {},
  }),
  mutations: {
    setFiscalRegYears(state, regYears) {
      state.regYears = regYears;
    },
  },
  actions: {
    async loadMiscUnitCode({ commit, dispatch }, {
      criteria, fields, baseCrudKey,
    }) {
      let results;
      const gqlfields = fields || defaultSchoolUnitFields;
      const sortingDir = (criteria.sortBy || [])[0] ? criteria.sortBy[0].direction : 'asc';
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql` query miscUnitCode($criteria: miscUnitCodeCriteriaType) {
            miscUnitCode(criteria: $criteria) {
                ${fieldsToGqlString(gqlfields)},
              }
            }`,
          variables: { criteria },
          fetchPolicy: 'no-cache',

        });
        clients.handleGQLErrors(results, commit);
        const miscUnitSchool = results.data ? results.data.miscUnitCode || [] : [];
        if (sortingDir === 'desc') {
          miscUnitSchool.sort((a, b) => {
            const unitCodeNumberA = parseFloat(a.unitCodeNumber);
            const unitCodeNumberB = parseFloat(b.unitCodeNumber);
            return unitCodeNumberB - unitCodeNumberA;
          });
        } else {
          miscUnitSchool.sort((a, b) => {
            const unitCodeNumberA = parseFloat(a.unitCodeNumber);
            const unitCodeNumberB = parseFloat(b.unitCodeNumber);

            // Handle empty unitcode numbers by moving them to the end
            if (Number.isNaN(unitCodeNumberA)) return 1;
            if (Number.isNaN(unitCodeNumberB)) return -1;
            return unitCodeNumberA - unitCodeNumberB;
          });
        }
        if (baseCrudKey) {
          commit('base/crud/setItems', [baseCrudKey, miscUnitSchool], { root: true });
        }
        return miscUnitSchool;
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was problem loading the School/Unit Code list.', { root: true });
      }
      return [];
    },
    async upsertMiscUnitCode({ dispatch, rootGetters, commit }, {
      item, fields, baseCrudKey,
    }) {
      let results;
      try {
        const gqlfields = fields || defaultSchoolUnitFields;
        await dispatch('reAuth', null, { root: true });
        results = await backend.mutate({
          mutation: gql`mutation upsertMiscUnitCode ($item: miscUnitCodeInputType!)  {
            upsertMiscUnitCode(upsert: $item) {
                    ${fieldsToGqlString(gqlfields)},
                  }
                }`,
          variables: {
            item,
          },
        });
        clients.handleGQLErrors(results, commit);
        const schoolUnitStateData = rootGetters['base/crud/items'](baseCrudKey);
        const newSchoolUnit = results.data.upsertMiscUnitCode;
        const newList = mergeByIdNewAtTop(schoolUnitStateData || [], newSchoolUnit);
        commit('base/crud/setItems', [baseCrudKey, newList], { root: true });
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem in upserting the School/Unit Code', { root: true });
      }
    },
    async loadFiscalRegYears({ commit, dispatch }) {
      let results;
      try {
        await dispatch('reAuth', null, { root: true });
        results = await backend.query({
          query: gql`{
            miscUnitCodeStatsFYRange { max, min },
          }`,
          fetchPolicy: 'no-cache',
        });
        clients.handleGQLErrors(results, commit);
        if (results.data) {
          commit('setFiscalRegYears', results.data.miscUnitCodeStatsFYRange);
        }
      } catch (e) {
        dispatch('logException', e, { root: true }); clients.handleGQLErrors(results, commit);
        dispatch('flashError', 'There was a problem getting min/max Fiscal Year ranges.', { root: true });
      }
    },
  },
};

export default schoolUnit;
